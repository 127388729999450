import AiAssistBadge from "@components/courseCatalog/AiAssistBadge";
import AiAssistImage from "@components/courseCatalog/AiAssistImage";
import BlankCourseImage from "@components/courseCatalog/BlankCourseImage";
import NewContentCard from "@components/courseCatalog/NewContentCard";
import AIGenerateModuleModal from "@components/modals/ai/AIGenerateModuleModal";
import { CreateAiModuleResultFragment } from "@components/modals/ai/AIGenerateModuleModal.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { useModal } from "@hooks/useModal";
import { useNewPath } from "@hooks/useNewPath";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  onModuleCreated: (module: CreateAiModuleResultFragment) => void;
};

const NewModuleModal: FC<Props> = ({ onModuleCreated }) => {
  const { showModal, closeModal } = useModal();
  const createPath = useNewPath();
  return (
    <Modal
      header={
        <TitleHeader
          title="Create module"
          onCancelClick={closeModal}
          styleDeclaration={styles.header}
        />
      }
      styleDeclaration={styles.modal}
      scrollContent={false}
    >
      <AutoLayout
        direction="vertical"
        flex={1}
        width="100%"
        styleDeclaration={styles.content}
      >
        <AutoLayout flex={1} alignSelf="stretch" styleDeclaration={styles.row}>
          <NewContentCard
            title="Empty Module"
            subtitle="Create a new module from scratch."
            color={deprecatedTones.blue9}
            image={<BlankCourseImage />}
            onClick={() => {
              createPath();
              closeModal();
            }}
          />
          <NewContentCard
            title="Create a Module Outline"
            subtitle="Convert PDF, Powerpoint, or image files."
            dataTestId="create-module-outline"
            color={deprecatedTones.purple8}
            image={<AiAssistImage />}
            onClick={() => {
              showModal(
                <AIGenerateModuleModal
                  closeModal={() => closeModal({ closeAll: true })}
                  onModuleCreated={onModuleCreated}
                />,
              );
            }}
            topLeftBadge={
              <AiAssistBadge styleDeclaration={styles.aiAssistBadge} />
            }
          />
        </AutoLayout>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    borderRadius: 32,
    backgroundColor: deprecatedTones.white,
    overflow: "hidden",
    "ms-overflow-style": "-ms-autohiding-scrollbar",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
      width: 0,
      height: 0,
    },
    scrollbarWidth: "none",
  },
  header: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  content: {
    gap: 16,
    padding: 24,
  },
  row: {
    gap: 16,
  },
  card: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: deprecatedTones.white,
    boxShadow:
      "0px 4px 28px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.02)",
  },
  title: {
    marginBottom: 4,
  },
  aiAssistBadge: {
    height: 64,
    paddingLeft: 16,
  },
});

export default NewModuleModal;
