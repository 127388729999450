import NavButton from "@components/ui/NavButton";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useActionPermissions from "@hooks/useActionPermissions";
import useDashboardTrainingFeatureFlag from "@hooks/useDashboardTrainingFeatureFlag";
import useUser from "@hooks/useUser";
import useLanguage from "@src/hooks/useLanguage";
import { UserType } from "@src/types.generated";
import { useTranslation } from "@src/utils/translationSets";
import React, { useMemo } from "react";

export const MY_TRAINING_ENGLISH = "My Training";
const MY_PROFILE_ENGLISH = "My Profile";

export const MainNav: React.FC = () => {
  const lang = useLanguage();
  const { user } = useUser();
  const isTrainee = useMemo(() => {
    return user?.userType === UserType.Employee;
  }, [user]);
  const { userActionPermissions } = useActionPermissions();
  const dashboardTrainingEnabled = useDashboardTrainingFeatureFlag();

  const myTrainingTranslation = useTranslation(
    MY_TRAINING_ENGLISH,
    lang || "en",
  );
  const myProfileTranslation = useTranslation(MY_PROFILE_ENGLISH, lang || "en");

  if (isTrainee) {
    return (
      <AutoLayout
        direction="vertical"
        spaceBetweenItems={2}
        alignSelf="stretch"
        paddingTop={12}
      >
        <NavButton
          title={myTrainingTranslation.text || MY_TRAINING_ENGLISH}
          path={{ pathname: "/" }}
          icon="pencil-book"
        />
        {user && (
          <NavButton
            title={myProfileTranslation.text || MY_PROFILE_ENGLISH}
            path={{
              pathname: "/my-profile",
            }}
            icon="user-circle"
          />
        )}
      </AutoLayout>
    );
  }

  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={2}
      alignSelf="stretch"
      paddingTop={12}
    >
      <NavButton title={"Organization"} path={{ pathname: "/" }} icon="home" />
      <NavButton title="Library" path={{ pathname: "/library" }} icon="book" />
      <NavButton
        title="Marketplace"
        path={{ pathname: "/marketplace" }}
        icon="building-store"
      />
      <NavButton
        title="Messages"
        path={{ pathname: "/messages" }}
        icon="message"
      />
      {userActionPermissions?.viewReporting ? (
        <NavButton
          title="Reporting"
          path={{ pathname: "/reporting" }}
          icon="pie-chart"
        />
      ) : null}
      {dashboardTrainingEnabled && isTrainee ? (
        <NavButton
          title="My Training"
          path={{ pathname: "/my-training" }}
          icon="pencil-book"
        />
      ) : null}
      <NavButton
        title="Settings"
        path={{ pathname: "/settings" }}
        icon="settings-2"
      />
    </AutoLayout>
  );
};

export default MainNav;
