import { getUserTypeLabel } from "@components/people/personDetail/EditUserType";
import {
  CourseOrPathItem,
  EmployeeItem,
  JumpToItem,
  LocationItem,
  OrgConfigItem,
  RoleGroupItem,
  RoleItem,
  SkillItem,
  TagItem,
} from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ContentAvatar, {
  ContentType,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import GroupAvatar, {
  GroupType,
} from "@src/deprecatedDesignSystem/components/GroupAvatar";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import TagAvatar from "@src/deprecatedDesignSystem/components/TagAvatar";
import Text from "@ui/text";
import useScrollIntoView from "@hooks/useScrollIntoView";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { formatMultipleGroupNames } from "@utils/strings";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import { Route } from "nextjs-routes";
import { FC, useMemo, useRef } from "react";
import { LocationGroupType } from "@src/types.generated";
import { locationGroupTypeDisplayName } from "@components/home/constants";

type Props = {
  item: JumpToItem;
  highlighted: boolean;
  link: Route;
  onMouseMove: () => void;
  styleDeclaration?: StyleDeclaration;
  dataTestId?: string;
  close?: () => void;
};

const JumpToItemRow: FC<Props> = ({
  item,
  highlighted,
  link,
  onMouseMove,
  styleDeclaration,
  dataTestId,
  close,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useScrollIntoView(ref, highlighted, "center");
  return (
    <AutoLayout
      ref={ref}
      id={`item-container-${item.id}`}
      direction={"horizontal"}
      alignmentVertical={"center"}
      onClick={close}
      wrap={"nowrap"}
      flex={1}
      className={css(
        styleDeclaration,
        styles.item,
        highlighted && styles.highlighted,
      )}
      link={link}
      onMouseMove={onMouseMove}
      data-testid={dataTestId}
    >
      <div className={css(styles.avatarContainer)}>
        <>
          {isEmployeeItemType(item) && typeof item.id === "number" ? (
            <PersonAvatar
              person={{
                id: item.id,
                name: item.name,
              }}
              size="24px"
            />
          ) : null}
          {isContentItemType(item) ? (
            <ContentAvatar
              size={"24px"}
              defaultBackgroundHashKey={item.id}
              contentType={item.type as ContentType}
              coverImage={
                item.coverImage
                  ? item.coverImage
                  : {
                      imageUrls:
                        item.type === "Resource" && item.mediaUrl
                          ? { original: item.mediaUrl }
                          : undefined,
                    }
              }
            />
          ) : null}
          {isLocationItemType(item) ||
          isLocationGroupTypeEnum(item.type) ||
          isRoleItemType(item) ||
          isRoleGroupItemType(item) ? (
            <GroupAvatar size={"24px"} groupType={item.type as GroupType} />
          ) : null}
          {isTagItemType(item) && typeof item.id === "string" ? (
            <TagAvatar id={item.id} />
          ) : null}
        </>
      </div>
      <AutoLayout
        direction="horizontal"
        flex={1}
        alignmentVertical="center"
        className={css(styles.textContainer)}
      >
        <Text
          type={"P2"}
          fontWeight="Medium"
          color={
            isEmployeeItemType(item) && item.deactivatedAt
              ? deprecatedTones.gray8
              : deprecatedTones.black
          }
          ellipsis
          ellipsisParentFlexDirection={"row"}
          style={{ minWidth: isContentItemType(item) ? 300 : 200 }}
        >
          {item.name === "" ? `Untitled ${item.type}` : item.name}
        </Text>
        <JumpToItemRowDescription item={item} />
      </AutoLayout>
    </AutoLayout>
  );
};

type JumpToItemRowDescriptionProps = {
  item: JumpToItem;
};

const JumpToItemRowDescription: FC<JumpToItemRowDescriptionProps> = ({
  item,
}) => {
  const copy = useMemo(() => {
    if (isEmployeeItemType(item)) {
      if (item.deactivatedAt) {
        return `Deactivated ${getUserTypeLabel(item?.userType)}`;
      }
      return `${getUserTypeLabel(item?.userType)} ${
        item?.locations && item.locations.length > 0
          ? ` at ${formatMultipleGroupNames(item.locations)}`
          : ""
      }`;
    } else if (isLocationGroupTypeEnum(item.type)) {
      return locationGroupTypeDisplayName[item.type];
    } else if (isLocationItemType(item)) {
      return "Location";
    } else if (isRoleItemType(item)) {
      return "Role";
    } else if (isRoleGroupItemType(item)) {
      return "Department";
    } else if (isOrgConfigItemType(item)) {
      return `Premium Module`;
    } else {
      if (item.type === "Skill") {
        return "Check-in";
      }
      return item.type;
    }
  }, [item]);
  return (
    <AutoLayout alignmentVertical="center">
      <Text
        type="P3"
        fontWeight={"SemiBold"}
        color={deprecatedTones.gray5}
        style={{ flex: 1, textAlign: "right" }}
      >
        {copy}
      </Text>
    </AutoLayout>
  );
};

export function isContentItemType(
  item: JumpToItem,
): item is CourseOrPathItem | OrgConfigItem | SkillItem {
  return ["Course", "PremiumPath", "Module", "Skill", "Resource"].includes(
    item.type,
  );
}

export function isEmployeeItemType(item: JumpToItem): item is EmployeeItem {
  return item.type === "Employee";
}

export function isLocationItemType(item: JumpToItem): item is LocationItem {
  return item.type === "Location";
}

export function isRoleItemType(item: JumpToItem): item is RoleItem {
  return item.type === "Role";
}

export function isRoleGroupItemType(item: JumpToItem): item is RoleGroupItem {
  return item.type === "RoleGroup";
}

export function isTagItemType(item: JumpToItem): item is TagItem {
  return item.type === "Tag";
}

export function isOrgConfigItemType(item: JumpToItem): item is OrgConfigItem {
  return item.type === "PremiumPath";
}

const styles = StyleSheet.create({
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    marginLeft: 12,
  },
  item: {
    marginTop: 4,
    height: 40,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 8,
  },
  highlighted: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});

export default JumpToItemRow;

export const isLocationGroupTypeEnum = (
  type: string,
): type is LocationGroupType => {
  return Object.values(LocationGroupType).includes(type as LocationGroupType);
};
