import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { LocationGroupType } from "@src/types.generated";
import useLocationGroups from "@hooks/useLocationGroups";
import { locationGroupTypeDisplayName } from "@components/home/constants";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import SingleSelectField from "@src/deprecatedDesignSystem/components/SingleSelectField";
import { LocationGroupThinFragment } from "@hooks/useLocationGroups.generated";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  type: LocationGroupType;
  label?: string;
  placeholder?: string;
  locationGroupId?: string;
  setLocationGroupId: (x: string | undefined) => void;
  locationGroupOptions?: LocationGroupThinFragment[];
};

export const LocationGroupSingleSelectField: FC<Props> = (props) => {
  const { lgTypeToLgs } = useLocationGroups();
  const options = useMemo(() => {
    return (props.locationGroupOptions || lgTypeToLgs[props.type]).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [lgTypeToLgs, props.locationGroupOptions, props.type]);
  const label: string = useMemo(() => {
    if (props.label) return props.label;
    else {
      return locationGroupTypeDisplayName[props.type];
    }
  }, [props.label, props.type]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {label}
        </Text>
      </AutoLayout>
      <SingleSelectField
        searchEnabled={true}
        containerStyle={{
          alignSelf: "stretch",
        }}
        value={props.locationGroupId}
        onValueChange={(locationGroupId) => {
          props.setLocationGroupId(locationGroupId);
        }}
        options={options}
        includeNoneOption={true}
        placeholder={props.placeholder || `Select a ${label}...`}
        placeholderTextProps={{
          fontWeight: "Regular",
        }}
        fontWeight={"Regular"}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
      />
    </AutoLayout>
  );
};

export default LocationGroupSingleSelectField;
