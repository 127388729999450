import { UserType } from "@src/types.generated";
import React, { FC } from "react";
import { css } from "@utils/extendedStyles";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleSheet } from "aphrodite";
import Text from "@ui/text";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = {
  userType: UserType;
};

const UserTypeDescription: FC<Props> = ({ userType }) => {
  return (
    <>
      {userType === UserType.Employee && (
        <p className={css(styles.banner)}>
          A Trainee only has access to the Trainee app.
        </p>
      )}
      {userType === UserType.Admin && (
        <AutoLayout styleDeclaration={styles.banner}>
          <Text type="P2" multiline color={deprecatedTones.blue9}>
            Admins have full access to all locations, content, and settings by
            default
          </Text>
        </AutoLayout>
      )}
      {userType === UserType.Manager && (
        <div className={css(styles.banner)}>
          <Text type="P2" multiline color={deprecatedTones.blue9}>
            Managers can do everything an Admin can do except:
          </Text>

          <ul
            style={{
              marginLeft: 20,
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
            }}
            className="list-disc"
          >
            <li>View all Trainees and Locations</li>
            <li>Edit content they did not create</li>
            <li>Edit organization settings</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default UserTypeDescription;

const styles = StyleSheet.create({
  banner: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    padding: 12,
    borderRadius: "8px",
    color: deprecatedTones.blue9,
    backgroundColor: deprecatedTones.blue1,
    width: "100%",
    marginTop: 8,
    maxWidth: 380,
  },
});
