import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import React, { CSSProperties, FC } from "react";

type Props = {
  style?: CSSProperties;
};

const TermsOfServiceFooter: FC<Props> = ({ style }) => {
  return (
    <Text
      className={css(styles.text)}
      type="P4"
      style={style}
      color={deprecatedTones.gray6}
    >
      By using Opus you agree to our{" "}
      <AutoLayout externalUrl="https://www.opus.so/terms">
        <Text
          type="P4"
          style={{ textDecoration: "underline" }}
          color={deprecatedTones.gray6}
        >
          Terms of Service
        </Text>
      </AutoLayout>
    </Text>
  );
};

export default TermsOfServiceFooter;

const styles = StyleSheet.create({
  text: {
    color: deprecatedTones.gray6,
    fontSize: 12,
  },
});
