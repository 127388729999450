import * as React from "react";
import { IconProps } from "../types";

const UserSettingsIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9.5 7.25C11.0188 7.25 12.25 6.01878 12.25 4.5C12.25 2.98122 11.0188 1.75 9.5 1.75C7.98122 1.75 6.75 2.98122 6.75 4.5C6.75 6.01878 7.98122 7.25 9.5 7.25Z"
          fill={color}
          opacity="0.3"
        />
        <path
          d="M9.5 7.25C11.0188 7.25 12.25 6.01878 12.25 4.5C12.25 2.98122 11.0188 1.75 9.5 1.75C7.98122 1.75 6.75 2.98122 6.75 4.5C6.75 6.01878 7.98122 7.25 9.5 7.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 10.5V11.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.548 11.452L15.664 12.336"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 13.75H16.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.548 16.048L15.664 15.164"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 17V15.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.952 16.048L12.836 15.164"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 13.75H12.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.952 11.452L12.836 12.336"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.097 9.794C9.89899 9.775 9.70299 9.75 9.49999 9.75C6.94899 9.75 4.76099 11.28 3.79099 13.47C3.42599 14.295 3.87799 15.244 4.73799 15.515C5.95599 15.9 7.56899 16.244 9.47199 16.248"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 15.5C15.2165 15.5 16 14.7165 16 13.75C16 12.7835 15.2165 12 14.25 12C13.2835 12 12.5 12.7835 12.5 13.75C12.5 14.7165 13.2835 15.5 14.25 15.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default UserSettingsIcon;
