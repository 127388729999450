import { getPersonDetailRoute } from "@components/people/utils/getDetailRoute";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { useRouter } from "next/router";
import { GraphqlOperations } from "@src/types.generated";
import { useCreateUserMutation } from "../InviteAdminOrManagerModal.generated";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useInviteUserMutationWrapper = () => {
  const { closeModal } = useModal();
  const router = useRouter();
  const { addErrorToast } = useToast();
  return useCreateUserMutation({
    refetchQueries: [GraphqlOperations.Query.GetPeople],
    onCompleted(data) {
      if (data.createUser.success) {
        closeModal();
        if (data.createUser.user) {
          router.push(getPersonDetailRoute(data.createUser.user.id));
        }
      } else {
        let error_msg = "An unexpected error occurred";
        if (data.createUser.error?.code === "PHONE_NUMBER_IN_USE") {
          error_msg = "Phone number already in use";
        } else if (data.createUser.error?.code === "EMAIL_IN_USE") {
          error_msg = "Email already in use";
        }
        addErrorToast({
          data: data,
          message: error_msg,
          callsite: "use_invite_user_mutation_wrapper",
        });
      }
    },
    onError(error) {
      addErrorToast({
        error: error,
        message: "An unexpected error occurred",
        callsite: "use_invite_user_mutation_wrapper",
      });
    },
  });
};

export default useInviteUserMutationWrapper;
