import { DialogContent, DialogHeader, DialogTitle } from "@ui/dialog";
import {
  USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION,
  USER_TYPE_TO_PERMISSIONS,
  UserActionPermissionsKey,
} from "@utils/actionPermissions";
import { FC, useCallback, useMemo } from "react";
import { Checkbox } from "@ui/checkbox";
import { createDefaultActionPermissionSetInputForUserType } from "@utils/actionPermissions";
import { Input } from "@ui/input";
import useRoleGroups from "@hooks/useRoleGroups";
import { ROLE_USER_TYPE_COPY, ROLE_USER_TYPE_DESCRIPTION } from "@utils/copy";
import { ActionPermissionSetInput, UserType } from "@src/types.generated";
import { Label } from "@ui/label";
import Text from "@ui/text";
import { RoleGroupThinFragment } from "@hooks/useRoleGroups.generated";
import { Button } from "@ui/button";
import TrainerIcon from "@src/ui/icons/18px/trainer";
import UserSettingsIcon from "@src/ui/icons/18px/user-settings";
import StudentIcon from "@src/ui/icons/18px/student";
import { Tooltip, TooltipContent, TooltipTrigger } from "@src/ui/tooltip";
import CircleInfoOutline from "@src/ui/icons/18px/circle-info-outline";
import { Switch } from "@src/ui/switch";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { gql } from "@apollo/client";
import {
  RoleModalBody_RoleFragment,
  useRoleModalBody_RolesQuery,
} from "./RoleModalBody.generated";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import {
  Command,
  CommandInput,
  CommandList,
  CommandGroup,
  CommandItem,
  CommandEmpty,
} from "@src/ui/command";
import ChevronDown from "@src/ui/icons/18px/chevron-down";
import { RadioGroup } from "@src/ui/radio-group";
import RadioGroupChoiceboxItem from "../ui/RadioGroupChoiceboxItem";

const USER_TYPE_TO_SECTION_HEADER = {
  [UserType.Admin]: "Admins",
  [UserType.Manager]: "Managers",
  [UserType.Employee]: "Employees",
};

type Props = {
  title: string;
  name: string;
  setName: (name: string) => void;
  roleUserType: UserType | undefined;
  setRoleUserType: (userType: UserType) => void;
  permissions: ActionPermissionSetInput;
  setPermissions: (permissions: ActionPermissionSetInput) => void;
  clockInConfirmationRequired: boolean;
  setClockInConfirmationRequired: (
    clockInConfirmationRequired: boolean,
  ) => void;
  roleGroupIds: string[];
  setRoleGroupIds: (ids: string[]) => void;
  toggleClockInConfirmationRequired: () => void;
  saveCopy: string;
  onClose: () => void;
  onSave: () => void;
  loading: boolean;
  saveDisabled: boolean;
  directReportRoleIds: number[];
  setDirectReportRoleIds: (ids: number[]) => void;
};

type RolePermissionOptionsSelectionProps = {
  selectedUserType: UserType;
  permissions: ActionPermissionSetInput;
  setPermissions: (permissions: ActionPermissionSetInput) => void;
};

type PermissionRowProps = RolePermissionOptionsSelectionProps & {
  permission: UserActionPermissionsKey;
  required?: boolean;
};

type RoleTrainingOptionsSelectionProps = {
  clockInConfirmationRequired: boolean;
  toggleClockInConfirmationRequired: () => void;
};

type UserTypeRoleProps = {
  userType: UserType;
  selectedUserType?: UserType | undefined;
  className?: string;
};

const UserTypeRole: FC<UserTypeRoleProps> = ({
  userType,
  className,
  selectedUserType,
}) => {
  return (
    <RadioGroupChoiceboxItem
      testId={`role-user-type-radio-${userType}`.toLocaleLowerCase()}
      value={userType}
      className={className}
      selected={userType === selectedUserType}
    >
      {userType === UserType.Admin && <UserSettingsIcon className="mr-2" />}
      {userType === UserType.Manager && <TrainerIcon className="mr-2" />}
      {userType === UserType.Employee && <StudentIcon className="mr-2" />}
      <Label className="flex flex-col">
        <Text type="P3" fontWeight="Medium">
          {ROLE_USER_TYPE_COPY[userType]}
        </Text>
        <Text type="P3" fontWeight="Regular" className="text-muted-foreground">
          {ROLE_USER_TYPE_DESCRIPTION[userType]}
        </Text>
      </Label>
    </RadioGroupChoiceboxItem>
  );
};

const RoleTrainingOptionsSelection: FC<RoleTrainingOptionsSelectionProps> = ({
  clockInConfirmationRequired,
  toggleClockInConfirmationRequired,
}) => {
  return (
    <div className="flex items-center justify-between self-stretch">
      <Label htmlFor="clock-in-confirmation">
        Require clock-in confirmation to train
      </Label>
      <Switch
        id="clock-in-confirmation"
        checked={clockInConfirmationRequired}
        onCheckedChange={toggleClockInConfirmationRequired}
      />
    </div>
  );
};

const PermissionRow: FC<PermissionRowProps> = ({
  permission,
  permissions,
  setPermissions,
  required = false,
}) => {
  const checked = useMemo(
    () => required || Boolean(permissions[permission]?.value),
    [permissions, permission, required],
  );
  const toggleValue = useCallback(() => {
    setPermissions({
      ...permissions,
      [permission]: {
        value: !checked,
      },
    });
  }, [setPermissions, permissions, permission, checked]);
  return (
    <div className="flex items-center justify-between self-stretch">
      <div className="flex flex-1 items-center space-x-2 self-stretch">
        <Label
          htmlFor={`permission-${permission}`}
          className="cursor-pointer select-none text-sm font-normal text-foreground"
        >
          {USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION[permission].name}
        </Label>
        {typeof USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION[permission]
          ?.description === "string" && (
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <div
                style={{ cursor: "help" }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <CircleInfoOutline className="text-muted-foreground" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {String(
                USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION[permission]
                  .description,
              )}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <Checkbox
        id={`permission-${permission}`}
        checked={required || Boolean(permissions[permission]?.value)}
        onCheckedChange={toggleValue}
        disabled={required}
        className="mr-2"
      />
    </div>
  );
};

const RolePermissionOptionsSelection: FC<
  RolePermissionOptionsSelectionProps
> = (props) => {
  const { selectedUserType } = props;
  const { required, optionalDefaultChecked, optionalDefaultUnchecked } =
    USER_TYPE_TO_PERMISSIONS[selectedUserType];
  if (
    required.length +
      optionalDefaultChecked.length +
      (optionalDefaultUnchecked?.length || 0) ===
    0
  ) {
    return null;
  }
  return (
    <>
      <Text type="P3" fontWeight="SemiBold" className="mt-3">
        Permission options
      </Text>
      <div className="flex flex-col space-y-2 overflow-auto">
        {required.map((permission) => (
          <PermissionRow
            {...props}
            permission={permission}
            key={permission}
            required
          />
        ))}
        {optionalDefaultChecked.map((permission) => (
          <PermissionRow {...props} permission={permission} key={permission} />
        ))}
        {optionalDefaultUnchecked?.map((permission) => (
          <PermissionRow {...props} permission={permission} key={permission} />
        ))}
      </div>
    </>
  );
};

type RoleGroupMultiSelectFieldProps = {
  roleGroupIds: string[];
  setRoleGroupIds: (x: string[]) => void;
  roleGroupOptions?: RoleGroupThinFragment[];
  dataTestId?: string;
};

export const RoleGroupMultiSelectField: FC<RoleGroupMultiSelectFieldProps> = (
  props,
) => {
  const { roleGroups } = useRoleGroups();

  return (
    <div className="flex flex-col space-y-1 self-stretch">
      <Label className="mb-2">Departments</Label>
      <Popover modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="mb-4 flex shrink-0 justify-between truncate"
            data-testid={props.dataTestId}
          >
            <div className="truncate">
              {props.roleGroupIds.length === 0
                ? "Select Departments"
                : roleGroups
                    .filter((group) => props.roleGroupIds.includes(group.id))
                    .map((group) => group.name)
                    .join(", ")}
            </div>
            <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[1000] w-[380px] p-0">
          <Command>
            <CommandInput placeholder="Search departments" />
            <CommandList className="max-h-[300px] overflow-y-auto">
              <CommandEmpty>No departments found.</CommandEmpty>
              {roleGroups.map((group) => (
                <CommandItem
                  key={group.id}
                  onSelect={() => {
                    const newIds = props.roleGroupIds.includes(group.id)
                      ? props.roleGroupIds.filter((id) => id !== group.id)
                      : [...props.roleGroupIds, group.id];
                    props.setRoleGroupIds(newIds);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={props.roleGroupIds.includes(group.id)}
                      onCheckedChange={() => {}}
                    />
                    <span>{group.name}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

type DirectReportsProps = {
  directReportRoleIds: number[];
  setDirectReportRoleIds: (directReportRoleIds: number[]) => void;
  roles: RoleModalBody_RoleFragment[];
};

const DirectReports: FC<DirectReportsProps> = ({
  directReportRoleIds,
  setDirectReportRoleIds,
  roles,
}) => {
  return (
    <>
      <Label className="mb-2">Direct Reports</Label>
      <span className="mb-2 flex whitespace-pre-wrap text-sm leading-5 text-muted-foreground">
        Select roles that directly report to this role. Users are responsible
        for the check-ins and tasks of their direct reports.
      </span>
      <Popover modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="mb-4 flex shrink-0 justify-between truncate"
          >
            <div className="truncate">
              {directReportRoleIds.length === 0
                ? "Select roles"
                : roles
                    .filter((role) => directReportRoleIds.includes(role.id))
                    .map((role) => role.name)
                    .join(", ")}
            </div>
            <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[1000] w-[380px] p-0">
          <Command>
            <CommandInput placeholder="Search roles" />
            <CommandList className="max-h-[300px] overflow-y-auto">
              <CommandEmpty>No roles found.</CommandEmpty>
              {Object.values(UserType).map((userType) => {
                const filteredRoles = roles.filter(
                  (role) =>
                    role.roleActionPermissions?.roleUserType === userType,
                );
                if (filteredRoles.length === 0) return null;
                return (
                  <CommandGroup key={userType}>
                    <div className="flex items-center justify-between">
                      <Text
                        type="P3"
                        fontWeight="SemiBold"
                        className="ml-2 text-gray-8"
                      >
                        {USER_TYPE_TO_SECTION_HEADER[userType]}
                      </Text>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-primary"
                        onClick={() => {
                          const roleIds = filteredRoles.map((role) => role.id);
                          setDirectReportRoleIds(
                            directReportRoleIds.includes(roleIds[0])
                              ? directReportRoleIds.filter(
                                  (id) => !roleIds.includes(id),
                                )
                              : [...directReportRoleIds, ...roleIds],
                          );
                        }}
                      >
                        {directReportRoleIds.includes(filteredRoles[0]?.id)
                          ? "Deselect All"
                          : "Select All"}
                      </Button>
                    </div>
                    {filteredRoles.map((role) => (
                      <CommandItem
                        key={role.id}
                        onSelect={() => {
                          setDirectReportRoleIds(
                            directReportRoleIds.includes(role.id)
                              ? directReportRoleIds.filter(
                                  (id) => id !== role.id,
                                )
                              : [...directReportRoleIds, role.id],
                          );
                        }}
                        className="cursor-pointer"
                      >
                        <Checkbox
                          checked={directReportRoleIds.includes(role.id)}
                          className="mr-2 size-4"
                        />
                        <div className="flex w-full justify-between">
                          <Text type="P2">{role.name}</Text>
                          <Text type="P3" className="font-mono">
                            {role.userCount}
                          </Text>
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                );
              })}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
};

const RoleModalBody: FC<Props> = ({
  title,
  name,
  setName,
  roleUserType,
  setRoleUserType,
  permissions,
  setPermissions,
  clockInConfirmationRequired,
  setClockInConfirmationRequired,
  roleGroupIds,
  setRoleGroupIds,
  toggleClockInConfirmationRequired,
  saveCopy,
  onClose,
  onSave,
  loading,
  saveDisabled,
  directReportRoleIds,
  setDirectReportRoleIds,
}) => {
  const directReportsEnabled = useFeatureFlag("role-direct-reports");
  const { data, loading: rolesLoading } = useRoleModalBody_RolesQuery();
  const roles = useMemo(() => {
    return data?.Roles?.objects || [];
  }, [data]);
  const setSelectedUserTypeAndDefaultPermissionsForRole = useCallback(
    (v: string) => {
      const userType = v as UserType;
      setRoleUserType(userType);
      setClockInConfirmationRequired(userType !== UserType.Admin);
      setPermissions(
        createDefaultActionPermissionSetInputForUserType(userType),
      );
    },
    [setClockInConfirmationRequired, setPermissions, setRoleUserType],
  );
  if (rolesLoading) {
    return (
      <DialogContent className="z-[1000] flex max-h-[90vh] min-w-[975px] flex-col items-start overflow-auto">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <Spinner />
      </DialogContent>
    );
  }
  return (
    <DialogContent className="z-[1000] flex max-h-[90vh] min-w-[975px] flex-col items-start overflow-auto">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <div className="flex w-full flex-row justify-between overflow-auto p-1">
        <div className="flex w-[380px] min-w-[380px] flex-col self-stretch">
          <div className="mb-4 space-y-2">
            <Label htmlFor="role-name">Role Name</Label>
            <Input
              id="role-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              data-testid="role-name-input"
              placeholder="e.g., Dishwasher or GM"
            />
          </div>
          <div className="mb-4">
            <RoleGroupMultiSelectField
              roleGroupIds={roleGroupIds}
              setRoleGroupIds={setRoleGroupIds}
            />
          </div>
          {directReportsEnabled && (
            <DirectReports
              directReportRoleIds={directReportRoleIds}
              setDirectReportRoleIds={setDirectReportRoleIds}
              roles={roles}
            />
          )}
          {roleUserType && (
            <RoleTrainingOptionsSelection
              clockInConfirmationRequired={clockInConfirmationRequired}
              toggleClockInConfirmationRequired={
                toggleClockInConfirmationRequired
              }
            />
          )}
        </div>
        <div className="flex flex-col">
          <Label className="mb-4">Permissions</Label>
          <div className="flex w-[502px] min-w-[502px] flex-col overflow-auto rounded-md border border-input bg-background p-4 text-sm shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
            <div className="flex flex-col self-stretch">
              <RadioGroup
                value={roleUserType}
                onValueChange={setSelectedUserTypeAndDefaultPermissionsForRole}
                defaultValue={roleUserType}
              >
                <UserTypeRole
                  userType={UserType.Admin}
                  selectedUserType={roleUserType}
                  className="mb-2"
                />
                <UserTypeRole
                  userType={UserType.Manager}
                  selectedUserType={roleUserType}
                  className="mb-2"
                />
                <UserTypeRole
                  userType={UserType.Employee}
                  selectedUserType={roleUserType}
                />
              </RadioGroup>
            </div>
            {roleUserType && (
              <RolePermissionOptionsSelection
                selectedUserType={roleUserType}
                permissions={permissions}
                setPermissions={setPermissions}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-auto flex flex-row justify-end self-stretch">
        <Button variant="outline" onClick={onClose} className="mr-2">
          Cancel
        </Button>
        <Button disabled={saveDisabled || loading} onClick={onSave}>
          {loading && <Spinner size={20} />}
          {saveCopy}
        </Button>
      </div>
    </DialogContent>
  );
};

gql`
  fragment RoleModalBody_Role on Role {
    id
    name
    userCount
    roleActionPermissions {
      roleUserType
    }
  }
  query RoleModalBody_Roles {
    Roles {
      objects {
        ...RoleModalBody_Role
      }
    }
  }
`;
export default RoleModalBody;
