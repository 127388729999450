import { useCreateDashboardFeedbackMutation } from "@components/layout/topNav/HelpButton.generated";
import Text from "@ui/text";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedButton from "@src/deprecatedDesignSystem/components/Button";
import useDropdown from "@hooks/useDropdown";
import { useToast } from "@hooks/useToast";
import * as Popover from "@radix-ui/react-popover";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import useLanguage from "@src/hooks/useLanguage";
import { Button } from "@src/ui/button";
import Sparkles2OutlineIcon from "@src/ui/icons/12px/sparkles-2-outline";
import { useTranslation } from "@src/utils/translationSets";
import { css, StyleSheet } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import gql from "graphql-tag";
import React, { useState } from "react";

const HELP_ENGLISH = "Help";
const GO_TO_HELP_CENTER_ENGLISH = "Go to Help Center";
const SUBMIT_FEEDBACK_ENGLISH = "Submit feedback";
const JOIN_OUR_COMMUNITY_ENGLISH = "Join our community";
const READ_OUR_BLOG_ENGLISH = "Read our blog";
const HELP_US_IMPROVE_OPUS_ENGLISH = "Help us improve Opus";
const VIEW_ROADMAP_ENGLISH = "View roadmap";
const FEEDBACK_SUBMITTED_ENGLISH = "Feedback submitted";

export const HelpButton: React.FC = () => {
  const language = useLanguage();
  const helpTranslation = useTranslation(HELP_ENGLISH, language || "en");
  const goToHelpCenterTranslation = useTranslation(
    GO_TO_HELP_CENTER_ENGLISH,
    language || "en",
  );
  const submitFeedbackTranslation = useTranslation(
    SUBMIT_FEEDBACK_ENGLISH,
    language || "en",
  );
  const joinOurCommunityTranslation = useTranslation(
    JOIN_OUR_COMMUNITY_ENGLISH,
    language || "en",
  );
  const readOurBlogTranslation = useTranslation(
    READ_OUR_BLOG_ENGLISH,
    language || "en",
  );
  const feedbackSubmittedTranslation = useTranslation(
    FEEDBACK_SUBMITTED_ENGLISH,
    language || "en",
  );
  const viewRoadmapTranslation = useTranslation(
    VIEW_ROADMAP_ENGLISH,
    language || "en",
  );
  const helpUsImproveOpusTranslation = useTranslation(
    HELP_US_IMPROVE_OPUS_ENGLISH,
    language || "en",
  );
  const [feedback, setFeedback] = useState("");
  const {
    dropdownMenuRef: feedbackFormRef,
    isDropdownVisible: feedbackFormVisible,
    setIsDropdownVisible: setFeedbackFormVisible,
  } = useDropdown();
  const { addErrorToast, addToast } = useToast();
  const [createDashboardFeedback, { loading }] =
    useCreateDashboardFeedbackMutation({
      onCompleted: (data) => {
        if (data) {
          addToast({
            iconType: "checkmark",
            message:
              feedbackSubmittedTranslation.text || FEEDBACK_SUBMITTED_ENGLISH,
          });
          setFeedbackFormVisible(false);
        } else {
          addErrorToast({
            data,
            callsite: "dashboard_feedback_form",
          });
        }
        setFeedback("");
      },
    });

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="outline">
          <Sparkles2OutlineIcon className="text-muted-foreground" />
          <Text type="P2" fontWeight="SemiBold" color={deprecatedTones.black}>
            {helpTranslation.text || HELP_ENGLISH}
          </Text>
        </Button>
      </Popover.Trigger>
      <AnimatePresence>
        <Popover.Content
          asChild
          sideOffset={4}
          align="end"
          className={css(styles.dropdownContainer)}
        >
          <motion.div
            initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
            animate={{
              scaleY: 1,
              opacity: 1,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{ originY: 0, originX: 1 }}
          >
            <img
              src="/images/help-center.png"
              alt="help-center"
              className={css(styles.imageContainer)}
            />
            <div className={css(styles.menuContainer)}>
              <a
                className={css(styles.link)}
                href="https://help.opus.so/en/"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={css(
                    styles.textContainer,
                    styles.helpCenterContainer,
                  )}
                >
                  {goToHelpCenterTranslation.text || GO_TO_HELP_CENTER_ENGLISH}
                </div>
              </a>
              <div className={css(styles.feedbackFormContainer)}>
                {feedbackFormVisible && (
                  <div
                    className={css(styles.feedbackForm)}
                    ref={feedbackFormRef}
                  >
                    <textarea
                      style={{ resize: "none" }}
                      className={css(styles.feedbackText)}
                      value={feedback}
                      placeholder={
                        helpUsImproveOpusTranslation.text ||
                        HELP_US_IMPROVE_OPUS_ENGLISH
                      }
                      onChange={(event) => setFeedback(event.target.value)}
                    />
                    <DeprecatedButton
                      onClick={() => {
                        createDashboardFeedback({
                          variables: {
                            comment: feedback,
                          },
                        });
                      }}
                      style={{ marginTop: 5, width: 275 }}
                      disabled={feedback.length === 0}
                      loading={loading}
                      text={
                        submitFeedbackTranslation.text ||
                        SUBMIT_FEEDBACK_ENGLISH
                      }
                    />
                  </div>
                )}
                <div
                  className={css(styles.textContainer)}
                  onClick={() => setFeedbackFormVisible(true)}
                >
                  {submitFeedbackTranslation.text || SUBMIT_FEEDBACK_ENGLISH}
                </div>
              </div>

              <a
                className={css(styles.link)}
                href="https://opera.circle.so/join?invitation_token=9477c79011261250dde87b1878db9d885b5689d2-067fba4c-328b-4871-99a9-92b0459cc608"
                target="_blank"
                rel="noreferrer"
              >
                <div className={css(styles.textContainer)}>
                  {joinOurCommunityTranslation.text ||
                    JOIN_OUR_COMMUNITY_ENGLISH}
                </div>
              </a>
              <a
                className={css(styles.link)}
                href="https://opustraining.notion.site/opustraining/5c7e7b1164bb44e58f1f5afdeb74bbea?v=fe8fa7f83a5a499493c91f5d6140735f"
                target="_blank"
                rel="noreferrer"
              >
                <div className={css(styles.textContainer)}>
                  {viewRoadmapTranslation.text || VIEW_ROADMAP_ENGLISH}
                </div>
              </a>
              <a
                className={css(styles.link)}
                href="https://www.opus.so/blog"
                target="_blank"
                rel="noreferrer"
              >
                <div className={css(styles.textContainer)}>
                  {readOurBlogTranslation.text || READ_OUR_BLOG_ENGLISH}
                </div>
              </a>
            </div>
          </motion.div>
        </Popover.Content>
      </AnimatePresence>
    </Popover.Root>
  );
};

export default HelpButton;

gql`
  mutation createDashboardFeedback($comment: String!) {
    createDashboardFeedback(input: { comment: $comment }) {
      success
    }
  }
`;

const styles = StyleSheet.create({
  helpIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    width: 24,
    marginRight: 2,
  },
  imageContainer: {
    height: 192,
    width: 222,
    overflow: "hidden",
    borderRadius: "8px 8px 0px 0px",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    width: 222,
  },
  link: {
    display: "flex",
    cursor: "pointer",
    color: "unset",
    textDecoration: "unset",
  },
  textContainer: {
    width: 222,
    padding: "2px 0px 4px 10px",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  helpCenterContainer: {
    borderBottom: `solid 1px ${deprecatedTones.gray5Alpha}`,
  },
  feedbackFormContainer: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
  },
  feedbackForm: {
    height: 225,
    width: 300,
    position: "absolute",
    right: 225,
    top: 225,
    borderRadius: "8px",
    backgroundColor: deprecatedColors.background,
    boxShadow:
      "0px 16px 32px rgba(0, 0, 0, 0.05), 0px 16px 32px rgba(0, 0, 0, 0.05), 0px 8px 12px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05)",
    padding: 15,
  },
  feedbackFormTitle: {
    color: deprecatedColors.onBackground,
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 5,
  },
  feedbackText: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxSizing: "border-box",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 8,
    color: deprecatedColors.onBackground,
    fontSize: 14,
    padding: 12,
    width: "100%",
    height: 150,
    ":hover": {
      border: "1px solid #b9bac6",
    },
    ":focus": {
      boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
  },
  dropdownContainer: {
    borderRadius: "8px",
    backgroundColor: deprecatedColors.background,
    boxShadow: shadows.dropdownShadow,
    maxWidth: "400px",
    border: "none",
    display: "block",
    fontSize: "14px",
    lineHeight: "24px",
    margin: "0",
    outline: "none",
    textOverflow: "ellipsis",
    fontWeight: 400,
  },
});
