import AdminTools from "@components/layout/topNav/AdminTools";
import HelpButton from "@components/layout/topNav/HelpButton";
import { NotificationBell } from "@components/layout/topNav/NotificationBell";
import SettingsButton from "@components/layout/topNav/SettingsButton";
import JumpToBar from "@components/ui/jumpToBar/JumpToBar";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { StyleSheet } from "aphrodite";
import React from "react";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";

type Props = {
  scrollTop: number;
};

export const TopNav: React.FC<Props> = ({ scrollTop }) => {
  const { user } = useUser();
  const isTrainee = !user || user?.userType === UserType.Employee;
  return (
    <AutoLayout
      alignmentVertical="center"
      spacingMode="space-between"
      alignSelf="stretch"
      direction="horizontal"
      styleDeclaration={styles.container}
      style={{
        borderBottom:
          scrollTop > 0
            ? `1px solid ${deprecatedTones.gray5Alpha}`
            : "1px solid transparent",
      }}
    >
      {!isTrainee ? <JumpToBar /> : <AutoLayout flex={1} />}
      <AutoLayout className="shrink-0 gap-2">
        <AdminTools />
        {!isTrainee ? <NotificationBell /> : null}
        <HelpButton />
        <SettingsButton />
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    minHeight: 56,
    maxHeight: 56,
    zIndex: 40,
    padding: "0 16px",
    backgroundColor: deprecatedTones.white,
    overflow: "hidden",
  },
});

export default TopNav;
