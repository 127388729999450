import { FC, useRef } from "react";

import { cn } from "@src/ui/lib/utils";
import { RadioGroupItem } from "@src/ui/radio-group";

type Props = {
  className?: string;
  testId?: string;
  value: string;
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
};

const RadioGroupChoiceboxItem: FC<Props> = ({
  className,
  testId,
  value,
  children,
  disabled,
  selected,
}) => {
  const itemRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      className={cn(
        "flex items-center rounded-md border border-input bg-background p-2 ring-offset-background ",
        disabled
          ? "cursor-not-allowed [&>*]:cursor-not-allowed"
          : "cursor-pointer [&>*]:cursor-pointer",
        className,
        selected ? "border-primary" : "",
      )}
      onClick={() => {
        if (itemRef.current) {
          itemRef.current.click();
        }
      }}
    >
      {children}
      <RadioGroupItem
        disabled={disabled}
        data-testid={testId ? testId : undefined}
        className="ml-auto"
        value={value}
        ref={itemRef}
      />
    </div>
  );
};

export default RadioGroupChoiceboxItem;
