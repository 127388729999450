import { userTypeOptions } from "@components/people/personDetail/EditUserType";
import {
  PersonDetailDetailsFragment,
  useUpdateUserMutation,
} from "@components/people/personDetail/PersonDetails.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import MultiSelectField, {
  Option,
} from "@src/deprecatedDesignSystem/components/MultiSelectField";
import SegmentedControl from "@src/deprecatedDesignSystem/components/SegmentedControl";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import useActionPermissions from "@hooks/useActionPermissions";
import useLocations from "@hooks/useLocations";
import { useModal } from "@hooks/useModal";
import useRoles from "@hooks/useRoles";
import useCanAddMoreAdmins from "@src/hooks/billing/useCanAddMoreAdmins";
import { UserType } from "@src/types.generated";
import { StyleSheet, css } from "aphrodite";
import { FC, useMemo, useState } from "react";
import isEmail from "validator/lib/isEmail";
import UpgradePlanCTA from "./inviteOrEditUserModal/UpgradePlanCTA";
import UserTypeDescription from "./inviteOrEditUserModal/UserTypeDescription";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "@src/components/ui/dropdowns/constants";

type Props = {
  person: PersonDetailDetailsFragment;
};

const EditUserTypeModal: FC<Props> = ({ person }) => {
  const { userActionPermissions } = useActionPermissions();

  const canAddMoreAdmins = useCanAddMoreAdmins();
  const availableUserTypes = useMemo(() => {
    if (userActionPermissions?.changeOrgSettings) {
      return userTypeOptions;
    }
    return userTypeOptions.filter((option) => option.value !== UserType.Admin);
  }, [userActionPermissions?.changeOrgSettings]);
  const { closeModal } = useModal();
  const { locations } = useLocations();
  const { roles } = useRoles();
  const [updateError, setUpdateError] = useState<string | undefined>(undefined);
  const [selectedUserType, setSelectedUserType] = useState(person.userType);
  const [selectedRoleIds, setSelectedRoleIds] = useState(
    person.roles.map((role) => String(role.id)),
  );
  const [selectedLocationIds, setSelectedLocationIds] = useState(
    person.locations.map((location) => String(location.id)),
  );
  const [selectedEmail, setSelectedEmail] = useState(person.email || "");

  const roleOptions: Option<string>[] = useMemo(() => {
    return roles.map((role) => {
      return { value: role.id.toString(), label: role.name };
    });
  }, [roles]);

  const locationOptions: Option<string>[] = useMemo(() => {
    return locations.map((location) => {
      return { value: location.id.toString(), label: location.name };
    });
  }, [locations]);

  const onSaveClick = () => {
    if (selectedUserType !== UserType.Employee) {
      if (!selectedEmail || selectedEmail === "") {
        setUpdateError("Please enter an email");
        return;
      }
      if (!isEmail(selectedEmail)) {
        setUpdateError("Please use a valid email");
        return;
      }
    }

    if (
      selectedUserType === UserType.Manager &&
      selectedLocationIds.length === 0
    ) {
      setUpdateError("Please select a location");
      return;
    }

    updateUserMutation({
      variables: {
        id: person.id,
        input: {
          userType: { value: selectedUserType },
          roleIds: { value: selectedRoleIds.map((id) => Number(id)) },
          locationIds: {
            value: selectedLocationIds.map((id) => Number(id)),
          },
          email: { value: selectedEmail },
        },
      },
    });
  };

  const [updateUserMutation, { loading: updateLoading }] =
    useUpdateUserMutation({
      onCompleted: (data) => {
        if (data.updateUser.success) {
          setUpdateError(undefined);
          closeModal();
        } else {
          let errorMessage = "An unexpected error occurred";
          if (data.updateUser.error?.code === "EMAIL_IN_USE") {
            errorMessage = "Sorry another user has this email";
          }
          setUpdateError(errorMessage);
        }
      },
      onError() {
        setUpdateError("An unexpected error occurred");
      },
    });

  return (
    <Modal
      width={400}
      header={<TitleHeader title="Edit user type" onCancelClick={closeModal} />}
      footer={
        <Footer
          saveTitle="Save"
          onSaveClick={onSaveClick}
          isSaveLoading={updateLoading}
          cancelTitle="Cancel"
          onCancelClick={closeModal}
          isSaveDisabled={
            selectedUserType === UserType.Admin && !canAddMoreAdmins
          }
          leftContent={
            <Text type="P2" fontWeight="SemiBold" color={deprecatedTones.red9}>
              {updateError}
            </Text>
          }
        />
      }
    >
      <div className={css(styles.container)}>
        <SegmentedControl
          options={availableUserTypes}
          selectedOption={selectedUserType}
          onOptionSelected={setSelectedUserType}
          style={{ width: "100%" }}
        />
        {(selectedUserType !== UserType.Admin || canAddMoreAdmins) && (
          <UserTypeDescription userType={selectedUserType} />
        )}
        {selectedUserType === UserType.Admin && (
          <div>
            {canAddMoreAdmins ? (
              <>
                <TextField
                  label="Email*"
                  text={selectedEmail}
                  placeholder="Email"
                  onTextChange={setSelectedEmail}
                  leftIcon="mail"
                  height={"40px"}
                  containerStyleDeclaration={styles.textFieldContainer}
                />
                <MultiSelectField
                  values={selectedRoleIds}
                  options={roleOptions}
                  placeholder="Select a Role"
                  onValueChange={setSelectedRoleIds}
                  label="Roles"
                  icon="id-badge"
                  style={{ marginTop: 16 }}
                  dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
                />
              </>
            ) : (
              <UpgradePlanCTA description="You’ve reached the limit of admins on the Starter plan. To continue either delete an admin or upgrade your plan." />
            )}
          </div>
        )}
        {selectedUserType === UserType.Manager && (
          <div>
            <MultiSelectField
              values={selectedLocationIds}
              options={locationOptions}
              placeholder="Select a Location"
              onValueChange={setSelectedLocationIds}
              label="Locations*"
              icon="pin"
              dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
              style={{ marginTop: 16, marginBottom: 16 }}
            />
            <TextField
              label="Email*"
              text={selectedEmail}
              placeholder="Email"
              onTextChange={setSelectedEmail}
              leftIcon="mail"
              height={"40px"}
            />
            <MultiSelectField
              values={selectedRoleIds}
              options={roleOptions}
              placeholder="Select a Role"
              onValueChange={setSelectedRoleIds}
              label="Roles"
              icon="id-badge"
              dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
              style={{ marginTop: 16 }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  bannerContainer: {
    marginTop: 8,
    padding: "8px 12px",
    backgroundColor: deprecatedTones.blue2,
    borderRadius: 8,
  },
  bannerContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  listItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  textFieldContainer: {
    marginTop: 16,
  },
});

export default EditUserTypeModal;
