import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { StyleDeclaration } from "aphrodite";
import { CSSProperties, FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
  backgroundColor?: DeprecatedColor | DeprecateTone;
  paddingTop?: React.CSSProperties["paddingTop"];
  paddingHorizontal?: number;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
  width?: number;
};

const LeftSidebar: FC<Props> = ({
  children,
  backgroundColor = deprecatedTones.gray1,
  paddingTop = 10,
  paddingHorizontal = 0,
  styleDeclaration,
  style,
  width = 240,
}) => {
  return (
    <AutoLayout
      direction={"vertical"}
      alignSelf={"stretch"}
      width={width}
      paddingTop={paddingTop}
      paddingHorizontal={paddingHorizontal}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="hide-scrollbar"
      style={{
        overflow: "visible",
        minWidth: width,
        maxWidth: width,
        borderRight: `1px solid ${deprecatedTones.gray5Alpha}`,
        position: "sticky",
        left: 0,
        background: backgroundColor,
        top: 0,
        zIndex: 49,
        ...style,
      }}
      styleDeclaration={styleDeclaration}
    >
      {children}
    </AutoLayout>
  );
};

export default LeftSidebar;
