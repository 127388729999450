import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MultiSelectField from "@src/deprecatedDesignSystem/components/MultiSelectField";
import useLocations from "@hooks/useLocations";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  label?: string;
  placeholder?: string;
  locationIds: number[];
  setLocationIds: (x: number[]) => void;
  excludedLocationIds?: number[];
};

export const LocationMultiSelectField: FC<Props> = (props) => {
  const { locations } = useLocations();
  const options = useMemo(() => {
    return locations
      .filter((x) => !props.excludedLocationIds?.includes(x.id))
      .map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }));
  }, [locations, props.excludedLocationIds]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
      data-testid={"locations-field"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {props.label || "Locations"}
        </Text>
      </AutoLayout>
      <MultiSelectField
        style={{
          alignSelf: "stretch",
        }}
        values={props.locationIds.map((x) => x.toString())}
        onValueChange={(x) => {
          props.setLocationIds(x.map((x) => Number(x)));
        }}
        options={options}
        fontWeight={"Regular"}
        placeholder={props.placeholder || `Select locations...`}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
        searchEnabled={true}
      />
    </AutoLayout>
  );
};

export default LocationMultiSelectField;
