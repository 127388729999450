import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useToast } from "@hooks/useToast";
import { LocationGroupType } from "@src/types.generated";
import { AllNestedParents_LocationGroupFragment } from "@contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated";
import { flattenParentLocationGroups } from "@components/people/hooks/useSortNestedLocationGroupsByParents";
import { useLocationGroupChildrenOptions_LocationGroupsQuery } from "@components/home/hooks/useLocationGroupsChildrenOptionsWithoutCycles.generated";

type Args = {
  locationGroupId: string | undefined;
  parentLocationGroupId: string | undefined;
  type: LocationGroupType;
};

const useLocationGroupsChildrenOptionsWithoutCycles = (
  args: Args,
): AllNestedParents_LocationGroupFragment[] => {
  const { addErrorToast } = useToast();
  const { data: locationGroupData } =
    useLocationGroupChildrenOptions_LocationGroupsQuery({
      variables: {
        type: args.type,
      },
      onCompleted(data) {
        if (!data.LocationGroups.objects) {
          addErrorToast({ message: "Failed to load location groups" });
        }
      },
      onError() {
        addErrorToast({
          message: "Encountered unexpected error loading location groups",
        });
      },
    });
  const parentLocationGroup = useMemo(() => {
    return locationGroupData?.LocationGroups.objects.find(
      (x) => x.id === args.parentLocationGroupId,
    );
  }, [args.parentLocationGroupId, locationGroupData?.LocationGroups.objects]);
  const flattendParentLgIds: string[] = useMemo(() => {
    return parentLocationGroup
      ? flattenParentLocationGroups(parentLocationGroup)?.map((x) => x.id)
      : [];
  }, [parentLocationGroup]);
  return useMemo(() => {
    return (
      locationGroupData?.LocationGroups.objects
        .filter((x) => x.id !== args.locationGroupId)
        .filter((x) => !flattendParentLgIds.includes(x.id))
        .filter(
          (x) =>
            !x.parentLocationGroup ||
            x.parentLocationGroup.id === args.locationGroupId,
        ) || []
    );
  }, [
    args.locationGroupId,
    flattendParentLgIds,
    locationGroupData?.LocationGroups.objects,
  ]);
};

export default useLocationGroupsChildrenOptionsWithoutCycles;

gql`
  query LocationGroupChildrenOptions_LocationGroups($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      objects {
        ...AllNestedParents_LocationGroup
        userCount
      }
    }
  }
`;
