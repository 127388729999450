import { gql } from "@apollo/client";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useUser from "@hooks/useUser";
import DropdownMenu from "@src/deprecatedDesignSystem/components/DropdownMenu";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import useKeyboardNavigation from "@src/hooks/useKeyboardNavigation";
import { StyleSheet } from "aphrodite";
import React, { FC, useCallback, useMemo } from "react";
import { OrgLogo } from "./OrgLogo";
import {
  useOrgSwitcherQuery,
  useUpdateMyOrgMutation,
} from "./OrgSwitcher.generated";
import SearchBar from "@src/deprecatedDesignSystem/components/SearchBar";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { isInPast } from "@src/utils/time";
import useBranding from "@src/hooks/useBranding";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";

type Props = {
  maxWidth?: number;
};

const OrgSwitcher: FC<Props> = ({ maxWidth }) => {
  const { user } = useUser();
  const { name, brandDetails } = useBranding();
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "" });
  const { data, loading, previousData } = useOrgSwitcherQuery({
    variables: {
      input: {
        search:
          debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      },
    },
  });
  const [updateOrg] = useUpdateMyOrgMutation({
    onCompleted: (res) => {
      if (res.updateMyOrg.success) {
        window.location.reload();
      }
    },
  });
  const availableOrgs = useMemo(
    () =>
      data?.Me?.availableOrgs.objects ||
      previousData?.Me?.availableOrgs.objects ||
      [],
    [data, previousData],
  );
  const totalCount =
    data?.Me?.totalOrgCount.totalCount ||
    previousData?.Me?.totalOrgCount.totalCount ||
    0;
  const selectItemAtIndex = useCallback(
    (index: number) => {
      updateOrg({
        variables: {
          orgId: availableOrgs[index].id,
        },
      });
    },
    [availableOrgs, updateOrg],
  );
  const { keyboardSelectIndex, setKeyboardSelectIndex, onKeyDown } =
    useKeyboardNavigation(availableOrgs.length, selectItemAtIndex);
  const onSearchChange = useCallback(
    (value: string) => {
      setSearch(value);
      setKeyboardSelectIndex(0);
    },
    [setKeyboardSelectIndex, setSearch],
  );
  const disabled = totalCount <= 1 && !loading;
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger disabled={disabled} style={{ flex: 1 }}>
        <AutoLayout
          alignmentVertical="center"
          style={{ cursor: "pointer" }}
          link={disabled ? { pathname: "/" } : undefined}
          styleDeclaration={styles.currentOrg}
        >
          <OrgLogo
            orgId={user?.org?.id || 0}
            logoUrl={
              brandDetails?.logoUrls?.thumb || brandDetails?.logoUrls?.original
            }
          />

          <Text
            type="P2"
            fontWeight="Medium"
            style={{
              marginLeft: 8,
              maxWidth,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {name}
          </Text>
        </AutoLayout>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          style={{
            marginLeft: 6,
            width: 228,
            overflow: "hidden",
          }}
        >
          {totalCount > 10 && (
            <SearchBar
              value={search}
              onChange={onSearchChange}
              onKeyDown={onKeyDown}
              autoFocus
            />
          )}
          <AutoLayout
            direction="vertical"
            style={{
              maxHeight: 200,
              overflowY: "auto",
            }}
          >
            {loading && (
              <Text type="P1" marginLeft={12}>
                Loading...
              </Text>
            )}

            {availableOrgs.map((org, i) => {
              return (
                <AutoLayout
                  key={org.id}
                  paddingLeft={8}
                  paddingVertical={6}
                  alignmentVertical="center"
                  styleDeclaration={[
                    styles.orgOption,
                    i === keyboardSelectIndex && styles.selected,
                  ]}
                  spaceBetweenItems={8}
                  style={{ cursor: "pointer" }}
                  flex={1}
                  alignSelf="stretch"
                  onClick={() => updateOrg({ variables: { orgId: org.id } })}
                >
                  <OrgLogo
                    orgId={org.id}
                    logoUrl={
                      org.brandDetails?.logoUrls?.thumb ||
                      org.brandDetails?.logoUrls?.original
                    }
                  />
                  <Text
                    type="P2"
                    fontWeight="Regular"
                    ellipsis
                    style={{ flex: 1, alignSelf: "unset" }}
                    color={
                      org.deactivatedAt && isInPast(org.deactivatedAt)
                        ? deprecatedTones.gray7
                        : undefined
                    }
                  >
                    {org.name}
                  </Text>

                  {org.id === user?.org?.id && (
                    <DeprecatedIcon
                      type="checkmark"
                      iconSize={32}
                      style={{ marginRight: 4 }}
                    />
                  )}
                </AutoLayout>
              );
            })}
          </AutoLayout>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

const styles = StyleSheet.create({
  currentOrg: {
    flex: 1,

    alignSelf: "stretch",
    padding: 4,
    paddingLeft: 4,
    paddingRight: 12,
    borderRadius: 999,

    ":hover": {
      backgroundColor: deprecatedTones.white,
    },
  },
  orgOption: {
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
  },
  selected: {
    backgroundColor: deprecatedTones.gray5Alpha,
  },
});

gql`
  mutation UpdateMyOrg($orgId: Int!) {
    updateMyOrg(orgId: $orgId) {
      success
    }
  }
  fragment AvailableOrg on Org {
    id
    name
    deactivatedAt
    brandDetails {
      id
      logoUrls {
        id
        thumb
        original
      }
    }
  }
  query OrgSwitcher($input: AvailableOrgsInput!) {
    Me {
      availableOrgs(input: $input, pagination: { limit: 50 }) {
        totalCount
        objects {
          ...AvailableOrg
        }
      }
      totalOrgCount: availableOrgs(input: {}, pagination: { limit: 1 }) {
        totalCount
      }
    }
  }
`;
export default OrgSwitcher;
