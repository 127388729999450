import * as React from "react";
import { IconProps } from "../types";

const TrainerIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="19"
        height="18"
        fill="none"
        viewBox="0 0 19 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.5 5.5C10.7426 5.5 11.75 4.49264 11.75 3.25C11.75 2.00736 10.7426 1 9.5 1C8.25736 1 7.25 2.00736 7.25 3.25C7.25 4.49264 8.25736 5.5 9.5 5.5Z"
          fill={color}
          opacity="0.3"
        />
        <path
          d="M9.5 5.5C10.7426 5.5 11.75 4.49264 11.75 3.25C11.75 2.00736 10.7426 1 9.5 1C8.25736 1 7.25 2.00736 7.25 3.25C7.25 4.49264 8.25736 5.5 9.5 5.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 14.5L14.958 12.179C15.39 11.809 15.426 11.153 15.038 10.737L12.843 8.385C12.465 7.98 11.935 7.75 11.381 7.75H9.5H7.619C7.065 7.75 6.535 7.98 6.157 8.385L3.962 10.737C3.574 11.153 3.61 11.808 4.042 12.179L6.75 14.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 16.25V7.94897"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 16.25V7.94897"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.25 10.75H12.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default TrainerIcon;
