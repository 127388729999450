import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { AllNestedParents_RoleGroupFragmentDoc } from '../../contexts/RoleGroupDetailContext/RoleGroupDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRoleGroupMutationVariables = Types.Exact<{
  input: Types.RoleGroupInput;
}>;


export type CreateRoleGroupMutation = { __typename?: 'Mutation', createRoleGroup: { __typename?: 'RoleGroupMutationResult', success: boolean, roleGroup?: { __typename?: 'RoleGroup', id: string, name: string, childRoleGroups: Array<{ __typename?: 'RoleGroup', id: string, name: string }>, roleMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, role: { __typename?: 'Role', id: number, name: string } }>, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string } | null } | null } | null } | null } | null } | null } };

export type UpdateRoleGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.RoleGroupInput;
}>;


export type UpdateRoleGroupMutation = { __typename?: 'Mutation', updateRoleGroup: { __typename?: 'RoleGroupMutationResult', success: boolean, roleGroup?: { __typename?: 'RoleGroup', id: string, name: string, childRoleGroups: Array<{ __typename?: 'RoleGroup', id: string, name: string }>, roleMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, role: { __typename?: 'Role', id: number, name: string } }>, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string } | null } | null } | null } | null } | null } | null } };

export type RoleGroupModal_RoleGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RoleGroupModal_RoleGroupQuery = { __typename?: 'Query', RoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, childRoleGroups: Array<{ __typename?: 'RoleGroup', id: string, name: string }>, roleMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, role: { __typename?: 'Role', id: number, name: string } }>, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string } | null } | null } | null } | null } | null } | null };

export type RoleGroupModal_RolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RoleGroupModal_RolesQuery = { __typename?: 'Query', Roles: { __typename?: 'Roles', totalCount: number, objects: Array<{ __typename?: 'Role', id: number, name: string, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }> }> } };

export type RoleGroupModal_RoleGroupFragment = { __typename?: 'RoleGroup', id: string, name: string, childRoleGroups: Array<{ __typename?: 'RoleGroup', id: string, name: string }>, roleMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, role: { __typename?: 'Role', id: number, name: string } }>, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string } | null } | null } | null } | null } | null };

export const RoleGroupModal_RoleGroupFragmentDoc = gql`
    fragment RoleGroupModal_RoleGroup on RoleGroup {
  id
  name
  childRoleGroups {
    id
    name
  }
  roleMemberships {
    id
    role {
      id
      name
    }
  }
  ...AllNestedParents_RoleGroup
}
    ${AllNestedParents_RoleGroupFragmentDoc}`;
export const CreateRoleGroupDocument = gql`
    mutation CreateRoleGroup($input: RoleGroupInput!) {
  createRoleGroup(input: $input) {
    success
    roleGroup {
      ...RoleGroupModal_RoleGroup
    }
  }
}
    ${RoleGroupModal_RoleGroupFragmentDoc}`;
export type CreateRoleGroupMutationFn = Apollo.MutationFunction<CreateRoleGroupMutation, CreateRoleGroupMutationVariables>;

/**
 * __useCreateRoleGroupMutation__
 *
 * To run a mutation, you first call `useCreateRoleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleGroupMutation, { data, loading, error }] = useCreateRoleGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleGroupMutation, CreateRoleGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleGroupMutation, CreateRoleGroupMutationVariables>(CreateRoleGroupDocument, options);
      }
export type CreateRoleGroupMutationHookResult = ReturnType<typeof useCreateRoleGroupMutation>;
export type CreateRoleGroupMutationResult = Apollo.MutationResult<CreateRoleGroupMutation>;
export type CreateRoleGroupMutationOptions = Apollo.BaseMutationOptions<CreateRoleGroupMutation, CreateRoleGroupMutationVariables>;
export const UpdateRoleGroupDocument = gql`
    mutation UpdateRoleGroup($id: String!, $input: RoleGroupInput!) {
  updateRoleGroup(id: $id, input: $input) {
    success
    roleGroup {
      ...RoleGroupModal_RoleGroup
    }
  }
}
    ${RoleGroupModal_RoleGroupFragmentDoc}`;
export type UpdateRoleGroupMutationFn = Apollo.MutationFunction<UpdateRoleGroupMutation, UpdateRoleGroupMutationVariables>;

/**
 * __useUpdateRoleGroupMutation__
 *
 * To run a mutation, you first call `useUpdateRoleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleGroupMutation, { data, loading, error }] = useUpdateRoleGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleGroupMutation, UpdateRoleGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleGroupMutation, UpdateRoleGroupMutationVariables>(UpdateRoleGroupDocument, options);
      }
export type UpdateRoleGroupMutationHookResult = ReturnType<typeof useUpdateRoleGroupMutation>;
export type UpdateRoleGroupMutationResult = Apollo.MutationResult<UpdateRoleGroupMutation>;
export type UpdateRoleGroupMutationOptions = Apollo.BaseMutationOptions<UpdateRoleGroupMutation, UpdateRoleGroupMutationVariables>;
export const RoleGroupModal_RoleGroupDocument = gql`
    query RoleGroupModal_RoleGroup($id: String!) {
  RoleGroup(id: $id) {
    ...RoleGroupModal_RoleGroup
  }
}
    ${RoleGroupModal_RoleGroupFragmentDoc}`;

/**
 * __useRoleGroupModal_RoleGroupQuery__
 *
 * To run a query within a React component, call `useRoleGroupModal_RoleGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleGroupModal_RoleGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleGroupModal_RoleGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleGroupModal_RoleGroupQuery(baseOptions: Apollo.QueryHookOptions<RoleGroupModal_RoleGroupQuery, RoleGroupModal_RoleGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleGroupModal_RoleGroupQuery, RoleGroupModal_RoleGroupQueryVariables>(RoleGroupModal_RoleGroupDocument, options);
      }
export function useRoleGroupModal_RoleGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleGroupModal_RoleGroupQuery, RoleGroupModal_RoleGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleGroupModal_RoleGroupQuery, RoleGroupModal_RoleGroupQueryVariables>(RoleGroupModal_RoleGroupDocument, options);
        }
export type RoleGroupModal_RoleGroupQueryHookResult = ReturnType<typeof useRoleGroupModal_RoleGroupQuery>;
export type RoleGroupModal_RoleGroupLazyQueryHookResult = ReturnType<typeof useRoleGroupModal_RoleGroupLazyQuery>;
export type RoleGroupModal_RoleGroupQueryResult = Apollo.QueryResult<RoleGroupModal_RoleGroupQuery, RoleGroupModal_RoleGroupQueryVariables>;
export const RoleGroupModal_RolesDocument = gql`
    query RoleGroupModal_Roles {
  Roles {
    totalCount
    objects {
      id
      name
      roleGroupMemberships {
        id
        roleGroup {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useRoleGroupModal_RolesQuery__
 *
 * To run a query within a React component, call `useRoleGroupModal_RolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleGroupModal_RolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleGroupModal_RolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleGroupModal_RolesQuery(baseOptions?: Apollo.QueryHookOptions<RoleGroupModal_RolesQuery, RoleGroupModal_RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleGroupModal_RolesQuery, RoleGroupModal_RolesQueryVariables>(RoleGroupModal_RolesDocument, options);
      }
export function useRoleGroupModal_RolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleGroupModal_RolesQuery, RoleGroupModal_RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleGroupModal_RolesQuery, RoleGroupModal_RolesQueryVariables>(RoleGroupModal_RolesDocument, options);
        }
export type RoleGroupModal_RolesQueryHookResult = ReturnType<typeof useRoleGroupModal_RolesQuery>;
export type RoleGroupModal_RolesLazyQueryHookResult = ReturnType<typeof useRoleGroupModal_RolesLazyQuery>;
export type RoleGroupModal_RolesQueryResult = Apollo.QueryResult<RoleGroupModal_RolesQuery, RoleGroupModal_RolesQueryVariables>;