import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { BrandDetailsFragmentDoc, AiOrgContextFragmentDoc } from '../../hooks/useBranding.generated';
import { AllNestedParents_LocationGroupFragmentDoc } from '../../contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLocationGroupMutationVariables = Types.Exact<{
  input: Types.LocationGroupInput;
}>;


export type CreateLocationGroupMutation = { __typename?: 'Mutation', createLocationGroup: { __typename?: 'LocationGroupMutationResult', success: boolean, locationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, childLocationGroups: Array<{ __typename?: 'LocationGroup', id: string, name: string }>, locationMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, location: { __typename?: 'Location', id: number, name: string } }>, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null } | null } };

export type UpdateLocationGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.LocationGroupInput;
}>;


export type UpdateLocationGroupMutation = { __typename?: 'Mutation', updateLocationGroup: { __typename?: 'LocationGroupMutationResult', success: boolean, locationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, childLocationGroups: Array<{ __typename?: 'LocationGroup', id: string, name: string }>, locationMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, location: { __typename?: 'Location', id: number, name: string } }>, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null } | null } };

export type LocationGroupModal_LocationGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type LocationGroupModal_LocationGroupQuery = { __typename?: 'Query', LocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, childLocationGroups: Array<{ __typename?: 'LocationGroup', id: string, name: string }>, locationMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, location: { __typename?: 'Location', id: number, name: string } }>, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null } | null };

export type LocationGroupModal_LocationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LocationGroupModal_LocationsQuery = { __typename?: 'Query', Locations: { __typename?: 'Locations', totalCount: number, objects: Array<{ __typename?: 'Location', id: number, name: string, locationGroupMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, locationGroup: { __typename?: 'LocationGroup', id: string, type: Types.LocationGroupType, name: string } }> }> } };

export type LocationGroupModal_LocationGroupFragment = { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, childLocationGroups: Array<{ __typename?: 'LocationGroup', id: string, name: string }>, locationMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, location: { __typename?: 'Location', id: number, name: string } }>, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null };

export const LocationGroupModal_LocationGroupFragmentDoc = gql`
    fragment LocationGroupModal_LocationGroup on LocationGroup {
  id
  name
  brandDetails {
    ...BrandDetails
  }
  childLocationGroups {
    id
    name
  }
  locationMemberships {
    id
    location {
      id
      name
    }
  }
  ...AllNestedParents_LocationGroup
}
    ${BrandDetailsFragmentDoc}
${AllNestedParents_LocationGroupFragmentDoc}`;
export const CreateLocationGroupDocument = gql`
    mutation CreateLocationGroup($input: LocationGroupInput!) {
  createLocationGroup(input: $input) {
    success
    locationGroup {
      ...LocationGroupModal_LocationGroup
    }
  }
}
    ${LocationGroupModal_LocationGroupFragmentDoc}`;
export type CreateLocationGroupMutationFn = Apollo.MutationFunction<CreateLocationGroupMutation, CreateLocationGroupMutationVariables>;

/**
 * __useCreateLocationGroupMutation__
 *
 * To run a mutation, you first call `useCreateLocationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationGroupMutation, { data, loading, error }] = useCreateLocationGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationGroupMutation, CreateLocationGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationGroupMutation, CreateLocationGroupMutationVariables>(CreateLocationGroupDocument, options);
      }
export type CreateLocationGroupMutationHookResult = ReturnType<typeof useCreateLocationGroupMutation>;
export type CreateLocationGroupMutationResult = Apollo.MutationResult<CreateLocationGroupMutation>;
export type CreateLocationGroupMutationOptions = Apollo.BaseMutationOptions<CreateLocationGroupMutation, CreateLocationGroupMutationVariables>;
export const UpdateLocationGroupDocument = gql`
    mutation UpdateLocationGroup($id: String!, $input: LocationGroupInput!) {
  updateLocationGroup(id: $id, input: $input) {
    success
    locationGroup {
      ...LocationGroupModal_LocationGroup
    }
  }
}
    ${LocationGroupModal_LocationGroupFragmentDoc}`;
export type UpdateLocationGroupMutationFn = Apollo.MutationFunction<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>;

/**
 * __useUpdateLocationGroupMutation__
 *
 * To run a mutation, you first call `useUpdateLocationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationGroupMutation, { data, loading, error }] = useUpdateLocationGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>(UpdateLocationGroupDocument, options);
      }
export type UpdateLocationGroupMutationHookResult = ReturnType<typeof useUpdateLocationGroupMutation>;
export type UpdateLocationGroupMutationResult = Apollo.MutationResult<UpdateLocationGroupMutation>;
export type UpdateLocationGroupMutationOptions = Apollo.BaseMutationOptions<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>;
export const LocationGroupModal_LocationGroupDocument = gql`
    query LocationGroupModal_LocationGroup($id: String!) {
  LocationGroup(id: $id) {
    ...LocationGroupModal_LocationGroup
  }
}
    ${LocationGroupModal_LocationGroupFragmentDoc}`;

/**
 * __useLocationGroupModal_LocationGroupQuery__
 *
 * To run a query within a React component, call `useLocationGroupModal_LocationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGroupModal_LocationGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGroupModal_LocationGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationGroupModal_LocationGroupQuery(baseOptions: Apollo.QueryHookOptions<LocationGroupModal_LocationGroupQuery, LocationGroupModal_LocationGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationGroupModal_LocationGroupQuery, LocationGroupModal_LocationGroupQueryVariables>(LocationGroupModal_LocationGroupDocument, options);
      }
export function useLocationGroupModal_LocationGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationGroupModal_LocationGroupQuery, LocationGroupModal_LocationGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationGroupModal_LocationGroupQuery, LocationGroupModal_LocationGroupQueryVariables>(LocationGroupModal_LocationGroupDocument, options);
        }
export type LocationGroupModal_LocationGroupQueryHookResult = ReturnType<typeof useLocationGroupModal_LocationGroupQuery>;
export type LocationGroupModal_LocationGroupLazyQueryHookResult = ReturnType<typeof useLocationGroupModal_LocationGroupLazyQuery>;
export type LocationGroupModal_LocationGroupQueryResult = Apollo.QueryResult<LocationGroupModal_LocationGroupQuery, LocationGroupModal_LocationGroupQueryVariables>;
export const LocationGroupModal_LocationsDocument = gql`
    query LocationGroupModal_Locations {
  Locations {
    totalCount
    objects {
      id
      name
      locationGroupMemberships {
        id
        locationGroup {
          id
          type
          name
        }
      }
    }
  }
}
    `;

/**
 * __useLocationGroupModal_LocationsQuery__
 *
 * To run a query within a React component, call `useLocationGroupModal_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGroupModal_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGroupModal_LocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationGroupModal_LocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationGroupModal_LocationsQuery, LocationGroupModal_LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationGroupModal_LocationsQuery, LocationGroupModal_LocationsQueryVariables>(LocationGroupModal_LocationsDocument, options);
      }
export function useLocationGroupModal_LocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationGroupModal_LocationsQuery, LocationGroupModal_LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationGroupModal_LocationsQuery, LocationGroupModal_LocationsQueryVariables>(LocationGroupModal_LocationsDocument, options);
        }
export type LocationGroupModal_LocationsQueryHookResult = ReturnType<typeof useLocationGroupModal_LocationsQuery>;
export type LocationGroupModal_LocationsLazyQueryHookResult = ReturnType<typeof useLocationGroupModal_LocationsLazyQuery>;
export type LocationGroupModal_LocationsQueryResult = Apollo.QueryResult<LocationGroupModal_LocationsQuery, LocationGroupModal_LocationsQueryVariables>;