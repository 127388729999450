import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { AddressFragmentDoc } from '../../contexts/LocationDetailContext/LocationDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationModal_LocationQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type LocationModal_LocationQuery = { __typename?: 'Query', Location?: { __typename?: 'Location', id: number, name: string, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null, locationGroupMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, locationGroup: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } }> } | null };

export type CreateLocationMutationVariables = Types.Exact<{
  input: Types.CreateLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'LocationMutationResult', success: boolean, location?: { __typename?: 'Location', id: number, name: string, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null } | null } };

export type UpdateLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.UpdateLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'LocationMutationResult', success: boolean, location?: { __typename?: 'Location', id: number, name: string, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null } | null } };

export type LocationModal_LocationWithAddressFragment = { __typename?: 'Location', id: number, name: string, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null };

export type LocationModal_LocationFragment = { __typename?: 'Location', id: number, name: string, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null, locationGroupMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, locationGroup: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } }> };

export type LocationModal_LocationGroupQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type LocationModal_LocationGroupQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', objects: Array<{ __typename?: 'LocationGroup', id: string, name: string }> } };

export type LocationModal_BillingAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LocationModal_BillingAccountsQuery = { __typename?: 'Query', BillingAccounts: Array<{ __typename?: 'BillingAccount', id: string, subscriptions: Array<{ __typename?: 'BillingSubscription', id: string, plan: { __typename?: 'Plan', id: string, licenseType: Types.LicenseType } }> }> };

export const LocationModal_LocationWithAddressFragmentDoc = gql`
    fragment LocationModal_LocationWithAddress on Location {
  id
  name
  address {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export const LocationModal_LocationFragmentDoc = gql`
    fragment LocationModal_Location on Location {
  id
  name
  address {
    ...Address
  }
  locationGroupMemberships {
    id
    locationGroup {
      id
      name
      type
    }
  }
}
    ${AddressFragmentDoc}`;
export const LocationModal_LocationDocument = gql`
    query locationModal_Location($id: Int!) {
  Location(id: $id) {
    ...LocationModal_Location
  }
}
    ${LocationModal_LocationFragmentDoc}`;

/**
 * __useLocationModal_LocationQuery__
 *
 * To run a query within a React component, call `useLocationModal_LocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationModal_LocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationModal_LocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationModal_LocationQuery(baseOptions: Apollo.QueryHookOptions<LocationModal_LocationQuery, LocationModal_LocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationModal_LocationQuery, LocationModal_LocationQueryVariables>(LocationModal_LocationDocument, options);
      }
export function useLocationModal_LocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationModal_LocationQuery, LocationModal_LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationModal_LocationQuery, LocationModal_LocationQueryVariables>(LocationModal_LocationDocument, options);
        }
export type LocationModal_LocationQueryHookResult = ReturnType<typeof useLocationModal_LocationQuery>;
export type LocationModal_LocationLazyQueryHookResult = ReturnType<typeof useLocationModal_LocationLazyQuery>;
export type LocationModal_LocationQueryResult = Apollo.QueryResult<LocationModal_LocationQuery, LocationModal_LocationQueryVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    success
    location {
      ...LocationModal_LocationWithAddress
    }
  }
}
    ${LocationModal_LocationWithAddressFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: Int!, $input: UpdateLocationInput!) {
  updateLocation(id: $id, input: $input) {
    success
    location {
      ...LocationModal_LocationWithAddress
    }
  }
}
    ${LocationModal_LocationWithAddressFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const LocationModal_LocationGroupDocument = gql`
    query LocationModal_LocationGroup($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    objects {
      id
      name
    }
  }
}
    `;

/**
 * __useLocationModal_LocationGroupQuery__
 *
 * To run a query within a React component, call `useLocationModal_LocationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationModal_LocationGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationModal_LocationGroupQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLocationModal_LocationGroupQuery(baseOptions: Apollo.QueryHookOptions<LocationModal_LocationGroupQuery, LocationModal_LocationGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationModal_LocationGroupQuery, LocationModal_LocationGroupQueryVariables>(LocationModal_LocationGroupDocument, options);
      }
export function useLocationModal_LocationGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationModal_LocationGroupQuery, LocationModal_LocationGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationModal_LocationGroupQuery, LocationModal_LocationGroupQueryVariables>(LocationModal_LocationGroupDocument, options);
        }
export type LocationModal_LocationGroupQueryHookResult = ReturnType<typeof useLocationModal_LocationGroupQuery>;
export type LocationModal_LocationGroupLazyQueryHookResult = ReturnType<typeof useLocationModal_LocationGroupLazyQuery>;
export type LocationModal_LocationGroupQueryResult = Apollo.QueryResult<LocationModal_LocationGroupQuery, LocationModal_LocationGroupQueryVariables>;
export const LocationModal_BillingAccountsDocument = gql`
    query LocationModal_BillingAccounts {
  BillingAccounts(input: {}) {
    id
    subscriptions {
      id
      plan {
        id
        licenseType
      }
    }
  }
}
    `;

/**
 * __useLocationModal_BillingAccountsQuery__
 *
 * To run a query within a React component, call `useLocationModal_BillingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationModal_BillingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationModal_BillingAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationModal_BillingAccountsQuery(baseOptions?: Apollo.QueryHookOptions<LocationModal_BillingAccountsQuery, LocationModal_BillingAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationModal_BillingAccountsQuery, LocationModal_BillingAccountsQueryVariables>(LocationModal_BillingAccountsDocument, options);
      }
export function useLocationModal_BillingAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationModal_BillingAccountsQuery, LocationModal_BillingAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationModal_BillingAccountsQuery, LocationModal_BillingAccountsQueryVariables>(LocationModal_BillingAccountsDocument, options);
        }
export type LocationModal_BillingAccountsQueryHookResult = ReturnType<typeof useLocationModal_BillingAccountsQuery>;
export type LocationModal_BillingAccountsLazyQueryHookResult = ReturnType<typeof useLocationModal_BillingAccountsLazyQuery>;
export type LocationModal_BillingAccountsQueryResult = Apollo.QueryResult<LocationModal_BillingAccountsQuery, LocationModal_BillingAccountsQueryVariables>;