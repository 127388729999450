import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { ContentType } from "@src/deprecatedDesignSystem/components/ContentAvatar";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  contentType: ContentType;
  styleDeclaration?: StyleDeclaration;
  style?: React.CSSProperties;
};

type ColorValue = {
  backgroundColor: string;
  iconColor: string;
  iconType: DeprecatedIconType;
};
const contentTypeToColorValues: { [key in ContentType]: ColorValue } = {
  Module: {
    backgroundColor: deprecatedTones.orange4,
    iconColor: deprecatedTones.orange9,
    iconType: "lines",
  },
  PremiumPath: {
    backgroundColor: deprecatedTones.orange4,
    iconColor: deprecatedTones.orange9,
    iconType: "lines",
  },
  Course: {
    backgroundColor: deprecatedTones.blue3,
    iconColor: deprecatedTones.blue8,
    iconType: "bookmark",
  },
  Resource: {
    backgroundColor: deprecatedTones.purple3,
    iconColor: deprecatedTones.purple8,
    iconType: "file",
  },
  Skill: {
    backgroundColor: deprecatedTones.purple3,
    iconColor: deprecatedTones.purple8,
    iconType: "asterisk",
  },
};

const ContentTypeColorIcon: FC<Props> = ({
  contentType,
  styleDeclaration,
  style,
}) => {
  const contentTypeValues = contentTypeToColorValues[contentType];
  return (
    <div
      className={css(styleDeclaration, styles.container)}
      style={{ backgroundColor: contentTypeValues.backgroundColor, ...style }}
    >
      <DeprecatedIcon
        type={contentTypeValues.iconType}
        color={contentTypeValues.iconColor}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 24,
    minHeight: 24,
    maxWidth: 24,
    maxHeight: 24,
    borderRadius: "100%",
  },
});

export default ContentTypeColorIcon;
