import { useRouter } from "next/router";
import { useMemo } from "react";

const useNavButtonSelected = (
  basePath: string,
  searchParams?: URLSearchParams,
  detailPageId?: number | string,
): boolean => {
  const router = useRouter();
  const selected = useMemo(() => {
    const path = router.pathname;
    const routerTagText = router.query.tag;
    const basePathTagText = basePath.split("=")[1];
    if (basePathTagText !== undefined && routerTagText === basePathTagText) {
      return true;
    }
    const firstPathPart = basePath.split("/")[1] || "";
    const firstPart = path.split("/")[1] || "";
    if (firstPart === basePathTagText) {
      return true;
    }
    if (firstPart !== firstPathPart) {
      return false;
    }
    const splitPath = path.split("/");
    const lastPart =
      splitPath.length === 0 ? "" : splitPath[splitPath.length - 1];
    if (!detailPageId && lastPart === "[id]") {
      return false;
    }
    if (detailPageId != null) {
      return router.query?.["id"] === String(detailPageId);
    }
    let hasMismatch = false;
    if (searchParams) {
      searchParams.forEach((key, value) => {
        if (router.query[value] !== key) {
          hasMismatch = true;
        }
      });
    }
    return !hasMismatch;
  }, [basePath, detailPageId, router.pathname, router.query, searchParams]);
  return selected;
};

export default useNavButtonSelected;
