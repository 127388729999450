import { getPromptScreenId } from "@src/components/libraryItemDetailPages/course/hooks/useCourseScreensAtom";
import RichTextPreview from "@components/messaging/RichTextPreview";
import Text from "@ui/text";
import useUser from "@hooks/useUser";
import { NotificationFragment } from "@src/fragments.generated";
import { NotificationType } from "@src/types.generated";
import { Route } from "nextjs-routes";
import { useMemo } from "react";

type Return = {
  title: JSX.Element | string | undefined;
  body: JSX.Element | string | undefined;
  url: Route | undefined;
  creator: { id: number | undefined; name: string } | undefined;
};
export const useNotificationBody = (
  notification: NotificationFragment,
): Return | null => {
  const { user } = useUser();
  const draftCourseVersionId =
    notification?.course?.draftVersion.id.toString() || "";
  const draftPathVersionId =
    notification?.path?.draftVersion.id.toString() || "";
  return useMemo(() => {
    let body;
    let url: Route = {
      pathname: "/library",
    };
    let title;
    let creator;
    if (notification.type === NotificationType.CommentMention) {
      title = (
        <Text type="P2" multiline style={{ marginRight: 2 }}>
          <span style={{ fontWeight: 600 }}>
            {notification.comment?.createdBy.name}
          </span>
          {" mentioned you in a comment on "}
          <span style={{ fontWeight: 600 }}>
            {notification.course?.libraryItem.name.en}
          </span>
        </Text>
      );
      body = (
        <RichTextPreview
          text={notification.comment?.text || ""}
          isSelected={false}
        />
      );
      const promptUuid = notification.comment?.thread.prompt.uuid;
      url = {
        pathname: "/builder/course-version/[id]",
        query: {
          id: draftCourseVersionId,
          screenId: promptUuid ? getPromptScreenId(promptUuid) : undefined,
        },
      };
      creator = {
        id: notification.comment?.createdBy.id,
        name: notification.comment?.createdBy.name || "",
      };
    } else if (notification.type === NotificationType.CommentReply) {
      title = (
        <Text type="P2" multiline style={{ marginRight: 2 }}>
          <span style={{ fontWeight: 600 }}>
            {notification.comment?.createdBy.name}
          </span>
          {" replied to a comment in "}
          <span style={{ fontWeight: 600 }}>
            {notification.course?.libraryItem.name.en}
          </span>
        </Text>
      );
      body = (
        <RichTextPreview
          text={notification.comment?.text || ""}
          isSelected={false}
        />
      );
      if (notification.course) {
        const promptUuid = notification.comment?.thread.prompt.uuid;
        url = {
          pathname: "/builder/course-version/[id]",
          query: {
            id: draftCourseVersionId,
            screenId: promptUuid ? getPromptScreenId(promptUuid) : undefined,
          },
        };
      }
      creator = {
        id: notification.comment?.createdBy.id,
        name: notification.comment?.createdBy.name || "",
      };
    } else if (notification.type === NotificationType.NewComment) {
      title = (
        <Text type="P2" multiline style={{ marginRight: 2 }}>
          <span style={{ fontWeight: 600 }}>
            {notification.comment?.createdBy.name}
          </span>
          {" commented on "}
          <span style={{ fontWeight: 600 }}>
            {notification.course?.libraryItem.name.en}
          </span>
        </Text>
      );
      body = (
        <RichTextPreview
          text={notification.comment?.text || ""}
          isSelected={false}
        />
      );
      if (notification.course) {
        const promptUuid = notification.comment?.thread.prompt.uuid;
        url = {
          pathname: "/builder/course-version/[id]",
          query: {
            id: draftCourseVersionId,
            screenId: promptUuid ? getPromptScreenId(promptUuid) : undefined,
          },
        };
      }
      creator = {
        id: notification.comment?.createdBy.id,
        name: notification.comment?.createdBy.name || "",
      };
    } else if (notification.type === NotificationType.NewEditPermission) {
      creator = {
        id: user?.id,
        name: user?.name || "",
      };
      if (notification.course) {
        title = (
          <Text type="P2" multiline style={{ marginRight: 2 }}>
            {"You can now edit course "}
            <span style={{ fontWeight: 600 }}>
              {notification.course.libraryItem.name.en}
            </span>
          </Text>
        );
        url = {
          pathname: "/builder/course-version/[id]",
          query: {
            id: draftCourseVersionId,
          },
        };
      } else if (notification.path) {
        title = (
          <Text type="P2" multiline style={{ marginRight: 2 }}>
            {"You can now edit module "}
            <span style={{ fontWeight: 600 }}>
              {notification.path.libraryItem.name.en}
            </span>
          </Text>
        );
        url = {
          pathname: "/builder/path-version/[id]",
          query: {
            id: draftPathVersionId,
          },
        };
      }
    } else if (notification.type === NotificationType.NewCommentPermission) {
      creator = {
        id: user?.id,
        name: user?.name || "",
      };
      if (notification.course) {
        title = (
          <Text type="P2" multiline style={{ marginRight: 2 }}>
            {"You can now comment on course "}
            <span style={{ fontWeight: 600 }}>
              {notification.course.libraryItem.name.en}
            </span>
          </Text>
        );
        url = {
          pathname: "/builder/course-version/[id]",
          query: {
            id: draftCourseVersionId,
          },
        };
      }
    } else if (notification.type === NotificationType.NewViewPermission) {
      creator = {
        id: user?.id,
        name: user?.name || "",
      };
      if (notification.path) {
        title = (
          <Text type="P2" multiline style={{ marginRight: 2 }}>
            {"You can now view a module "}
            <span style={{ fontWeight: 600 }}>
              {notification.path.libraryItem.name.en}
            </span>
          </Text>
        );
        url = {
          pathname: "/builder/path-version/[id]",
          query: {
            id: draftPathVersionId,
          },
        };
      }
    } else if (notification.type === NotificationType.SharpReassignment) {
      title = (
        <Text type="P2" multiline style={{ marginRight: 2 }}>
          {notification.message}
        </Text>
      );
      url = {
        pathname: "/sexual-harassment-prevention",
      };
    } else if (notification.type === NotificationType.LowCreditBalance) {
      title = (
        <Text type="P2" multiline style={{ marginRight: 2 }}>
          {notification.message}
        </Text>
      );
      url = {
        pathname: "/marketplace",
      };
    } else {
      return null;
    }
    return {
      creator,
      title,
      body,
      url,
    };
  }, [
    draftCourseVersionId,
    draftPathVersionId,
    notification.comment?.createdBy.id,
    notification.comment?.createdBy.name,
    notification.comment?.text,
    notification.comment?.thread.prompt.uuid,
    notification.course,
    notification.path,
    notification.message,
    notification.type,
    user?.id,
    user?.name,
  ]);
};
