import NotificationList from "@components/layout/topNav/NotificationList";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import NotificationDot from "@src/deprecatedDesignSystem/components/NotificationDot";
import * as Popover from "@radix-ui/react-popover";
import { css, StyleSheet } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import gql from "graphql-tag";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import {
  useGetNotificationsQuery,
  useMarkNotificationsReadMutation,
} from "./NotificationBell.generated";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import { Button } from "@src/ui/button";
import BellOutlineIcon from "@src/ui/icons/12px/bell-outline";

export const NotificationBell: React.FC = () => {
  const PAGINATION_LIMIT = 15;
  const {
    data: newData,
    previousData,
    loading,
    fetchMore,
  } = useGetNotificationsQuery({
    variables: {
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });

  const data = newData || previousData;
  const totalNotifications = useMemo(() => {
    return data?.Notifications.totalCount || 0;
  }, [data?.Notifications.totalCount]);
  const notifications = useMemo(() => {
    return data?.Notifications.objects || [];
  }, [data?.Notifications.objects]);
  const hasMore = useMemo(() => {
    return notifications.length < totalNotifications;
  }, [notifications.length, totalNotifications]);

  const totalUnreadNotifications = useMemo(() => {
    return data?.UnreadCount || 0;
  }, [data?.UnreadCount]);

  const [readNotificationIds, setReadNotificationIds] = useState<Set<string>>(
    new Set(),
  );
  const [markNotificationsRead] = useMarkNotificationsReadMutation({
    refetchQueries: ["GetNotifications"],
    variables: {
      ids: Array.from(readNotificationIds),
    },
    onCompleted: () => {
      setReadNotificationIds(new Set());
    },
  });
  const onNotificationRead = (id: string) => {
    setReadNotificationIds((prev) => new Set(prev).add(id));
  };

  const debouncedFetchMore = debounce(
    () =>
      fetchMore({
        variables: {
          pagination: {
            limit: PAGINATION_LIMIT,
            offset: notifications.length,
          },
        },
      }),
    500,
  );

  return (
    <Popover.Root
      onOpenChange={(open) => {
        if (!open) {
          markNotificationsRead();
        }
      }}
    >
      <Popover.Trigger>
        <Button size="icon" variant="outline" className="relative">
          <BellOutlineIcon />
          {totalUnreadNotifications >= 1 && (
            <NotificationDot
              count={Math.min(totalUnreadNotifications, 99)}
              styleDeclaration={styles.dot}
            />
          )}
        </Button>
      </Popover.Trigger>
      <AnimatePresence>
        <Popover.Content
          asChild
          sideOffset={4}
          align="end"
          className={css(styles.dropdownContainer)}
        >
          <motion.div
            initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
            animate={{
              scaleY: 1,
              opacity: 1,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{ originY: 0, originX: 1 }}
          >
            <NotificationList
              notifications={notifications}
              loading={loading}
              hasMore={hasMore}
              fetchMoreNotifications={() => {
                if (hasMore) {
                  debouncedFetchMore();
                }
              }}
              onNotificationRead={onNotificationRead}
            />
          </motion.div>
        </Popover.Content>
      </AnimatePresence>
    </Popover.Root>
  );
};

export default NotificationBell;

gql`
  query Notifications {
    AdminInProductNotifications {
      ...Notification
    }
  }

  query GetNotifications($pagination: PaginationInput) {
    Notifications: InProductNotifications(input: {}, pagination: $pagination) {
      totalCount
      objects {
        ...Notification
      }
    }
    UnreadCount: InProductNotificationUnreadCount
  }

  mutation MarkNotificationsRead($ids: [String!]!) {
    markInProductNotificationsAsRead(inProductNotificationIds: $ids) {
      success
    }
  }
`;

const styles = StyleSheet.create({
  dot: {
    position: "absolute",
    top: -10,
    right: -10,
  },
  buttonContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: deprecatedColors.background,
    alignItems: "center",
    color: deprecatedTones.gray7,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: "40px",
    maxWidth: 32,
    height: 32,
    cursor: "pointer",
    marginLeft: 8,
    justifyContent: "center",
    fontWeight: 500,
    ":hover": {
      boxShadow: `0 0 0 4px ${deprecatedTones.gray4Alpha}`,
    },
  },
  dropdownContainer: {
    borderRadius: "8px",
    backgroundColor: deprecatedColors.background,
    boxShadow: shadows.dropdownShadow,
    maxWidth: "400px",
    border: "none",
    display: "block",
    fontSize: "14px",
    lineHeight: "24px",
    margin: "0",
    outline: "none",
    textOverflow: "ellipsis",
    fontWeight: 400,
  },
});
