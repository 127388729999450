import LeftSidebar from "@components/layout/leftSidebar/LeftSidebar";
import OrgInfoAndNewButton from "@components/layout/topNav/OrgInfoAndNewButton";
import TermsOfServiceFooter from "@components/settings/TermsOfServiceFooter";
import NavButton from "@components/ui/NavButton";
import Spacer from "@components/ui/Spacer";
import ScrollableFlexGrow from "@components/ui/flexBox/ScrollableFlexGrow";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import useScrollTop from "@hooks/useScrollTop";
import { useBillingInitialized } from "@src/hooks/billing/useBillingInitialized";
import { useRouter } from "next/router";
import { FC } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

export const SettingsLeftNav: FC = () => {
  const { userActionPermissions } = useActionPermissions();
  const { scrollTop, scrollProps } = useScrollTop();
  const router = useRouter();
  const billingInitialized = useBillingInitialized();
  return (
    <LeftSidebar paddingHorizontal={16}>
      <OrgInfoAndNewButton scrollTop={scrollTop} showNewButton={false} />
      <ScrollableFlexGrow
        onScroll={scrollProps.onScroll}
        flex={1}
        alignSelf={"stretch"}
      >
        <Spacer size={16} />
        <AutoLayout
          spaceBetweenItems={10}
          paddingLeft={4}
          link={{
            pathname: "/",
          }}
          alignmentVertical="center"
          marginBottom={12}
        >
          <DeprecatedIcon type="chevron-left" iconSize={24} />
          <Text type="H3" fontWeight="Medium">
            Settings
          </Text>
        </AutoLayout>

        {userActionPermissions?.changeOrgSettings ? (
          <>
            <NavButton
              title="Brand"
              path={{
                pathname: "/settings/brand",
              }}
              icon="color-swatch"
              selected={["/settings", "/settings/brand"].includes(
                router.asPath,
              )}
            />
            <NavButton
              title="AI Assist"
              path={{
                pathname: "/settings/ai-assist",
              }}
              icon="sparkles"
              selected={["/settings/ai-assist"].includes(router.asPath)}
            />
            <NavButton
              title="Wage & Hour"
              path={{
                pathname: "/settings/compliance",
              }}
              icon="timer"
              selected={["/settings/compliance"].includes(router.asPath)}
            />
            <NavButton
              title="Languages"
              path={{ pathname: "/settings/language" }}
              icon="language"
              selected={["/settings/language"].includes(router.asPath)}
            />
          </>
        ) : null}
        <NavButton
          title="Email"
          path={{
            pathname: "/settings/email",
          }}
          icon="mail"
          selected={["/settings/email"].includes(router.asPath)}
        />
        {billingInitialized && userActionPermissions?.manageBilling ? (
          <NavButton
            title="Billing"
            path={{
              pathname: "/settings/billing",
            }}
            icon="money"
            selected={["/settings/billing"].includes(router.asPath)}
          />
        ) : null}
        {userActionPermissions?.manageOrgIntegrations ? (
          <>
            <Text
              type="P2"
              fontWeight="SemiBold"
              color={deprecatedTones.gray9}
              style={{ marginTop: 20, marginLeft: 8, marginBottom: 4 }}
            >
              Integrations
            </Text>
            <NavButton
              title="HRIS"
              path={{
                pathname: "/settings/hris",
              }}
              icon="people"
              selected={["/settings/hris"].includes(router.asPath)}
            />
          </>
        ) : null}
        <TermsOfServiceFooter
          style={{ marginTop: "auto", marginBottom: 12, marginLeft: 12 }}
        />
      </ScrollableFlexGrow>
    </LeftSidebar>
  );
};
