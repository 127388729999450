import { LocationGroupType } from "@src/types.generated";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/components/DeprecatedIcon";

export const locationGroupTypeDisplayName: Record<LocationGroupType, string> = {
  [LocationGroupType.Brand]: "Brand",
  [LocationGroupType.Franchise]: "Franchise",
  [LocationGroupType.Region]: "Region",
};

export const locationGroupTypeDisplayNamePlural: Record<
  LocationGroupType,
  string
> = {
  [LocationGroupType.Brand]: "Brands",
  [LocationGroupType.Franchise]: "Franchises",
  [LocationGroupType.Region]: "Regions",
};

export const locationGroupTypeIcon: Record<
  LocationGroupType,
  DeprecatedIconType
> = {
  [LocationGroupType.Region]: "globe-with-pin",
  [LocationGroupType.Franchise]: "git-branch",
  [LocationGroupType.Brand]: "registered-trademark",
};
