import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { AllNestedParents_LocationGroupFragmentDoc } from '../../../contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationGroupChildrenOptions_LocationGroupsQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type LocationGroupChildrenOptions_LocationGroupsQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', objects: Array<{ __typename?: 'LocationGroup', userCount: number, id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null }> } };


export const LocationGroupChildrenOptions_LocationGroupsDocument = gql`
    query LocationGroupChildrenOptions_LocationGroups($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    objects {
      ...AllNestedParents_LocationGroup
      userCount
    }
  }
}
    ${AllNestedParents_LocationGroupFragmentDoc}`;

/**
 * __useLocationGroupChildrenOptions_LocationGroupsQuery__
 *
 * To run a query within a React component, call `useLocationGroupChildrenOptions_LocationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGroupChildrenOptions_LocationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGroupChildrenOptions_LocationGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLocationGroupChildrenOptions_LocationGroupsQuery(baseOptions: Apollo.QueryHookOptions<LocationGroupChildrenOptions_LocationGroupsQuery, LocationGroupChildrenOptions_LocationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationGroupChildrenOptions_LocationGroupsQuery, LocationGroupChildrenOptions_LocationGroupsQueryVariables>(LocationGroupChildrenOptions_LocationGroupsDocument, options);
      }
export function useLocationGroupChildrenOptions_LocationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationGroupChildrenOptions_LocationGroupsQuery, LocationGroupChildrenOptions_LocationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationGroupChildrenOptions_LocationGroupsQuery, LocationGroupChildrenOptions_LocationGroupsQueryVariables>(LocationGroupChildrenOptions_LocationGroupsDocument, options);
        }
export type LocationGroupChildrenOptions_LocationGroupsQueryHookResult = ReturnType<typeof useLocationGroupChildrenOptions_LocationGroupsQuery>;
export type LocationGroupChildrenOptions_LocationGroupsLazyQueryHookResult = ReturnType<typeof useLocationGroupChildrenOptions_LocationGroupsLazyQuery>;
export type LocationGroupChildrenOptions_LocationGroupsQueryResult = Apollo.QueryResult<LocationGroupChildrenOptions_LocationGroupsQuery, LocationGroupChildrenOptions_LocationGroupsQueryVariables>;