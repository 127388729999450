import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { AllNestedParents_RoleGroupFragmentDoc } from '../../../contexts/RoleGroupDetailContext/RoleGroupDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RoleGroupParentOptions_RoleGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RoleGroupParentOptions_RoleGroupsQuery = { __typename?: 'Query', RoleGroups: { __typename?: 'RoleGroups', objects: Array<{ __typename?: 'RoleGroup', userCount: number, id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string, parentRoleGroup?: { __typename?: 'RoleGroup', id: string, name: string } | null } | null } | null } | null } | null }> } };


export const RoleGroupParentOptions_RoleGroupsDocument = gql`
    query RoleGroupParentOptions_RoleGroups {
  RoleGroups {
    objects {
      userCount
      ...AllNestedParents_RoleGroup
    }
  }
}
    ${AllNestedParents_RoleGroupFragmentDoc}`;

/**
 * __useRoleGroupParentOptions_RoleGroupsQuery__
 *
 * To run a query within a React component, call `useRoleGroupParentOptions_RoleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleGroupParentOptions_RoleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleGroupParentOptions_RoleGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleGroupParentOptions_RoleGroupsQuery(baseOptions?: Apollo.QueryHookOptions<RoleGroupParentOptions_RoleGroupsQuery, RoleGroupParentOptions_RoleGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleGroupParentOptions_RoleGroupsQuery, RoleGroupParentOptions_RoleGroupsQueryVariables>(RoleGroupParentOptions_RoleGroupsDocument, options);
      }
export function useRoleGroupParentOptions_RoleGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleGroupParentOptions_RoleGroupsQuery, RoleGroupParentOptions_RoleGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleGroupParentOptions_RoleGroupsQuery, RoleGroupParentOptions_RoleGroupsQueryVariables>(RoleGroupParentOptions_RoleGroupsDocument, options);
        }
export type RoleGroupParentOptions_RoleGroupsQueryHookResult = ReturnType<typeof useRoleGroupParentOptions_RoleGroupsQuery>;
export type RoleGroupParentOptions_RoleGroupsLazyQueryHookResult = ReturnType<typeof useRoleGroupParentOptions_RoleGroupsLazyQuery>;
export type RoleGroupParentOptions_RoleGroupsQueryResult = Apollo.QueryResult<RoleGroupParentOptions_RoleGroupsQuery, RoleGroupParentOptions_RoleGroupsQueryVariables>;