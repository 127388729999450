import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RoleModalBody_RoleFragment = { __typename?: 'Role', id: number, name: string, userCount: number, roleActionPermissions?: { __typename?: 'RoleActionPermissions', roleUserType: Types.UserType } | null };

export type RoleModalBody_RolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RoleModalBody_RolesQuery = { __typename?: 'Query', Roles: { __typename?: 'Roles', objects: Array<{ __typename?: 'Role', id: number, name: string, userCount: number, roleActionPermissions?: { __typename?: 'RoleActionPermissions', roleUserType: Types.UserType } | null }> } };

export const RoleModalBody_RoleFragmentDoc = gql`
    fragment RoleModalBody_Role on Role {
  id
  name
  userCount
  roleActionPermissions {
    roleUserType
  }
}
    `;
export const RoleModalBody_RolesDocument = gql`
    query RoleModalBody_Roles {
  Roles {
    objects {
      ...RoleModalBody_Role
    }
  }
}
    ${RoleModalBody_RoleFragmentDoc}`;

/**
 * __useRoleModalBody_RolesQuery__
 *
 * To run a query within a React component, call `useRoleModalBody_RolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleModalBody_RolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleModalBody_RolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleModalBody_RolesQuery(baseOptions?: Apollo.QueryHookOptions<RoleModalBody_RolesQuery, RoleModalBody_RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleModalBody_RolesQuery, RoleModalBody_RolesQueryVariables>(RoleModalBody_RolesDocument, options);
      }
export function useRoleModalBody_RolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleModalBody_RolesQuery, RoleModalBody_RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleModalBody_RolesQuery, RoleModalBody_RolesQueryVariables>(RoleModalBody_RolesDocument, options);
        }
export type RoleModalBody_RolesQueryHookResult = ReturnType<typeof useRoleModalBody_RolesQuery>;
export type RoleModalBody_RolesLazyQueryHookResult = ReturnType<typeof useRoleModalBody_RolesLazyQuery>;
export type RoleModalBody_RolesQueryResult = Apollo.QueryResult<RoleModalBody_RolesQuery, RoleModalBody_RolesQueryVariables>;