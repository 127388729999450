import { gql } from "@apollo/client";
import { useCreateRoleMutation } from "@components/modals/NewRoleModal.generated";
import RoleModalBody from "@components/modals/RoleModalBody";
import { getRoleDetailRoute } from "@components/people/utils/getDetailRoute";

import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import {
  ActionPermissionSetInput,
  GraphqlOperations,
  UserType,
} from "@src/types.generated";
import { launchConfetti } from "@utils/confetti";
import { useRouter } from "next/router";
import { FC, useCallback, useMemo, useState } from "react";
import { Dialog, DialogTrigger } from "@ui/dialog";

type Props = {
  trigger?: React.ReactNode;
  onClose?: () => void;
};

const NewRoleModal: FC<Props> = ({ trigger, onClose }) => {
  const router = useRouter();
  const [name, setName] = useState("");
  const [roleUserType, setRoleUserType] = useState<UserType | undefined>();
  const [roleGroupIds, setRoleGroupIds] = useState<string[]>([]);
  const [directReportRoleIds, setDirectReportRoleIds] = useState<number[]>([]);
  const [permissions, setPermissions] = useState<ActionPermissionSetInput>({});
  const [clockInConfirmationRequired, setClockInConfirmationRequired] =
    useState(true);
  const { addErrorToast } = useToast();
  const { trackEvent } = useTracking();
  const toggleClockInConfirmationRequired = useCallback(() => {
    setClockInConfirmationRequired((current) => !current);
  }, []);
  const createRoleDisabled = useMemo(
    () => !name || !roleUserType,
    [name, roleUserType],
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createRoleMutation, { loading }] = useCreateRoleMutation({
    variables: {
      input: {
        name,
        roleUserType,
        permissions,
        roleGroupIds: { value: roleGroupIds },
        requireClockInConfirmation: clockInConfirmationRequired,
        directReportRoleIds: { value: directReportRoleIds },
      },
    },
    refetchQueries: [
      GraphqlOperations.Query.RolesList,
      GraphqlOperations.Query.GetRoles,
      GraphqlOperations.Query.RecentlyViewedRoles,
    ],
    onCompleted: (data) => {
      if (!data.createRole.success) {
        addErrorToast({
          data,
          callsite: "new_role_modal",
        });
      } else {
        trackEvent("created_role", { id: data.createRole?.role?.id });
        setIsDialogOpen(false);
        onClose && onClose();
        launchConfetti();
        if (data.createRole?.role?.id) {
          const id = data.createRole.role.id;
          router.push(getRoleDetailRoute(id));
        }
      }
    },
    onError: (error) => {
      addErrorToast({
        ...error,
        callsite: "new_role_modal",
      });
    },
  });

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <RoleModalBody
        title="New Role"
        name={name}
        setName={setName}
        roleUserType={roleUserType}
        setRoleUserType={setRoleUserType}
        permissions={permissions}
        setPermissions={setPermissions}
        roleGroupIds={roleGroupIds}
        setRoleGroupIds={setRoleGroupIds}
        clockInConfirmationRequired={clockInConfirmationRequired}
        setClockInConfirmationRequired={setClockInConfirmationRequired}
        toggleClockInConfirmationRequired={toggleClockInConfirmationRequired}
        saveCopy="Create Role"
        onClose={() => {
          setIsDialogOpen(false);
          onClose && onClose();
        }}
        onSave={createRoleMutation}
        loading={loading}
        saveDisabled={createRoleDisabled}
        directReportRoleIds={directReportRoleIds}
        setDirectReportRoleIds={setDirectReportRoleIds}
      />
    </Dialog>
  );
};

export default NewRoleModal;

gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      success
      role {
        ...RoleWithPermissions
      }
    }
  }

  fragment RoleWithPermissions on Role {
    id
    name
    roleGroupMemberships {
      id
      roleGroup {
        id
        name
      }
    }
    roleActionPermissions {
      ...RoleActionPermissions
    }
    directReports {
      id
      name
    }
  }

  fragment ActionPermissionSet on ActionPermissionSet {
    id
    changeOrgSettings
    manageOrgIntegrations
    manageBilling
    inviteOthers
    createContent
    manageLibrary
    assignContent
    unassignContent
    updateAutomations
    verifySkills
    viewReporting
    sendMessages
    editTrainees
    deactivateTrainees
    managePremiumContent
    promoteTrainees
    markAsComplete
  }

  fragment RoleActionPermissions on RoleActionPermissions {
    id
    roleUserType
    actionPermissions {
      ...ActionPermissionSet
    }
    requireClockInConfirmation
  }
`;
