import MainNav from "@components/layout/leftNav/MainNav";
import LeftSidebar from "@components/layout/leftSidebar/LeftSidebar";
import OrgInfoAndNewButton from "@components/layout/topNav/OrgInfoAndNewButton";
import ScrollableFlexGrow from "@components/ui/flexBox/ScrollableFlexGrow";
import Spacer from "@components/ui/Spacer";
import useScrollTop from "@hooks/useScrollTop";
import { useRouter } from "next/router";
import React from "react";
import { SettingsLeftNav } from "./SettingsLeftNav";

export const LeftNav: React.FC = () => {
  const { scrollTop, scrollProps } = useScrollTop();
  const router = useRouter();
  if (router.asPath.startsWith("/settings")) {
    return <SettingsLeftNav />;
  }
  return (
    <LeftSidebar paddingHorizontal={16}>
      <OrgInfoAndNewButton scrollTop={scrollTop} />
      <ScrollableFlexGrow
        onScroll={scrollProps.onScroll}
        flex={1}
        alignSelf={"stretch"}
      >
        <MainNav />
        <Spacer size={40} />
      </ScrollableFlexGrow>
    </LeftSidebar>
  );
};

export default LeftNav;
