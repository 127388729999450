import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { AllNestedParents_LocationGroupFragmentDoc } from '../../../contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationGroupParentOptions_LocationGroupsQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type LocationGroupParentOptions_LocationGroupsQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', objects: Array<{ __typename?: 'LocationGroup', userCount: number, id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, parentLocationGroup?: { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType } | null } | null } | null } | null } | null }> } };


export const LocationGroupParentOptions_LocationGroupsDocument = gql`
    query LocationGroupParentOptions_LocationGroups($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    objects {
      ...AllNestedParents_LocationGroup
      userCount
    }
  }
}
    ${AllNestedParents_LocationGroupFragmentDoc}`;

/**
 * __useLocationGroupParentOptions_LocationGroupsQuery__
 *
 * To run a query within a React component, call `useLocationGroupParentOptions_LocationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGroupParentOptions_LocationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGroupParentOptions_LocationGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLocationGroupParentOptions_LocationGroupsQuery(baseOptions: Apollo.QueryHookOptions<LocationGroupParentOptions_LocationGroupsQuery, LocationGroupParentOptions_LocationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationGroupParentOptions_LocationGroupsQuery, LocationGroupParentOptions_LocationGroupsQueryVariables>(LocationGroupParentOptions_LocationGroupsDocument, options);
      }
export function useLocationGroupParentOptions_LocationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationGroupParentOptions_LocationGroupsQuery, LocationGroupParentOptions_LocationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationGroupParentOptions_LocationGroupsQuery, LocationGroupParentOptions_LocationGroupsQueryVariables>(LocationGroupParentOptions_LocationGroupsDocument, options);
        }
export type LocationGroupParentOptions_LocationGroupsQueryHookResult = ReturnType<typeof useLocationGroupParentOptions_LocationGroupsQuery>;
export type LocationGroupParentOptions_LocationGroupsLazyQueryHookResult = ReturnType<typeof useLocationGroupParentOptions_LocationGroupsLazyQuery>;
export type LocationGroupParentOptions_LocationGroupsQueryResult = Apollo.QueryResult<LocationGroupParentOptions_LocationGroupsQuery, LocationGroupParentOptions_LocationGroupsQueryVariables>;