import * as React from "react";
import { IconProps } from "../types";

const Sparkles2OutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M6.5 1.75L7.845 5.154L11.25 6.5L7.845 7.846L6.5 11.25L5.154 7.846L1.75 6.5L5.154 5.154L6.5 1.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.492 1.49199L2.546 1.17699L2.23 0.229988C2.128 -0.0760117 1.621 -0.0760117 1.519 0.229988L1.203 1.17699L0.257 1.49199C0.104 1.54299 0 1.68599 0 1.84799C0 2.00999 0.104 2.15299 0.257 2.20399L1.203 2.51899L1.519 3.46599C1.57 3.61899 1.713 3.72199 1.874 3.72199C2.035 3.72199 2.179 3.61799 2.229 3.46599L2.545 2.51899L3.491 2.20399C3.644 2.15299 3.748 2.00999 3.748 1.84799C3.748 1.68599 3.644 1.54299 3.491 1.49199H3.492Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default Sparkles2OutlineIcon;
