import { useBillingBannerQuery } from "@components/layout/AddPaymentMethodBanner.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import { gql } from "graphql-request";
import { FC } from "react";

export const AddPaymentMethodBanner: FC = () => {
  const { userActionPermissions } = useActionPermissions();
  const { data } = useBillingBannerQuery({
    skip: !userActionPermissions?.manageBilling,
  });

  const needsPaymentMethod =
    data &&
    data.BillingAccounts.length > 0 &&
    data.BillingAccounts.filter((ba) => ba.paymentMethod).length === 0;
  if (!data || !needsPaymentMethod || data?.Me?.user?.org?.paysManually) {
    return null;
  }
  return (
    <AutoLayout
      style={{ backgroundColor: deprecatedTones.yellow9 }}
      alignSelf="stretch"
      alignmentHorizontal="center"
      padding={8}
      link={{ pathname: "/settings/billing" }}
    >
      <Text type="P1" fontWeight="Medium">
        Add a payment method to continue using Opus
      </Text>
    </AutoLayout>
  );
};

gql`
  query BillingBanner {
    Me {
      user {
        id
        org {
          id
          paysManually
        }
      }
    }
    BillingAccounts(input: {}) {
      id
      paymentMethod {
        id
      }
    }
  }
`;
