import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useToast } from "@hooks/useToast";
import { AllNestedParents_RoleGroupFragment } from "@contexts/RoleGroupDetailContext/RoleGroupDetailContext.generated";
import { flattenParentRoleGroups } from "@components/people/hooks/useSortNestedRoleGroupsByParents";
import { isNotNullOrUndefined } from "@utils/typeguards";
import { useRoleGroupParentOptions_RoleGroupsQuery } from "@components/home/hooks/useRoleGroupParentOptionWithoutCycles.generated";

type Args = {
  roleGroupId: string | undefined;
  childRoleGroupIds: string[];
};

const useRoleGroupParentOptionWithoutCycles = (
  args: Args,
): AllNestedParents_RoleGroupFragment[] => {
  const { addErrorToast } = useToast();
  const { data: roleGroupData } = useRoleGroupParentOptions_RoleGroupsQuery({
    onCompleted(data) {
      if (!data.RoleGroups.objects) {
        addErrorToast({ message: "Failed to load departments" });
      }
    },
    onError() {
      addErrorToast({
        message: "Encountered unexpected error loading departments",
      });
    },
  });
  const forbiddenRoleGroupIds: Set<string> = useMemo(() => {
    return new Set(
      [...args.childRoleGroupIds, args.roleGroupId].filter(
        isNotNullOrUndefined,
      ),
    );
  }, [args.childRoleGroupIds, args.roleGroupId]);
  return useMemo(() => {
    return (
      roleGroupData?.RoleGroups.objects
        .filter((x) => x.id !== args.roleGroupId)
        .filter(
          (x) =>
            !flattenParentRoleGroups(x)
              .map((x) => x.id)
              .find((y) => forbiddenRoleGroupIds.has(y)),
        )
        .filter(
          (x) =>
            !x.parentRoleGroup || x.parentRoleGroup.id === args.roleGroupId,
        ) || []
    );
  }, [
    args.roleGroupId,
    forbiddenRoleGroupIds,
    roleGroupData?.RoleGroups.objects,
  ]);
};

export default useRoleGroupParentOptionWithoutCycles;

gql`
  query RoleGroupParentOptions_RoleGroups {
    RoleGroups {
      objects {
        userCount
        ...AllNestedParents_RoleGroup
      }
    }
  }
`;
