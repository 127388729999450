import NewButton from "@components/layout/topNav/NewButton";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";
import React, { CSSProperties } from "react";
import OrgSwitcher from "./OrgSwitcher";

type Props = {
  scrollTop: number;
  showNewButton?: boolean;
  style?: CSSProperties;
};

const OrgInfoAndNewButton: React.FC<Props> = ({
  scrollTop,
  showNewButton = true,
  style,
}) => {
  const { user } = useUser();
  const isTrainee = user?.userType === UserType.Employee;
  return (
    <AutoLayout
      alignmentVertical="center"
      alignSelf="stretch"
      spacingMode="space-between"
      style={{
        borderBottom:
          scrollTop > 0
            ? `1px solid ${deprecatedTones.gray5Alpha}`
            : "1px solid transparent",
        paddingBottom: 4,
        marginLeft: -16,
        marginRight: -16,
        paddingLeft: 10,
        paddingRight: 16,
        ...style,
      }}
    >
      <OrgSwitcher maxWidth={showNewButton ? 128 : 170} />
      {showNewButton && !isTrainee && (
        <div>
          <NewButton />
        </div>
      )}
    </AutoLayout>
  );
};

export default OrgInfoAndNewButton;
