import { AllNestedParents_LocationGroupFragment } from "@contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { isNotNullOrUndefined } from "@utils/typeguards";

type Return = AllNestedParents_LocationGroupFragment[];

const useSortNestedLocationGroupsByParents = (
  locationGroups: AllNestedParents_LocationGroupFragment[],
): Return => {
  return useMemo(() => {
    return sortNestedLocationGroupsByParents(locationGroups);
  }, [locationGroups]);
};

export default useSortNestedLocationGroupsByParents;

export const sortNestedLocationGroupsByParents = (
  locationGroups: AllNestedParents_LocationGroupFragment[],
): Return => {
  return orderBy(locationGroups, locationGroupBreadCrumbName);
};

export const locationGroupBreadCrumbName = (
  locationGroup: AllNestedParents_LocationGroupFragment,
): string => {
  let ret = "";
  let curr: AllNestedParents_LocationGroupFragment | undefined | null =
    locationGroup;
  while (isNotNullOrUndefined(curr)) {
    if (ret === "") {
      ret = curr.name;
    } else {
      ret = curr.name + " / " + ret;
    }
    curr = curr.parentLocationGroup;
  }
  return ret;
};

export const flattenParentLocationGroups = (
  locationGroup: AllNestedParents_LocationGroupFragment,
): AllNestedParents_LocationGroupFragment[] => {
  const ret: AllNestedParents_LocationGroupFragment[] = [];
  let curr: AllNestedParents_LocationGroupFragment | undefined | null =
    locationGroup;
  while (isNotNullOrUndefined(curr)) {
    ret.push(curr);
    curr = curr.parentLocationGroup;
  }
  return ret;
};
