import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useRoleGroups from "@hooks/useRoleGroups";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import SingleSelectField from "@src/deprecatedDesignSystem/components/SingleSelectField";
import { RoleGroupThinFragment } from "@hooks/useRoleGroups.generated";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  label?: string;
  placeholder?: string;
  roleGroupId?: string;
  setRoleGroupId: (x: string | undefined) => void;
  roleGroupOptions?: RoleGroupThinFragment[];
};

export const RoleGroupSingleSelectField: FC<Props> = (props) => {
  const { roleGroups } = useRoleGroups();
  const options = useMemo(() => {
    return (props.roleGroupOptions || roleGroups).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [props.roleGroupOptions, roleGroups]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {props.label}
        </Text>
      </AutoLayout>
      <SingleSelectField
        searchEnabled={true}
        containerStyle={{
          alignSelf: "stretch",
        }}
        value={props.roleGroupId}
        onValueChange={(roleGroupId) => {
          props.setRoleGroupId(roleGroupId);
        }}
        options={options}
        includeNoneOption={true}
        placeholder={props.placeholder || `Select a ${props.label}...`}
        placeholderTextProps={{
          fontWeight: "Regular",
        }}
        fontWeight={"Regular"}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
      />
    </AutoLayout>
  );
};

export default RoleGroupSingleSelectField;
