import * as React from "react";
import { IconProps } from "../types";

const BellOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M10.75 8.25C9.922 8.25 9.25 7.578 9.25 6.75V4C9.25 2.205 7.795 0.75 6 0.75C4.205 0.75 2.75 2.205 2.75 4V6.75C2.75 7.578 2.078 8.25 1.25 8.25H10.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 12C7.105 12 8 11.105 8 10H4C4 11.105 4.895 12 6 12Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default BellOutlineIcon;
