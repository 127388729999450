import NewRoleModal from "@components/modals/NewRoleModal";
import BulkUploadTrainingResourceModal from "@components/modals/bulkUploadTrainingResourceModal/BulkUploadTrainingResourceModal";
import InviteQRCodeModal from "@components/modals/invitePeople/InviteQRCodeModal";
import InviteAdminOrManagerModal from "@src/components/people/personDetail/modals/inviteOrEditUserModal/InviteAdminOrManagerModal";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import ContentExplainerCard, {
  ContentType,
} from "@src/deprecatedDesignSystem/components/ContentExplainerCard";
import ContentTypeColorIcon from "@src/deprecatedDesignSystem/components/ContentTypeColorIcon";
import DropdownMenu from "@src/deprecatedDesignSystem/components/DropdownMenu";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import useIsFullAdmin from "@hooks/useIsFullAdmin";
import { useModal } from "@hooks/useModal";
import { styled } from "@stitches/react";
import { StyleSheet, css } from "aphrodite";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import NewModuleModal from "@src/components/contentLibrary/NewModuleModal";
import { useIsComplianceOnly } from "@src/hooks/useIsComplianceOnly";
import LocationModal from "@components/modals/LocationModal";
import useLocationGroups from "@hooks/useLocationGroups";
import LocationGroupModal from "@components/home/LocationGroupModal";
import {
  locationGroupTypeDisplayName,
  locationGroupTypeIcon,
} from "@components/home/constants";
import NewCourseModal from "@src/components/courseCatalog/NewCourseModal";
import RoleGroupModal from "@src/components/home/RoleGroupModal";
import MessageBlastModal from "@src/components/messaging/MessageBlastModal";

const AutoLayoutRow = styled(AutoLayout, {
  height: 32,
  width: 185,
  borderRadius: 64,
  marginTop: 2,
  cursor: "pointer",
  userSelect: "none",
  variants: {
    cursor: {
      disabled: { cursor: "not-allowed" },
    },
  },
});

type Props = {
  contentOnly?: boolean;
};

const NewButton: FC<Props> = ({ contentOnly = false }) => {
  const [open, setOpen] = useState(false);
  const { userActionPermissions } = useActionPermissions();
  const { showModal } = useModal();
  const isComplianceOnly = useIsComplianceOnly();
  const { locationGroupTypes } = useLocationGroups();
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        {contentOnly ? (
          userActionPermissions?.createContent ? (
            <Button
              leftIcon="plus"
              text="New content"
              data-testid="new-button"
            />
          ) : (
            <NoPermissionsTooltipWrapper
              hasPermission={false}
              caretPosition="Top"
              tooltipSide="bottom"
            >
              <Button
                disabled
                leftIcon="plus"
                text="New content"
                data-testid="new-button"
              />
            </NoPermissionsTooltipWrapper>
          )
        ) : (
          <Button
            leftIcon="plus"
            styleDeclaration={styles.circleTriggerButton}
            data-testid="new-button"
          />
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align={contentOnly ? "end" : "start"}>
          {!userActionPermissions ? (
            <AutoLayout
              width={185}
              height={225}
              alignmentHorizontal="center"
              alignmentVertical="center"
            >
              <Spinner />
            </AutoLayout>
          ) : (
            <div>
              {contentOnly ? null : (
                <>
                  <>
                    <DropdownMenu.Item
                      disabled={!userActionPermissions.changeOrgSettings}
                      onSelect={() => {
                        showModal(
                          <LocationModal navigateToLocationOnSave={true} />,
                        );
                      }}
                    >
                      <NoPermissionsTooltipWrapper
                        hasPermission={userActionPermissions.changeOrgSettings}
                      >
                        <AutoLayoutRow
                          alignmentVertical="center"
                          styleDeclaration={styles.firstButton}
                          data-testid="new-location-button"
                          cursor={
                            !userActionPermissions.changeOrgSettings
                              ? "disabled"
                              : undefined
                          }
                        >
                          <div className={css(styles.iconContainer)}>
                            <DeprecatedIcon
                              iconSize={24}
                              type="pin"
                              color={deprecatedTones.gray9}
                            />
                          </div>
                          <Text
                            type="P2"
                            fontWeight="Regular"
                            color={deprecatedTones.gray8}
                          >
                            Location
                          </Text>
                        </AutoLayoutRow>
                      </NoPermissionsTooltipWrapper>
                    </DropdownMenu.Item>
                    <NewRoleModal
                      onClose={() => setOpen(false)}
                      trigger={
                        <DropdownMenu.Item
                          disabled={!userActionPermissions.changeOrgSettings}
                          onSelect={(e) => e.preventDefault()}
                        >
                          <NoPermissionsTooltipWrapper
                            hasPermission={
                              userActionPermissions.changeOrgSettings
                            }
                          >
                            <AutoLayoutRow
                              alignmentVertical="center"
                              data-testid="new-role-button"
                            >
                              <div className={css(styles.iconContainer)}>
                                <DeprecatedIcon
                                  iconSize={24}
                                  type="id-badge"
                                  color={deprecatedTones.gray9}
                                />
                              </div>
                              <Text
                                type="P2"
                                fontWeight="Regular"
                                color={deprecatedTones.gray8}
                              >
                                Role
                              </Text>
                            </AutoLayoutRow>
                          </NoPermissionsTooltipWrapper>
                        </DropdownMenu.Item>
                      }
                    />
                    <DropdownMenu.Item
                      disabled={!userActionPermissions.changeOrgSettings}
                      onSelect={() => {
                        showModal(
                          <RoleGroupModal navigateToDetailPageOnSave={true} />,
                        );
                      }}
                    >
                      <NoPermissionsTooltipWrapper
                        hasPermission={userActionPermissions.changeOrgSettings}
                      >
                        <AutoLayoutRow
                          alignmentVertical="center"
                          data-testid="new-role-group-button"
                        >
                          <div className={css(styles.iconContainer)}>
                            <DeprecatedIcon
                              iconSize={24}
                              type="users-circle"
                              color={deprecatedTones.gray9}
                            />
                          </div>
                          <Text
                            type="P2"
                            fontWeight="Regular"
                            color={deprecatedTones.gray8}
                          >
                            Department
                          </Text>
                        </AutoLayoutRow>
                      </NoPermissionsTooltipWrapper>
                    </DropdownMenu.Item>
                    {locationGroupTypes.map((type) => {
                      return (
                        <DropdownMenu.Item
                          key={type}
                          disabled={!userActionPermissions.changeOrgSettings}
                          onSelect={() => {
                            showModal(
                              <LocationGroupModal
                                type={type}
                                navigateToDetailPageOnSave={true}
                              />,
                            );
                          }}
                        >
                          <NoPermissionsTooltipWrapper
                            hasPermission={
                              userActionPermissions.changeOrgSettings
                            }
                          >
                            <AutoLayoutRow
                              alignmentVertical="center"
                              data-testid={`new-${type}-button`}
                            >
                              <div className={css(styles.iconContainer)}>
                                <DeprecatedIcon
                                  iconSize={24}
                                  type={locationGroupTypeIcon[type]}
                                  color={deprecatedTones.gray9}
                                />
                              </div>
                              <Text
                                type="P2"
                                fontWeight="Regular"
                                color={deprecatedTones.gray8}
                              >
                                {locationGroupTypeDisplayName[type]}
                              </Text>
                            </AutoLayoutRow>
                          </NoPermissionsTooltipWrapper>
                        </DropdownMenu.Item>
                      );
                    })}
                  </>
                  <InviteTraineeOrAdminItem />
                  <DropdownMenu.Item
                    disabled={!userActionPermissions?.sendMessages}
                    onSelect={() => {
                      showModal(<MessageBlastModal />);
                    }}
                  >
                    <NoPermissionsTooltipWrapper
                      hasPermission={userActionPermissions?.sendMessages}
                    >
                      <AutoLayoutRow
                        alignmentVertical="center"
                        cursor={
                          !userActionPermissions.sendMessages
                            ? "disabled"
                            : undefined
                        }
                      >
                        <div className={css(styles.iconContainer)}>
                          <DeprecatedIcon
                            iconSize={24}
                            type="message"
                            color={deprecatedTones.gray9}
                          />
                        </div>
                        <Text
                          type="P2"
                          fontWeight="Regular"
                          color={deprecatedTones.gray8}
                        >
                          Message
                        </Text>
                      </AutoLayoutRow>
                    </NoPermissionsTooltipWrapper>
                  </DropdownMenu.Item>
                </>
              )}
              {!contentOnly && !isComplianceOnly ? (
                <DropdownMenu.Separator
                  style={{
                    marginTop: 2,
                  }}
                >
                  <Text
                    type="P4"
                    fontWeight="Bold"
                    color={deprecatedTones.gray9}
                    style={{
                      textTransform: "uppercase",
                      marginLeft: 6,
                    }}
                  >
                    Content
                  </Text>
                </DropdownMenu.Separator>
              ) : null}
            </div>
          )}
          {!isComplianceOnly && <CreateContentRows setOpen={setOpen} />}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

type CreateContentRowsProps = {
  setOpen: (open: boolean) => void;
};

const CreateContentRows: FC<CreateContentRowsProps> = ({ setOpen }) => {
  const router = useRouter();
  const { showModal, closeModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  return (
    <>
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger
          disabled={!userActionPermissions?.createContent}
          onClick={() => {
            setOpen(false);
            showModal(
              <NewModuleModal
                onModuleCreated={(p) => {
                  const libraryItemId = p.libraryItem.id;
                  if (libraryItemId) {
                    router.push({
                      pathname: "/library/library-item/[id]",
                      query: { id: libraryItemId },
                    });
                  }
                }}
              />,
            );
          }}
        >
          <NoPermissionsTooltipWrapper
            hasPermission={userActionPermissions?.createContent}
          >
            <AutoLayoutRow
              alignmentVertical="center"
              cursor={
                !userActionPermissions?.createContent ? "disabled" : undefined
              }
            >
              <ContentTypeColorIcon
                contentType="Module"
                styleDeclaration={styles.iconContainer}
              />
              <Text
                type="P2"
                fontWeight="Regular"
                color={deprecatedTones.gray8}
              >
                {"Module"}
              </Text>
            </AutoLayoutRow>
          </NoPermissionsTooltipWrapper>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            style={{ zIndex: 200, width: 200, padding: 0 }}
            sideOffset={4}
            alignOffset={-85}
          >
            <DropdownMenu.Item
              onSelect={() => {
                showModal(
                  <NewModuleModal
                    onModuleCreated={(p) => {
                      const libraryItemId = p.libraryItem.id;
                      if (libraryItemId) {
                        router.push({
                          pathname: "/library/library-item/[id]",
                          query: { id: libraryItemId },
                        });
                      }
                    }}
                  />,
                );
              }}
            >
              <ContentExplainerCard type={ContentType.MODULE} />
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger
          disabled={!userActionPermissions?.createContent}
          onClick={() => {
            setOpen(false);
            showModal(
              <NewCourseModal
                onSelectMarketplaceOption={() => {
                  closeModal();
                  router.push({
                    pathname: "/marketplace",
                  });
                }}
                onCourseCreated={(c) => {
                  closeModal();
                  const libraryItemId = c.libraryItem.id;
                  if (libraryItemId) {
                    router.push({
                      pathname: "/library/library-item/[id]",
                      query: { id: libraryItemId },
                    });
                  }
                }}
              />,
            );
          }}
        >
          <NoPermissionsTooltipWrapper
            hasPermission={userActionPermissions?.createContent}
          >
            <AutoLayoutRow
              alignmentVertical="center"
              data-testid="new-course-button"
              cursor={
                !userActionPermissions?.createContent ? "disabled" : undefined
              }
            >
              <ContentTypeColorIcon
                contentType="Course"
                styleDeclaration={styles.iconContainer}
              />
              <Text
                type="P2"
                fontWeight="Regular"
                color={deprecatedTones.gray8}
              >
                Course
              </Text>
            </AutoLayoutRow>
          </NoPermissionsTooltipWrapper>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            style={{ zIndex: 200, width: 200, padding: 0 }}
            sideOffset={13}
            alignOffset={-85}
          >
            <DropdownMenu.Item
              onSelect={() => {
                showModal(
                  <NewCourseModal
                    onSelectMarketplaceOption={() =>
                      router.push({
                        pathname: "/marketplace",
                      })
                    }
                    onCourseCreated={(c) =>
                      router.push({
                        pathname: "/builder/course-version/[id]",
                        query: {
                          id: c.draftVersion.id.toString(),
                        },
                      })
                    }
                  />,
                );
              }}
            >
              <ContentExplainerCard type={ContentType.COURSE} />
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger
          disabled={!userActionPermissions?.createContent}
          onClick={() => {
            setOpen(false);
            showModal(<BulkUploadTrainingResourceModal />);
          }}
        >
          <NoPermissionsTooltipWrapper
            hasPermission={userActionPermissions?.createContent}
          >
            <AutoLayoutRow
              alignmentVertical="center"
              cursor={
                !userActionPermissions?.createContent ? "disabled" : undefined
              }
              marginBottom={3}
            >
              <ContentTypeColorIcon
                contentType="Resource"
                styleDeclaration={styles.iconContainer}
              />
              <Text
                type="P2"
                fontWeight="Regular"
                color={deprecatedTones.gray8}
              >
                Resource
              </Text>
            </AutoLayoutRow>
          </NoPermissionsTooltipWrapper>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            style={{ zIndex: 200, width: 200, padding: 0 }}
            sideOffset={4}
            alignOffset={-85}
          >
            <DropdownMenu.Item
              onSelect={() => {
                showModal(<BulkUploadTrainingResourceModal />);
              }}
            >
              <ContentExplainerCard type={ContentType.FILE} />
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
};

const InviteTraineeOrAdminItem: FC = () => {
  const { showModal } = useModal();
  const isFullAdmin = useIsFullAdmin();
  const { userActionPermissions } = useActionPermissions();
  const hasPermission = userActionPermissions?.inviteOthers;
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger disabled={!hasPermission}>
        <NoPermissionsTooltipWrapper hasPermission={hasPermission}>
          <AutoLayoutRow
            cursor={!hasPermission ? "disabled" : undefined}
            alignmentVertical="center"
            spacingMode="space-between"
          >
            <AutoLayout alignmentVertical="center">
              <div className={css(styles.iconContainer)}>
                <DeprecatedIcon
                  iconSize={24}
                  type="user-plus"
                  color={deprecatedTones.gray9}
                />
              </div>
              <Text
                type="P2"
                fontWeight="Regular"
                color={deprecatedTones.gray8}
              >
                Invite
              </Text>
            </AutoLayout>
            {hasPermission ? (
              <DeprecatedIcon
                iconSize={24}
                type="chevron-right"
                color={deprecatedTones.gray9}
                style={{ marginRight: 8 }}
              />
            ) : null}
          </AutoLayoutRow>
        </NoPermissionsTooltipWrapper>
      </DropdownMenu.SubTrigger>
      {hasPermission ? (
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent style={{ zIndex: 200 }}>
            <DropdownMenu.Item
              onSelect={() => {
                showModal(<InviteQRCodeModal />);
              }}
            >
              <AutoLayoutRow alignmentVertical="center">
                <Text
                  type="P2"
                  fontWeight="Regular"
                  style={{ marginLeft: 20 }}
                  color={deprecatedTones.gray8}
                >
                  Trainee
                </Text>
              </AutoLayoutRow>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => {
                showModal(<InviteAdminOrManagerModal />);
              }}
            >
              <AutoLayoutRow alignmentVertical="center">
                <Text
                  type="P2"
                  fontWeight="Regular"
                  style={{ marginLeft: 20 }}
                  color={deprecatedTones.gray8}
                >
                  {isFullAdmin ? "Admin or manager" : "Manager"}
                </Text>
              </AutoLayoutRow>
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      ) : null}
    </DropdownMenu.Sub>
  );
};

const styles = StyleSheet.create({
  circleTriggerButton: {
    width: 28,
    height: 28,
    background: deprecatedTones.blue9,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(236, 236, 236, 0.9)",
    borderRadius: 30,
  },
  firstButton: {
    marginTop: 0,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 24,
    minHeight: 24,
    maxWidth: 24,
    maxHeight: 24,
    borderRadius: "100%",
    marginLeft: 4,
    marginRight: 12,
  },
});

export default NewButton;
