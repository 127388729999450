import { CSSProperties, FC, useMemo } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";

type Props = {
  count?: number;
  styleDeclaration?: StyleDeclaration;
  styles?: CSSProperties;
};

const NotificationDot: FC<Props> = ({ count, styleDeclaration }) => {
  const backgroundColor = useMemo(() => {
    if (count) {
      return deprecatedTones.red9;
    } else {
      return deprecatedTones.blue9;
    }
  }, [count]);

  const size = useMemo(() => {
    if (count) {
      return 24;
    } else {
      return 12;
    }
  }, [count]);

  return (
    <div
      className={css(!!count && styles.countContainer, styleDeclaration)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`,
        backgroundColor,
        ...styles,
      }}
    >
      {count && (
        <Text type="P3" fontWeight="SemiBold" color={deprecatedTones.white}>
          {count}
        </Text>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  countContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NotificationDot;
