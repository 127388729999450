import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useToast } from "@hooks/useToast";
import { LocationGroupType } from "@src/types.generated";
import { AllNestedParents_LocationGroupFragment } from "@contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated";
import { flattenParentLocationGroups } from "@components/people/hooks/useSortNestedLocationGroupsByParents";
import { isNotNullOrUndefined } from "@utils/typeguards";
import { useLocationGroupParentOptions_LocationGroupsQuery } from "@components/home/hooks/useLocationGroupParentOptionWithoutCycles.generated";

type Args = {
  locationGroupId: string | undefined;
  childLocationGroupIds: string[];
  type: LocationGroupType;
};

const useLocationGroupParentOptionWithoutCycles = (
  args: Args,
): AllNestedParents_LocationGroupFragment[] => {
  const { addErrorToast } = useToast();
  const { data: locationGroupData } =
    useLocationGroupParentOptions_LocationGroupsQuery({
      variables: {
        type: args.type,
      },
      onCompleted(data) {
        if (!data.LocationGroups.objects) {
          addErrorToast({ message: "Failed to load location groups" });
        }
      },
      onError() {
        addErrorToast({
          message: "Encountered unexpected error loading location groups",
        });
      },
    });
  const forbiddenLgIds: Set<string> = useMemo(() => {
    return new Set(
      [...args.childLocationGroupIds, args.locationGroupId].filter(
        isNotNullOrUndefined,
      ),
    );
  }, [args.childLocationGroupIds, args.locationGroupId]);
  return useMemo(() => {
    return (
      locationGroupData?.LocationGroups.objects
        .filter((x) => x.id !== args.locationGroupId)
        .filter(
          (x) =>
            !flattenParentLocationGroups(x)
              .map((x) => x.id)
              .find((y) => forbiddenLgIds.has(y)),
        )
        .filter(
          (x) =>
            !x.parentLocationGroup ||
            x.parentLocationGroup.id === args.locationGroupId,
        ) || []
    );
  }, [
    args.locationGroupId,
    forbiddenLgIds,
    locationGroupData?.LocationGroups.objects,
  ]);
};

export default useLocationGroupParentOptionWithoutCycles;

gql`
  query LocationGroupParentOptions_LocationGroups($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      objects {
        ...AllNestedParents_LocationGroup
        userCount
      }
    }
  }
`;
