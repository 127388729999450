import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import Tab from "@src/deprecatedDesignSystem/components/Tab";
import useNavButtonSelected from "@hooks/useNavButtonSelected";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import NextLink from "next/link";
import { FC, RefObject } from "react";
import { ConnectDropTarget } from "react-dnd";
import { URLSearchParams } from "url";
import { route, Route } from "nextjs-routes";

type Props = {
  title: string;
  path: Route;
  icon?: DeprecatedIconType;
  iconComponent?: JSX.Element;
  searchParams?: URLSearchParams;
  styleDeclaration?: StyleDeclaration;
  detailPageId?: number | string;
  ref?: RefObject<HTMLDivElement>;
  dropRef?: ConnectDropTarget;
  selected?: boolean;
};

const NavButton: FC<Props> = ({
  title,
  path,
  icon,
  iconComponent,
  searchParams,
  styleDeclaration,
  detailPageId,
  ref,
  selected,
}) => {
  const inferredSelected = useNavButtonSelected(
    route(path),
    // @ts-ignore
    searchParams,
    detailPageId,
  );
  return (
    <div ref={ref} style={{ alignSelf: "stretch" }}>
      <NextLink
        href={path}
        shallow
        className={css(styles.link)}
        data-testid={"left-nav-button"}
      >
        <Tab
          icon={icon}
          iconSize={24}
          iconComponent={iconComponent}
          type="Pill"
          height="32px"
          active={selected !== undefined ? selected : inferredSelected}
          borderRadius="64px"
          text={title}
          styleDeclaration={[styleDeclaration, styles.tab]}
          fontWeight="Regular"
          ellipsis
        />
      </NextLink>
    </div>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecoration: "none",
    alignSelf: "stretch",
  },
  tab: { paddingLeft: 6 },
});

export default NavButton;
