import { gql } from "@apollo/client";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import { getLocationDetailRoute } from "@components/people/utils/getDetailRoute";
import useOrgSharpConfigContext from "@contexts/OrgSharpConfigContext/hooks/useOrgSharpConfigContext";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import {
  AddressInput,
  CreateLocationInput,
  GraphqlOperations,
  LocationGroupType,
} from "@src/types.generated";
import { launchConfetti } from "@utils/confetti";
import { useRouter } from "next/router";
import { FC, useCallback, useMemo, useState } from "react";
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useLocationModal_LocationQuery,
} from "@components/modals/LocationModal.generated";
import useLocationGroups from "@hooks/useLocationGroups";
import AddressField from "@src/deprecatedDesignSystem/components/AddressField";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import LocationGroupMultiSelectField from "@components/ui/LocationGroupMultiSelectField";
import LocationGroupSingleSelectField from "@components/ui/LocationGroupSingleSelectField";
import { isNotNullOrUndefined } from "@utils/typeguards";

type Props = {
  id?: number;
  initialInput?: Partial<CreateLocationInput>;
  navigateToLocationOnSave?: boolean;
};
const LocationModal: FC<Props> = (props) => {
  const { orgSharpConfig } = useOrgSharpConfigContext();
  const addressRequired = useMemo(() => !!orgSharpConfig, [orgSharpConfig]);
  const router = useRouter();
  const [address, setAddress] = useState<AddressInput | undefined>(
    props.initialInput?.addressInput || undefined,
  );
  const [name, setName] = useState(props.initialInput?.name || "");
  const [locationGroupIds, setLocationGroupIds] = useState<string[]>(
    props.initialInput?.locationGroupIds?.value || [],
  );
  const { locationGroups: allLocationGroups, locationGroupTypes } =
    useLocationGroups();
  const selectedLocationGroups = useMemo(() => {
    return allLocationGroups.filter((x) => locationGroupIds.includes(x.id));
  }, [allLocationGroups, locationGroupIds]);
  const [hasNameValidationErrors, setHasNameValidationErrors] = useState(false);
  const [hasAddressValidationErrors, setHasAddressValidationErrors] =
    useState(false);
  useLocationModal_LocationQuery({
    skip: !props.id,
    variables: {
      id: props.id || -1,
    },
    onCompleted(data) {
      if (data.Location) {
        setName(data.Location.name);
        if (data.Location.address) {
          setAddress(data.Location.address);
        }
        setLocationGroupIds(
          data.Location.locationGroupMemberships.map(
            (membership) => membership.locationGroup.id,
          ),
        );
      }
    },
  });
  const { closeModal } = useModal();
  const { addErrorToast } = useToast();
  const { trackEvent } = useTracking();
  const input: CreateLocationInput = useMemo(() => {
    return {
      name,
      addressInput: address,
      locationGroupIds: {
        value: locationGroupIds,
      },
    };
  }, [address, locationGroupIds, name]);
  const [createLocationMutation, { loading: createLocationLoading }] =
    useCreateLocationMutation({
      variables: {
        input,
      },
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (!data.createLocation.success) {
          addErrorToast({
            data,
            callsite: "new_location_modal",
          });
        } else {
          trackEvent("created_location", {
            id: data.createLocation?.location?.id,
          });
          closeModal();
          launchConfetti();
          if (
            props.navigateToLocationOnSave &&
            data.createLocation?.location?.id
          ) {
            const id = data.createLocation.location.id;
            router.push(getLocationDetailRoute(id));
          }
        }
      },
      onError: (error) => {
        addErrorToast({
          ...error,
          callsite: "new_location_modal",
        });
      },
    });
  const [updateLocationMutation, { loading: updateLocationLoading }] =
    useUpdateLocationMutation({
      variables: {
        id: props.id || -1,
        input,
      },
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (!data.updateLocation.success) {
          addErrorToast({
            data,
            callsite: "update_location_modal",
          });
        } else {
          closeModal();
        }
      },
      onError: (error) => {
        addErrorToast({
          ...error,
          callsite: "update_location_modal",
        });
      },
    });
  const onSave = useCallback(() => {
    const _nameValidationErrors = !name;
    const _addressValidationErrors = addressRequired && !address?.lineOne;
    const hasValidationErrors =
      _nameValidationErrors || _addressValidationErrors;
    if (hasValidationErrors) {
      setHasNameValidationErrors(_nameValidationErrors);
      setHasAddressValidationErrors(_addressValidationErrors);
    } else if (props.id) {
      updateLocationMutation();
    } else {
      createLocationMutation();
    }
  }, [
    address,
    addressRequired,
    createLocationMutation,
    name,
    props.id,
    updateLocationMutation,
  ]);

  return (
    <Modal
      header={
        <TitleHeader
          title={props.id ? "Edit Location" : "New Location"}
          onCancelClick={closeModal}
        />
      }
      width={439}
      footer={
        <Footer
          saveTitle={props.id ? "Save" : "Create"}
          isSaveLoading={
            props.id ? updateLocationLoading : createLocationLoading
          }
          onSaveClick={onSave}
          onCancelClick={closeModal}
          cancelTitle={"Cancel"}
        />
      }
    >
      <AutoLayout
        direction={"vertical"}
        paddingTop={24}
        paddingBottom={24}
        paddingHorizontal={24}
        alignSelf={"stretch"}
        flex={1}
        spaceBetweenItems={18}
      >
        <TextField
          label={"Name"}
          text={name}
          onTextChange={(x) => {
            setHasNameValidationErrors(false);
            setName(x);
          }}
          dataTestId="location-name-input"
          height={"40px"}
          placeholder={"Write name..."}
          error={hasNameValidationErrors}
        />
        <AddressFieldInput
          address={address}
          setAddress={(x) => {
            setHasAddressValidationErrors(false);
            setAddress(x);
          }}
          required={addressRequired}
          hasValidationErrors={hasAddressValidationErrors}
        />
        {locationGroupTypes?.map((type) => {
          if (type === LocationGroupType.Region) {
            return (
              <LocationGroupMultiSelectField
                key={type}
                type={type}
                placeholder={"None"}
                locationGroupIds={selectedLocationGroups
                  .filter((x) => x.type === type)
                  .map((x) => x.id)}
                setLocationGroupIds={(locationGroupIds) => {
                  setLocationGroupIds([
                    ...selectedLocationGroups
                      .filter((x) => x.type !== type)
                      .map((x) => x.id),
                    ...locationGroupIds,
                  ]);
                }}
              />
            );
          } else {
            return (
              <LocationGroupSingleSelectField
                key={type}
                type={type}
                locationGroupId={
                  selectedLocationGroups.filter((x) => x.type === type)[0]?.id
                }
                placeholder={"None"}
                setLocationGroupId={(locationGroupId) => {
                  const newVal = [
                    ...selectedLocationGroups
                      .filter((x) => x.type !== type)
                      .map((x) => x.id),
                  ];
                  if (isNotNullOrUndefined(locationGroupId)) {
                    newVal.push(locationGroupId);
                  }
                  setLocationGroupIds(newVal);
                }}
              />
            );
          }
        })}
      </AutoLayout>
    </Modal>
  );
};

const AddressFieldInput: FC<{
  address?: AddressInput;
  setAddress: (x: AddressInput) => void;
  required?: boolean;
  hasValidationErrors?: boolean;
}> = (props) => {
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          Address
        </Text>
        <Text type="P3" fontWeight="Medium" color={deprecatedTones.gray7}>
          {props.required ? "Required" : "Recommended"}
        </Text>
      </AutoLayout>
      <AddressField
        initialAddress={props.address}
        onAddressChange={props.setAddress}
        textFieldProps={{
          height: "40px",
        }}
        hasValidationError={props.hasValidationErrors}
      />
    </AutoLayout>
  );
};

gql`
  query locationModal_Location($id: Int!) {
    Location(id: $id) {
      ...LocationModal_Location
    }
  }
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      success
      location {
        ...LocationModal_LocationWithAddress
      }
    }
  }
  mutation updateLocation($id: Int!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      success
      location {
        ...LocationModal_LocationWithAddress
      }
    }
  }
  fragment LocationModal_LocationWithAddress on Location {
    id
    name
    address {
      ...Address
    }
  }
  fragment LocationModal_Location on Location {
    id
    name
    address {
      ...Address
    }
    locationGroupMemberships {
      id
      locationGroup {
        id
        name
        type
      }
    }
  }
  query LocationModal_LocationGroup($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      objects {
        id
        name
      }
    }
  }
  query LocationModal_BillingAccounts {
    BillingAccounts(input: {}) {
      id
      subscriptions {
        id
        plan {
          id
          licenseType
        }
      }
    }
  }
`;

export default LocationModal;

const REFETCH_QUERIES = [
  GraphqlOperations.Query.GetLocations,
  GraphqlOperations.Query.LocationsTable_Locations,
  GraphqlOperations.Query.LocationGroupLocationsTable_Locations,
];
