import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useUser from "@src/hooks/useUser";
import { FC } from "react";

export const AddPhoneNumberBanner: FC = () => {
  const { user } = useUser();
  if (user?.phoneNumber) {
    return null;
  }
  return (
    <AutoLayout
      style={{ backgroundColor: deprecatedTones.purple3 }}
      alignSelf="stretch"
      alignmentHorizontal="center"
      padding={8}
      link={{ pathname: "/my-profile" }}
    >
      <Text type="P1" fontWeight="Medium">
        Add a phone number to your account to sign into the mobile app
      </Text>
    </AutoLayout>
  );
};
