import { AllNestedParents_RoleGroupFragment } from "@contexts/RoleGroupDetailContext/RoleGroupDetailContext.generated";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { isNotNullOrUndefined } from "@utils/typeguards";

type Return = AllNestedParents_RoleGroupFragment[];

const useSortNestedRoleGroupsByParents = (
  roleGroups: AllNestedParents_RoleGroupFragment[],
): Return => {
  return useMemo(() => {
    return sortNestedRoleGroupsByParents(roleGroups);
  }, [roleGroups]);
};

export default useSortNestedRoleGroupsByParents;

export const sortNestedRoleGroupsByParents = (
  roleGroups: AllNestedParents_RoleGroupFragment[],
): Return => {
  return orderBy(roleGroups, roleGroupBreadCrumbName);
};

export const roleGroupBreadCrumbName = (
  roleGroup: AllNestedParents_RoleGroupFragment,
): string => {
  let ret = "";
  let curr: AllNestedParents_RoleGroupFragment | undefined | null = roleGroup;
  while (isNotNullOrUndefined(curr)) {
    if (ret === "") {
      ret = curr.name;
    } else {
      ret = curr.name + " / " + ret;
    }
    curr = curr.parentRoleGroup;
  }
  return ret;
};

export const flattenParentRoleGroups = (
  roleGroup: AllNestedParents_RoleGroupFragment,
): AllNestedParents_RoleGroupFragment[] => {
  const ret: AllNestedParents_RoleGroupFragment[] = [];
  let curr: AllNestedParents_RoleGroupFragment | undefined | null = roleGroup;
  while (isNotNullOrUndefined(curr)) {
    ret.push(curr);
    curr = curr.parentRoleGroup;
  }
  return ret;
};
