import { useNotificationBody } from "@components/layout/hooks/useNotificationBody";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Link from "@src/deprecatedDesignSystem/components/Link";
import NotificationDot from "@src/deprecatedDesignSystem/components/NotificationDot";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import Text from "@ui/text";
import useIntersectionObserver from "@hooks/useIntersectionObserver";
import { NotificationFragment } from "@src/fragments.generated";
import { formatRelativeDate } from "@utils/dates";
import { StyleSheet, css } from "aphrodite";
import { parseISO } from "date-fns";
import React, { FC, useEffect, useRef, useState } from "react";

type Props = {
  notification: NotificationFragment;
  listRef: React.RefObject<HTMLElement>;
  inViewport: (id: string) => void;
};

const NotificationRow: FC<Props> = ({ notification, listRef, inViewport }) => {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const { isInViewport } = useIntersectionObserver({
    root: listRef.current,
    target: rowRef,
    threshold: 1.0,
  });
  const [markedAsRead, setMarkedAsRead] = useState(false);
  const notificationBody = useNotificationBody(notification);
  const { title, body, url, creator } = notificationBody || {};

  useEffect(() => {
    if (
      isInViewport &&
      listRef.current &&
      !notification.readAt &&
      !markedAsRead
    ) {
      inViewport(notification.id);
      setMarkedAsRead(true);
    }
  }, [
    isInViewport,
    notification.readAt,
    notification.id,
    inViewport,
    listRef,
    markedAsRead,
  ]);

  if (!notificationBody) {
    return null;
  }

  const Component = (
    <div ref={rowRef} className={css(styles.container)}>
      {creator && (
        <div className={css(styles.avatarContainer)}>
          <PersonAvatar size="40px" person={creator} />
        </div>
      )}
      <div className={css(styles.contentContainer)}>
        <Text type="P2" multiline>
          {title}
        </Text>
        <Text type="P2" style={{ marginBottom: 4 }}>
          {body}
        </Text>
        <Text type="P3" color={deprecatedTones.gray7}>
          {formatRelativeDate(parseISO(notification.createdAt))}
        </Text>
      </div>
      {!notification.readAt && (
        <div className={css(styles.notificationDotContainer)}>
          <NotificationDot />
        </div>
      )}
    </div>
  );

  return url ? (
    <Link href={url} style={{ width: "100%" }}>
      {Component}
    </Link>
  ) : (
    Component
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    padding: 12,
    borderBottom: `1px solid ${deprecatedColors.border}`,
    backgroundColor: deprecatedTones.white,
    ":hover": {
      backgroundColor: deprecatedColors.primaryHover,
    },
  },
  avatarContainer: {
    display: "flex",
    width: 52,
    paddingRight: 12,
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  notificationDotContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 12,
  },
});

export default NotificationRow;
