import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { LocationGroupType } from "@src/types.generated";
import useLocationGroups from "@hooks/useLocationGroups";
import { pluralize } from "@utils/strings";
import { locationGroupTypeDisplayName } from "@components/home/constants";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MultiSelectField from "@src/deprecatedDesignSystem/components/MultiSelectField";
import { LocationGroupThinFragment } from "@hooks/useLocationGroups.generated";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  type: LocationGroupType;
  label?: string;
  placeholder?: string;
  locationGroupIds: string[];
  setLocationGroupIds: (x: string[]) => void;
  locationGroupOptions?: LocationGroupThinFragment[];
  dataTestId?: string;
};

export const LocationGroupMultiSelectField: FC<Props> = (props) => {
  const { lgTypeToLgs } = useLocationGroups();
  const options = useMemo(() => {
    return (props.locationGroupOptions || lgTypeToLgs[props.type]).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [lgTypeToLgs, props.locationGroupOptions, props.type]);
  const label: string = useMemo(() => {
    if (props.label) return props.label;
    else {
      return pluralize(locationGroupTypeDisplayName[props.type], 2);
    }
  }, [props.label, props.type]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {label}
        </Text>
      </AutoLayout>
      <MultiSelectField
        style={{
          alignSelf: "stretch",
        }}
        dataTestId={props.dataTestId}
        searchEnabled={true}
        values={props.locationGroupIds}
        onValueChange={(locationGroupIds) => {
          props.setLocationGroupIds(locationGroupIds);
        }}
        options={options}
        fontWeight={"Regular"}
        placeholder={props.placeholder || `Select ${label}...`}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
      />
    </AutoLayout>
  );
};

export default LocationGroupMultiSelectField;
