import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useRoleGroups from "@hooks/useRoleGroups";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MultiSelectField from "@src/deprecatedDesignSystem/components/MultiSelectField";
import { RoleGroupThinFragment } from "@hooks/useRoleGroups.generated";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  label?: string;
  placeholder?: string;
  roleGroupIds: string[];
  setRoleGroupIds: (x: string[]) => void;
  roleGroupOptions?: RoleGroupThinFragment[];
  dataTestId?: string;
};

export const RoleGroupMultiSelectField: FC<Props> = (props) => {
  const { roleGroups } = useRoleGroups();
  const options = useMemo(() => {
    return (props.roleGroupOptions || roleGroups).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [props.roleGroupOptions, roleGroups]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {props.label}
        </Text>
      </AutoLayout>
      <MultiSelectField
        style={{
          alignSelf: "stretch",
        }}
        dataTestId={props.dataTestId}
        searchEnabled={true}
        values={props.roleGroupIds}
        onValueChange={(roleGroupIds) => {
          props.setRoleGroupIds(roleGroupIds);
        }}
        options={options}
        fontWeight={"Regular"}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
        placeholder={props.placeholder || `Select ${props.label}...`}
      />
    </AutoLayout>
  );
};

export default RoleGroupMultiSelectField;
