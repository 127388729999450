import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useToast } from "@hooks/useToast";
import { AllNestedParents_RoleGroupFragment } from "@contexts/RoleGroupDetailContext/RoleGroupDetailContext.generated";
import { flattenParentRoleGroups } from "@components/people/hooks/useSortNestedRoleGroupsByParents";
import { useRoleGroupChildrenOptions_RoleGroupsQuery } from "@components/home/hooks/useRoleGroupsChildrenOptionsWithoutCycles.generated";

type Args = {
  roleGroupId: string | undefined;
  parentRoleGroupId: string | undefined;
};

const useRoleGroupsChildrenOptionsWithoutCycles = (
  args: Args,
): AllNestedParents_RoleGroupFragment[] => {
  const { addErrorToast } = useToast();
  const { data: roleGroupData } = useRoleGroupChildrenOptions_RoleGroupsQuery({
    onCompleted(data) {
      if (!data.RoleGroups.objects) {
        addErrorToast({ message: "Failed to load departments" });
      }
    },
    onError() {
      addErrorToast({
        message: "Encountered unexpected error loading departments",
      });
    },
  });
  const parentRoleGroup = useMemo(() => {
    return roleGroupData?.RoleGroups.objects.find(
      (x) => x.id === args.parentRoleGroupId,
    );
  }, [args.parentRoleGroupId, roleGroupData?.RoleGroups.objects]);
  const flattendParentRoleGroupIds: string[] = useMemo(() => {
    return parentRoleGroup
      ? flattenParentRoleGroups(parentRoleGroup)?.map((x) => x.id)
      : [];
  }, [parentRoleGroup]);
  return useMemo(() => {
    return (
      roleGroupData?.RoleGroups.objects
        .filter((x) => x.id !== args.roleGroupId)
        .filter((x) => !flattendParentRoleGroupIds.includes(x.id))
        .filter(
          (x) =>
            !x.parentRoleGroup || x.parentRoleGroup.id === args.roleGroupId,
        ) || []
    );
  }, [
    args.roleGroupId,
    flattendParentRoleGroupIds,
    roleGroupData?.RoleGroups.objects,
  ]);
};

export default useRoleGroupsChildrenOptionsWithoutCycles;

gql`
  query RoleGroupChildrenOptions_RoleGroups {
    RoleGroups {
      objects {
        userCount
        ...AllNestedParents_RoleGroup
      }
    }
  }
`;
