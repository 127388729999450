import { PersonDetailDetailsFragment } from "@components/people/personDetail/PersonDetails.generated";
import EditUserTypeModal from "@components/people/personDetail/modals/EditUserTypeModal";
import InlineEditButton from "@src/deprecatedDesignSystem/components/InlineEditButton";
import InlineEditField from "@src/deprecatedDesignSystem/components/InlineEditField";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";
import { FC, useMemo } from "react";

type Props = {
  person: PersonDetailDetailsFragment;
};

export const userTypeOptions = [
  { value: UserType.Admin, label: "Admin" },
  { value: UserType.Manager, label: "Manager" },
  { value: UserType.Employee, label: "Trainee" },
];

export const getUserTypeLabel = (userType: UserType): string => {
  return userTypeOptions.filter((option) => option.value === userType)[0].label;
};

const EditUserType: FC<Props> = ({ person }) => {
  const { userActionPermissions } = useActionPermissions();
  const { user } = useUser();
  const { showModal } = useModal();
  const canEditUserType = useMemo(() => {
    if (person.id === user?.id) {
      return false;
    }
    if (userActionPermissions?.changeOrgSettings) {
      return true;
    }
    return (
      userActionPermissions?.promoteTrainees &&
      person.userType === UserType.Employee
    );
  }, [
    person.id,
    person.userType,
    user?.id,
    userActionPermissions?.changeOrgSettings,
    userActionPermissions?.promoteTrainees,
  ]);

  return (
    <>
      <InlineEditField
        icon="check-circle"
        label="User type"
        labelStyle={{ width: 160 }}
        valueStyle={{ flex: 1 }}
      >
        {!canEditUserType ? (
          <InlineEditButton
            values={[getUserTypeLabel(person.userType)]}
            loading={person === undefined}
          />
        ) : (
          <InlineEditButton
            values={[getUserTypeLabel(person.userType)]}
            onClick={() => {
              showModal(<EditUserTypeModal person={person} />);
            }}
          />
        )}
      </InlineEditField>
    </>
  );
};

export default EditUserType;
