import { FC, useCallback, useMemo, useState } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { useModal } from "@src/hooks/useModal";
import {
  GraphqlOperations,
  LocationGroupInput,
  LocationGroupType,
} from "@src/types.generated";
import { useToast } from "@src/hooks/useToast";
import { locationGroupTypeDisplayName } from "./constants";
import LocationGroupMultiSelectField from "@components/ui/LocationGroupMultiSelectField";
import LocationGroupSingleSelectField from "@components/ui/LocationGroupSingleSelectField";
import LocationMultiSelectField from "@components/ui/LocationMultiSelectField";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import useLocationGroupsChildrenOptionsWithoutCycles from "@components/home/hooks/useLocationGroupsChildrenOptionsWithoutCycles";
import useLocationGroupParentOptionWithoutCycles from "@components/home/hooks/useLocationGroupParentOptionWithoutCycles";
import { gql } from "@apollo/client";
import {
  useCreateLocationGroupMutation,
  useLocationGroupModal_LocationGroupQuery,
  useLocationGroupModal_LocationsQuery,
  useUpdateLocationGroupMutation,
} from "@components/home/LocationGroupModal.generated";
import { useRouter } from "next/router";
import Text from "@ui/text";
import useUser from "@src/hooks/useUser";
import { css, StyleSheet } from "aphrodite";
import FileUploadButton from "../ui/FileUploadButton";
import {
  isValidHexColor,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";

type Props = {
  id?: string;
  type: LocationGroupType;
  navigateToDetailPageOnSave?: boolean;
};

const LocationGroupModal: FC<Props> = (props) => {
  const [name, setName] = useState("");
  const { user } = useUser();
  const [hasNameValidationError, setHasNameValidationError] = useState(false);
  const [hasBrandColorValidationError, setHasBrandColorValidationError] =
    useState(false);
  const [parentLocationGroupId, setParentLocationGroupId] = useState<string>();
  const [subLocationGroupIds, setSubLocationGroupIds] = useState<string[]>([]);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [brandColor, setBrandColor] = useState<string>();
  const [locationIds, setLocationIds] = useState<number[]>([]);
  const { closeModal } = useModal();
  const { addErrorToast } = useToast();
  const typeDisplayName = useMemo(() => {
    return locationGroupTypeDisplayName[props.type];
  }, [props.type]);
  const modalTitle = useMemo(() => {
    if (props.id) {
      return `Edit ${typeDisplayName}`;
    } else {
      return `New ${typeDisplayName}`;
    }
  }, [props.id, typeDisplayName]);
  const showLoadErrorToast = useCallback(() => {
    addErrorToast({
      message: `Error loading ${typeDisplayName}`,
    });
  }, [addErrorToast, typeDisplayName]);
  const showCreateErrorToast = useCallback(() => {
    addErrorToast({
      message: `Error creating ${typeDisplayName}`,
    });
  }, [addErrorToast, typeDisplayName]);
  const showUpdateErrorToast = useCallback(() => {
    addErrorToast({
      message: `Error updating ${typeDisplayName}`,
    });
  }, [addErrorToast, typeDisplayName]);
  const { addToast } = useToast();
  const { data } = useLocationGroupModal_LocationGroupQuery({
    skip: !props.id,
    variables: {
      id: props.id || "",
    },
    onCompleted(data) {
      const lg = data.LocationGroup;
      if (!lg) {
        showLoadErrorToast();
        return;
      }
      setName(lg.name);
      setBrandColor(lg.brandDetails?.brandColor || undefined);
      setLogoUrl(lg.brandDetails?.logoUrls?.thumb || undefined);
      setSubLocationGroupIds(lg.childLocationGroups?.map((x) => x.id) || []);
      setParentLocationGroupId(lg.parentLocationGroup?.id);
      setLocationIds(lg.locationMemberships?.map((x) => x.location.id) || []);
    },
    onError() {
      showLoadErrorToast();
    },
  });
  const router = useRouter();
  const [createLocationGroup, { loading: loadingCreate }] =
    useCreateLocationGroupMutation({
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (
          data.createLocationGroup.success &&
          data.createLocationGroup.locationGroup
        ) {
          closeModal();
          addToast({
            iconType: "check",
            message: `${typeDisplayName} created`,
          });
          if (props.navigateToDetailPageOnSave) {
            router.push({
              pathname: "/location-groups/[id]",
              query: {
                id: data.createLocationGroup.locationGroup.id,
              },
            });
          }
        } else {
          showCreateErrorToast();
        }
      },
      onError() {
        showCreateErrorToast();
      },
    });
  const [updateLocationGroup, { loading: loadingUpdate }] =
    useUpdateLocationGroupMutation({
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (data.updateLocationGroup.success) {
          closeModal();
          addToast({
            iconType: "check",
            message: `${typeDisplayName} updated`,
          });
        } else {
          showUpdateErrorToast();
        }
      },
      onError() {
        showUpdateErrorToast();
      },
    });
  const handleSubmit = useCallback(() => {
    if (props.id && !data) return;
    const input: LocationGroupInput = {
      name,
      type: props.type,
      locationIds,
      parentLocationGroupId,
      childLocationGroupIds: subLocationGroupIds,
    };
    if (props.type === LocationGroupType.Brand) {
      input.brandDetails = {
        logoUrl,
        brandColor,
      };
    }
    const hasColorValidationError = brandColor && !isValidHexColor(brandColor);
    const hasNameValidationError = !name;
    if (hasNameValidationError) {
      setHasNameValidationError(true);
    }
    if (hasColorValidationError) {
      setHasBrandColorValidationError(true);
    }
    if (hasNameValidationError || hasColorValidationError) {
      return;
    }
    if (props.id) {
      updateLocationGroup({ variables: { id: props.id, input } });
    } else {
      createLocationGroup({ variables: { input } });
    }
  }, [
    props.id,
    props.type,
    data,
    name,
    locationIds,
    parentLocationGroupId,
    subLocationGroupIds,
    updateLocationGroup,
    createLocationGroup,
    logoUrl,
    brandColor,
  ]);
  const excludedLocationIds = useLocationIdsAlreadyInLocationGroupType(
    props.type,
    props.id,
  );
  return (
    <Modal
      header={<TitleHeader title={modalTitle} onCancelClick={closeModal} />}
      width={440}
      footer={
        <Footer
          saveTitle={props.id ? "Save" : "Create"}
          isSaveLoading={props.id ? loadingUpdate : loadingCreate}
          onSaveClick={handleSubmit}
          onCancelClick={closeModal}
          cancelTitle={"Cancel"}
        />
      }
      dataTestId={"location-group-modal"}
    >
      <AutoLayout
        direction={"vertical"}
        alignSelf={"stretch"}
        padding={24}
        spaceBetweenItems={18}
      >
        <TextField
          label="Name"
          placeholder="Enter name..."
          text={name}
          onTextChange={(x) => {
            setHasNameValidationError(false);
            setName(x);
          }}
          height={"40px"}
          error={hasNameValidationError}
          dataTestId={"name-input"}
        />
        <LocationMultiSelectField
          locationIds={locationIds}
          setLocationIds={setLocationIds}
          placeholder={"Select Locations"}
          excludedLocationIds={excludedLocationIds}
        />
        {props.type === LocationGroupType.Region && (
          <>
            <HorizontalDivider styleDeclaration={styles.horizontalDivider} />
            <ParentRegionField
              locationGroupId={props.id}
              childLocationGroupIds={subLocationGroupIds}
              parentLocationGroupId={parentLocationGroupId}
              setParentLocationGroupId={setParentLocationGroupId}
            />
            <SubRegionsField
              locationGroupId={props.id}
              parentLocationGroupId={parentLocationGroupId}
              childrenIds={subLocationGroupIds}
              setChildrenIds={setSubLocationGroupIds}
            />
          </>
        )}
        {props.type === LocationGroupType.Brand && (
          <>
            <HorizontalDivider styleDeclaration={styles.horizontalDivider} />
            <LogoField
              orgId={user!.org?.id}
              logoUrl={logoUrl}
              setLogoUrl={setLogoUrl}
            />
            <BrandColorField
              brandColor={brandColor}
              setBrandColor={setBrandColor}
              hasValidationError={hasBrandColorValidationError}
              setHasValidationError={setHasBrandColorValidationError}
            />
          </>
        )}
      </AutoLayout>
    </Modal>
  );
};

const useLocationIdsAlreadyInLocationGroupType = (
  type: LocationGroupType,
  lgId: string | undefined,
): number[] => {
  const { data } = useLocationGroupModal_LocationsQuery();
  return useMemo(() => {
    if (type === LocationGroupType.Region) return [];
    const allLocations = data?.Locations.objects;
    const locationsAlreadyInLocationGroupType =
      allLocations?.filter((l) => {
        const memberLgTypes = l.locationGroupMemberships
          .filter((x) => x.locationGroup.id !== lgId)
          .map((x) => x.locationGroup.type);
        return memberLgTypes.includes(type);
      }) || [];
    return locationsAlreadyInLocationGroupType.map((x) => x.id);
  }, [type, data?.Locations.objects, lgId]);
};

const LogoField: FC<{
  orgId: number | undefined;
  logoUrl: string | undefined;
  setLogoUrl: (logoUrl: string) => void;
}> = (props) => {
  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      minHeight={54}
      borderRadius={8}
      paddingHorizontal={16}
      border={`1px solid ${deprecatedTones.gray5Alpha}`}
      alignmentVertical="center"
      spacingMode="space-between"
    >
      <Text type="P2" fontWeight="Medium">
        Logo
      </Text>
      <AutoLayout>
        <AutoLayout alignmentVertical="center">
          {props?.logoUrl && (
            <img
              width={42}
              height={42}
              src={props.logoUrl}
              className={css(styles.orgLogo)}
              alt="org-icon"
            />
          )}

          <FileUploadButton
            onFileUploadSuccess={(e) => {
              props.setLogoUrl(e.url);
            }}
            type="Outline"
            text={props?.logoUrl ? "Edit" : "Add"}
            imageOnly
          />
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

const BrandColorField: FC<{
  brandColor: string | undefined;
  setBrandColor: (brandColor: string) => void;
  hasValidationError?: boolean;
  setHasValidationError?: (hasValidationError: boolean) => void;
}> = (props) => {
  const isValidHex = isValidHexColor(props.brandColor || "");
  return (
    <AutoLayout direction="vertical" alignSelf="stretch">
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        minHeight={54}
        borderRadius={8}
        paddingHorizontal={16}
        border={`1px solid ${deprecatedTones.gray5Alpha}`}
        alignmentVertical="center"
        spacingMode="space-between"
      >
        <Text type="P2" fontWeight="Medium">
          Color
        </Text>
        <AutoLayout>
          <AutoLayout alignmentVertical="center">
            {isValidHex && (
              <AutoLayout
                borderRadius={40}
                marginRight={8}
                style={{
                  height: 24,
                  minWidth: 24,
                  backgroundColor: props.brandColor,
                }}
              />
            )}

            <TextField
              text={props.brandColor || ""}
              error={props.hasValidationError}
              inputStyle={{
                maxWidth: 80,
              }}
              placeholder="#000000"
              onTextChange={(v) => {
                props.setBrandColor(v);
                props.setHasValidationError?.(false);
              }}
            />
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
      {props.hasValidationError && (
        <AutoLayout
          padding={12}
          borderRadius={8}
          style={{
            backgroundColor: deprecatedTones.red1,
          }}
        >
          <Text type="P3" multiline color={deprecatedTones.red9}>
            Please enter a valid hex color
          </Text>
        </AutoLayout>
      )}
      {isValidHex && (
        <AutoLayout
          alignSelf="stretch"
          alignmentHorizontal="center"
          padding={12}
          borderRadius={8}
          marginTop={16}
          style={{
            backgroundColor: props.brandColor,
          }}
        >
          <Text type="P3" multiline color={deprecatedTones.white}>
            Make sure white text is clearly legible
          </Text>
        </AutoLayout>
      )}
    </AutoLayout>
  );
};

const ParentRegionField: FC<{
  locationGroupId: string | undefined;
  childLocationGroupIds: string[];
  parentLocationGroupId: string | undefined;
  setParentLocationGroupId: (x: string | undefined) => void;
}> = (props) => {
  const possibleChildrenLocationGroups =
    useLocationGroupParentOptionWithoutCycles({
      locationGroupId: props.locationGroupId,
      childLocationGroupIds: props.childLocationGroupIds,
      type: LocationGroupType.Region,
    });
  return (
    <LocationGroupSingleSelectField
      type={LocationGroupType.Region}
      label={"Parent region"}
      placeholder={"None"}
      locationGroupId={props.parentLocationGroupId}
      setLocationGroupId={(x) => {
        props.setParentLocationGroupId(x);
      }}
      locationGroupOptions={possibleChildrenLocationGroups}
    />
  );
};

const SubRegionsField: FC<{
  locationGroupId: string | undefined;
  parentLocationGroupId: string | undefined;
  childrenIds: string[];
  setChildrenIds: (x: string[]) => void;
}> = (props) => {
  const possibleChildrenLocationGroups =
    useLocationGroupsChildrenOptionsWithoutCycles({
      locationGroupId: props.locationGroupId,
      parentLocationGroupId: props.parentLocationGroupId,
      type: LocationGroupType.Region,
    });
  return (
    <AutoLayout
      direction={"vertical"}
      alignSelf={"stretch"}
      data-testid={"sub-regions-field"}
    >
      <LocationGroupMultiSelectField
        type={LocationGroupType.Region}
        label={"Sub regions"}
        placeholder={"None"}
        locationGroupIds={props.childrenIds}
        setLocationGroupIds={props.setChildrenIds}
        locationGroupOptions={possibleChildrenLocationGroups}
      />
    </AutoLayout>
  );
};

export default LocationGroupModal;

const REFETCH_QUERIES = [
  GraphqlOperations.Query.GetLocations,
  GraphqlOperations.Query.GetLocationGroups,
  GraphqlOperations.Query.LocationGroupDetail,
  GraphqlOperations.Query.GetLocationGroups,
  GraphqlOperations.Query.LocationGroupHeader_LocationsCount,
  GraphqlOperations.Query.LocationGroupHeader_PeopleCount,
  GraphqlOperations.Query.LocationGroupLocationsTable_Locations,
  GraphqlOperations.Query.GetPeople,
  GraphqlOperations.Query.SubRegionsList_LocationGroup,
  GraphqlOperations.Query.OrgDetail_LocationGroups,
];

gql`
  mutation CreateLocationGroup($input: LocationGroupInput!) {
    createLocationGroup(input: $input) {
      success
      locationGroup {
        ...LocationGroupModal_LocationGroup
      }
    }
  }
  mutation UpdateLocationGroup($id: String!, $input: LocationGroupInput!) {
    updateLocationGroup(id: $id, input: $input) {
      success
      locationGroup {
        ...LocationGroupModal_LocationGroup
      }
    }
  }
  query LocationGroupModal_LocationGroup($id: String!) {
    LocationGroup(id: $id) {
      ...LocationGroupModal_LocationGroup
    }
  }

  query LocationGroupModal_Locations {
    Locations {
      totalCount
      objects {
        id
        name
        locationGroupMemberships {
          id
          locationGroup {
            id
            type
            name
          }
        }
      }
    }
  }

  fragment LocationGroupModal_LocationGroup on LocationGroup {
    id
    name
    brandDetails {
      ...BrandDetails
    }
    childLocationGroups {
      id
      name
    }
    locationMemberships {
      id
      location {
        id
        name
      }
    }
    ...AllNestedParents_LocationGroup
  }
`;
const styles = StyleSheet.create({
  orgLogo: {
    borderRadius: 8,
    overflow: "hidden",
    marginRight: 16,
  },
  horizontalDivider: {
    marginTop: 12,
    marginBottom: 12,
  },
});
