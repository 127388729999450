import LeftNav from "@components/layout/leftNav/LeftNav";
import TopNav from "@components/layout/topNav/TopNav";
import ScrollableFlexGrow from "@components/ui/flexBox/ScrollableFlexGrow";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useScrollTop from "@hooks/useScrollTop";
import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { AddPaymentMethodBanner } from "./AddPaymentMethodBanner";
import { AddPhoneNumberBanner } from "./AddPhoneNumberBanner";

type Props = {
  children: ReactNode;
  showTopNav?: boolean;
  showLeftNav?: boolean;
  overflow?: React.CSSProperties["overflow"];
  dataTestId?: string;
};

export const Page: FC<Props> = ({
  children,
  showTopNav = true,
  showLeftNav = true,
  overflow = "auto",
  dataTestId,
}: Props) => {
  const { scrollTop, scrollProps } = useScrollTop();
  return (
    <AutoLayout
      direction="vertical"
      styleDeclaration={styles.container}
      style={{ overflow }}
      data-testid={dataTestId}
    >
      <AddPhoneNumberBanner />
      <AddPaymentMethodBanner />
      <AutoLayout alignSelf={"stretch"} flex={1}>
        {showLeftNav ? <LeftNav /> : null}
        <AutoLayout direction="vertical" flex={1} alignSelf={"stretch"}>
          {showTopNav ? <TopNav scrollTop={scrollTop} /> : null}
          <ScrollableFlexGrow
            flex={1}
            alignSelf={"stretch"}
            innerStyle={{
              alignItems: "center",
            }}
            onScroll={scrollProps.onScroll}
          >
            <AutoLayout
              id={"page-content-inner"}
              {...scrollProps}
              style={{ overflow }}
              alignSelf={"stretch"}
              flex={1}
            >
              {children}
            </AutoLayout>
          </ScrollableFlexGrow>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
  },
});

export default Page;
