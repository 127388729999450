import {
  getCourseDetailRoute,
  getFileDetailRoute,
  getPathDetailRoute,
  getPremiumPathRoute,
  getSkillDetailRoute,
  SHARP_DETAIL_ROUTE,
} from "@components/contentLibrary/utils";
import {
  getLocationDetailRoute,
  getLocationGroupDetailRoute,
  getPersonDetailRoute,
  getRoleDetailRoute,
  getRoleGroupDetailRoute,
  getTagRoute,
} from "@components/people/utils/getDetailRoute";
import useAllJumpToItems, {
  JumpToItem,
} from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import useAllRecentlyViewedItems from "@components/ui/jumpToBar/hooks/useAllRecentlyViewedItems";
import useSearchJumpToItems from "@components/ui/jumpToBar/hooks/useSearchJumpToItems";
import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import JumpToItemRow, {
  isEmployeeItemType,
  isLocationGroupTypeEnum,
  isOrgConfigItemType,
  isTagItemType,
} from "@src/deprecatedDesignSystem/components/JumpToItemRow";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import useActionPermissions from "@hooks/useActionPermissions";
import useKeyboardNavigation from "@hooks/useKeyboardNavigation";
import * as Popover from "@radix-ui/react-popover";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import { useRouter } from "next/router";
import { Route } from "nextjs-routes";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchIcon from "@src/ui/icons/18px/search";

const WIDTH = 480;
const JumpToBar: FC = () => {
  const { userActionPermissions } = useActionPermissions();
  const router = useRouter();
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { items: allJumpToItems, loading } = useAllJumpToItems({
    search: debouncedSearch,
  });
  const { items: recentlyViewedItems } = useAllRecentlyViewedItems();
  const filteredItems = useSearchJumpToItems(allJumpToItems, search);
  const showingRecentlyViewed = useMemo(() => {
    return search.trim().length === 0;
  }, [search]);
  const items = useMemo(() => {
    if (showingRecentlyViewed) {
      return recentlyViewedItems;
    }
    return filteredItems;
  }, [showingRecentlyViewed, filteredItems, recentlyViewedItems]);
  const getLinkToItem = useCallback((item: JumpToItem) => {
    let url: Route = getTagRoute(item.id as string);
    if (
      "libraryItemId" in item &&
      !item.isSharp &&
      !isOrgConfigItemType(item)
    ) {
      url = {
        pathname: "/library/library-item/[id]",
        query: { id: item.libraryItemId },
      };
    } else if (item.isSharp) {
      url = SHARP_DETAIL_ROUTE;
    } else if (item.type === "Module") {
      const pathId = item.id as number;
      url = getPathDetailRoute(pathId);
    } else if (item.type === "Course") {
      const courseId = item.id as number;
      url = getCourseDetailRoute(courseId);
    } else if (item.type === "Resource") {
      url = getFileDetailRoute(item.id as string);
    } else if (item.type === "Skill") {
      url = getSkillDetailRoute(item.id as number);
    } else if (isEmployeeItemType(item)) {
      url = getPersonDetailRoute(item.id as number);
    } else if (item.type === "Location") {
      url = getLocationDetailRoute(item.id as number);
    } else if (item.type === "Role") {
      url = getRoleDetailRoute(item.id as number);
    } else if (item.type === "RoleGroup") {
      url = getRoleGroupDetailRoute(item.id as string);
    } else if (isLocationGroupTypeEnum(item.type)) {
      url = getLocationGroupDetailRoute(item.id as string);
    } else if (isTagItemType(item)) {
      url = getTagRoute(item.id as string);
    } else if (isOrgConfigItemType(item)) {
      url = getPremiumPathRoute(item.catalogPathId);
    }
    return url;
  }, []);

  const blurInput = useCallback(() => {
    inputRef?.current?.blur();
  }, [inputRef]);

  const selectItem = useCallback(
    (item: JumpToItem) => {
      const link = getLinkToItem(item);
      setOpen(false);
      setSearch("");
      blurInput();
      router.push(link);
    },
    [getLinkToItem, setSearch, blurInput, router],
  );
  const selectItemAtIndex = useCallback(
    (index: number) => {
      const item = items[index];
      selectItem(item);
    },
    [items, selectItem],
  );
  const { keyboardSelectIndex, setKeyboardSelectIndex, onKeyDown } =
    useKeyboardNavigation(items.length, selectItemAtIndex, blurInput);
  useEffect(() => {
    function listener(evt: KeyboardEvent) {
      if (["k", "p"].includes(evt.key) && evt.metaKey) {
        evt.preventDefault();
        setOpen((o) => !o);
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  useEffect(() => {
    if (open) {
      setKeyboardSelectIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <div className={css(styles.inputRow)}>
          <SearchIcon className="ml-1 text-muted-foreground" />
          <TextField
            ref={inputRef}
            text={search}
            onTextChange={(newVal) => {
              setSearch(newVal);
              setKeyboardSelectIndex(0);
              setOpen(true);
            }}
            onKeyDown={onKeyDown}
            containerStyleDeclaration={styles.textFieldContainer}
            inputStyleDeclaration={styles.textFieldInput}
            placeholder="Quick find..."
            dataTestId="jump-to-bar-input"
          />
          <div className={css(styles.keyboardShortcut)}>
            <kbd className={css(styles.kbd)}>⌘</kbd>
            <kbd className={css(styles.kbd)}>K</kbd>
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Content
        sideOffset={4}
        className={css(styles.list)}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
          inputRef?.current?.focus();
        }}
        align="start"
      >
        {showingRecentlyViewed && (
          <div>
            <Text type="P3" color={deprecatedTones.gray7}>
              Take me to
            </Text>
            <Spacer size={4} />
            <AutoLayout direction={"horizontal"} style={{ flex: 1 }}>
              <Button
                text="Messages"
                leftIcon="message"
                customIconColor={deprecatedTones.gray5}
                variant="Outline"
                onClick={() => router.push("/messages")}
              />
              {userActionPermissions?.changeOrgSettings !== false ? (
                <>
                  <Spacer axis="horizontal" size={8} />
                  <Button
                    text="Settings"
                    leftIcon="settings"
                    customIconColor={deprecatedTones.gray5}
                    variant="Outline"
                    onClick={() => router.push("/settings")}
                  />
                </>
              ) : null}
              <Spacer axis="horizontal" size={8} />
              <Button
                text="People"
                leftIcon="user"
                customIconColor={deprecatedTones.gray5}
                variant="Outline"
                onClick={() =>
                  router.push({
                    pathname: "/",
                    query: {
                      tab: "people",
                    },
                  })
                }
              />
            </AutoLayout>
            <Spacer size={12} />
          </div>
        )}
        {filteredItems.length === 0 && allJumpToItems.length > 0 ? (
          <EmptyState
            loading={loading}
            icon="search"
            title={`No results match "${search}"`}
          />
        ) : (
          <Text type="P3" color={deprecatedTones.gray7} fontWeight="Medium">
            {showingRecentlyViewed
              ? "Recently Viewed"
              : `Search results: ${items.length}`}
          </Text>
        )}
        {items.map((item, i) => (
          <JumpToItemRow
            key={items.length + i}
            item={item}
            highlighted={i === keyboardSelectIndex}
            link={getLinkToItem(item)}
            close={() => setOpen(false)}
            onMouseMove={() => setKeyboardSelectIndex(i)}
            dataTestId="jump-to-item-row"
          />
        ))}
      </Popover.Content>
    </Popover.Root>
  );
};

export default JumpToBar;

const styles = StyleSheet.create({
  inputRow: {
    display: "flex",
    alignItems: "center",
    maxWidth: 600,
    minWidth: 240,
    flex: 1,
    background: deprecatedTones.gray4Alpha,
    padding: "4px 16px 4px 8px",
    borderRadius: 999,
    height: 36,
    position: "relative",
    marginRight: 20,
    ":hover": {
      background: deprecatedTones.gray5Alpha,
    },
  },
  searchIcon: {},
  textFieldContainer: {},
  textFieldInput: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    ":focus": {
      border: "none",
      outline: "none",
    },
    ":hover": {
      border: "none",
      outline: "none",
    },
    ":disabled": {
      border: "none",
      outline: "none",
    },
    "::placeholder": {
      color: deprecatedTones.gray7,
    },
  },
  keyboardShortcut: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: deprecatedTones.gray7,
  },
  kbd: {
    padding: "0 2px",
    userSelect: "none",
    fontFamily: "inherit",
    fontSize: 13,
  },
  list: {
    height: 400,
    width: WIDTH,
    borderRadius: "8px",
    boxShadow: shadows.dropdownShadow,
    border: "none",
    outline: "none",
    background: deprecatedTones.white,
    color: deprecatedTones.gray13,
    padding: 8,
    overflow: "auto",
    overscrollBehavior: "contain",
    scrollPaddingBlockEnd: "40px",
    paddingBottom: "40px",
  },
  resultRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "64px",
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 14,
    fontWeight: 500,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  selected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
