import { useModal } from "@src/hooks/useModal";
import { FC, useCallback, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { useToast } from "@src/hooks/useToast";
import {
  useCreateRoleGroupMutation,
  useRoleGroupModal_RoleGroupQuery,
  useRoleGroupModal_RolesQuery,
  useUpdateRoleGroupMutation,
} from "./RoleGroupModal.generated";
import { useRouter } from "next/router";
import { GraphqlOperations, RoleGroupInput } from "@src/types.generated";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import RoleGroupSingleSelectField from "../ui/RoleGroupSingleSelectField";
import useRoleGroupParentOptionWithoutCycles from "@components/home/hooks/useRoleGroupParentOptionWithoutCycles";
import useRoleGroupsChildrenOptionsWithoutCycles from "@components/home/hooks/useRoleGroupsChildrenOptionsWithoutCycles";
import RoleMultiSelectField from "@components/ui/RoleMultiSelectField";
import RoleGroupMultiSelectField from "@components/ui/RoleGroupMultiSelectField";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";

type Props = {
  id?: string;
  navigateToDetailPageOnSave?: boolean;
};

const RoleGroupModal: FC<Props> = (props) => {
  const [name, setName] = useState("");
  const [hasNameValidationError, setHasNameValidationError] = useState(false);
  const [parentRoleGroupId, setParentRoleGroupId] = useState<string>();
  const [childRoleGroupIds, setChildRoleGroupIds] = useState<string[]>([]);
  const [roleIds, setRoleIds] = useState<number[]>([]);
  const router = useRouter();
  const { closeModal } = useModal();
  const { addToast, addErrorToast } = useToast();
  const modalTitle = useMemo(() => {
    return props.id ? "Edit Department" : "New Department";
  }, [props.id]);
  const showLoadErrorToast = useCallback(() => {
    addErrorToast({
      message: "Error loading Department",
    });
  }, [addErrorToast]);
  const showCreateErrorToast = useCallback(() => {
    addErrorToast({
      message: "Error creating Department",
    });
  }, [addErrorToast]);
  const showUpdateErrorToast = useCallback(() => {
    addErrorToast({
      message: "Error updating Department",
    });
  }, [addErrorToast]);
  const { data } = useRoleGroupModal_RoleGroupQuery({
    skip: !props.id,
    variables: {
      id: props.id || "",
    },
    onCompleted(data) {
      const roleGroup = data.RoleGroup;
      if (!roleGroup) {
        showLoadErrorToast();
        return;
      }
      setName(roleGroup.name);
      setChildRoleGroupIds(roleGroup.childRoleGroups?.map((x) => x.id) || []);
      setParentRoleGroupId(roleGroup.parentRoleGroup?.id);
      setRoleIds(roleGroup.roleMemberships?.map((x) => x.role.id) || []);
    },
    onError() {
      showLoadErrorToast();
    },
  });
  const [createRoleGroup, { loading: loadingCreate }] =
    useCreateRoleGroupMutation({
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (data.createRoleGroup.success && data.createRoleGroup.roleGroup) {
          closeModal();
          addToast({
            iconType: "check",
            message: "Department created",
          });
          if (props.navigateToDetailPageOnSave) {
            router.push({
              pathname: "/role-groups/[id]",
              query: {
                id: data.createRoleGroup.roleGroup.id,
              },
            });
          }
        } else {
          showCreateErrorToast();
        }
      },
      onError() {
        showCreateErrorToast();
      },
    });
  const [updateRoleGroup, { loading: loadingUpdate }] =
    useUpdateRoleGroupMutation({
      refetchQueries: REFETCH_QUERIES,
      onCompleted: (data) => {
        if (data.updateRoleGroup.success) {
          closeModal();
          addToast({
            iconType: "check",
            message: "Department updated",
          });
        } else {
          showUpdateErrorToast();
        }
      },
      onError() {
        showUpdateErrorToast();
      },
    });
  const handleSubmit = useCallback(() => {
    if (props.id && !data) return;
    const input: RoleGroupInput = {
      name,
      roleIds,
      parentRoleGroupId,
      childRoleGroupIds,
    };
    if (!name) {
      setHasNameValidationError(true);
      return;
    }
    if (props.id) {
      updateRoleGroup({ variables: { id: props.id, input } });
    } else {
      createRoleGroup({ variables: { input } });
    }
  }, [
    childRoleGroupIds,
    createRoleGroup,
    data,
    name,
    parentRoleGroupId,
    props.id,
    roleIds,
    updateRoleGroup,
  ]);
  const excludedRoleIds = useRoleIdsAlreadyInRoleGroup(props.id);
  return (
    <Modal
      header={<TitleHeader title={modalTitle} onCancelClick={closeModal} />}
      width={440}
      footer={
        <Footer
          saveTitle={props.id ? "Save" : "Create"}
          isSaveLoading={props.id ? loadingUpdate : loadingCreate}
          onSaveClick={handleSubmit}
          onCancelClick={closeModal}
          cancelTitle="Cancel"
        />
      }
      dataTestId="role-group-modal"
    >
      <AutoLayout
        direction="vertical"
        alignSelf="stretch"
        padding={24}
        spaceBetweenItems={18}
      >
        <TextField
          label="Name"
          height="40px"
          placeholder="Enter name..."
          text={name}
          onTextChange={(x) => {
            setHasNameValidationError(false);
            setName(x);
          }}
          error={hasNameValidationError}
          dataTestId="name-input"
        />
        <RoleMultiSelectField
          roleIds={roleIds}
          setRoleIds={setRoleIds}
          placeholder="Select Roles"
          excludedRoleIds={excludedRoleIds}
        />
        <HorizontalDivider style={{ marginTop: 12, marginBottom: 12 }} />
        <ParentRoleGroupField
          roleGroupId={props.id}
          childRoleGroupIds={childRoleGroupIds}
          parentRoleGroupId={parentRoleGroupId}
          setParentRoleGroupId={setParentRoleGroupId}
        />
        <ChildRoleGroupField
          roleGroupId={props.id}
          parentRoleGroupId={parentRoleGroupId}
          childrenIds={childRoleGroupIds}
          setChildrenIds={setChildRoleGroupIds}
        />
      </AutoLayout>
    </Modal>
  );
};

const ParentRoleGroupField: FC<{
  roleGroupId: string | undefined;
  childRoleGroupIds: string[];
  parentRoleGroupId: string | undefined;
  setParentRoleGroupId: (x: string | undefined) => void;
}> = (props) => {
  const possibleChildrenRoleGroups = useRoleGroupParentOptionWithoutCycles({
    roleGroupId: props.roleGroupId,
    childRoleGroupIds: props.childRoleGroupIds,
  });

  return (
    <RoleGroupSingleSelectField
      label="Parent department"
      placeholder="None"
      roleGroupId={props.parentRoleGroupId}
      setRoleGroupId={(x) => {
        props.setParentRoleGroupId(x);
      }}
      roleGroupOptions={possibleChildrenRoleGroups}
    />
  );
};

const ChildRoleGroupField: FC<{
  roleGroupId: string | undefined;
  parentRoleGroupId: string | undefined;
  childrenIds: string[];
  setChildrenIds: (x: string[]) => void;
}> = (props) => {
  const possibleChildrenRoleGroups = useRoleGroupsChildrenOptionsWithoutCycles({
    roleGroupId: props.roleGroupId,
    parentRoleGroupId: props.parentRoleGroupId,
  });

  return (
    <AutoLayout
      direction="vertical"
      alignSelf="stretch"
      data-testid="child-role-groups-field"
    >
      <RoleGroupMultiSelectField
        label="Sub departments"
        placeholder="None"
        roleGroupIds={props.childrenIds}
        setRoleGroupIds={props.setChildrenIds}
        roleGroupOptions={possibleChildrenRoleGroups}
      />
    </AutoLayout>
  );
};

const useRoleIdsAlreadyInRoleGroup = (
  roleGroupId: string | undefined,
): number[] => {
  const { data } = useRoleGroupModal_RolesQuery();
  return useMemo(() => {
    const allRoles = data?.Roles.objects;
    const rolesAlreadyInRoleGroup =
      allRoles?.filter((r) => {
        r.roleGroupMemberships.filter((x) => x.roleGroup.id !== roleGroupId);
      }) || [];
    return rolesAlreadyInRoleGroup.map((x) => x.id);
  }, [data?.Roles.objects, roleGroupId]);
};

gql`
  mutation CreateRoleGroup($input: RoleGroupInput!) {
    createRoleGroup(input: $input) {
      success
      roleGroup {
        ...RoleGroupModal_RoleGroup
      }
    }
  }
  mutation UpdateRoleGroup($id: String!, $input: RoleGroupInput!) {
    updateRoleGroup(id: $id, input: $input) {
      success
      roleGroup {
        ...RoleGroupModal_RoleGroup
      }
    }
  }
  query RoleGroupModal_RoleGroup($id: String!) {
    RoleGroup(id: $id) {
      ...RoleGroupModal_RoleGroup
    }
  }
  query RoleGroupModal_Roles {
    Roles {
      totalCount
      objects {
        id
        name
        roleGroupMemberships {
          id
          roleGroup {
            id
            name
          }
        }
      }
    }
  }
  fragment RoleGroupModal_RoleGroup on RoleGroup {
    id
    name
    childRoleGroups {
      id
      name
    }
    roleMemberships {
      id
      role {
        id
        name
      }
    }
    ...AllNestedParents_RoleGroup
  }
`;

const REFETCH_QUERIES = [
  GraphqlOperations.Query.GetRoles,
  GraphqlOperations.Query.GetRoleGroups,
  GraphqlOperations.Query.RoleGroupDetail,
  GraphqlOperations.Query.GetRoleGroups,
  GraphqlOperations.Query.RoleGroupHeader_RolesCount,
  GraphqlOperations.Query.RoleGroupHeader_PeopleCount,
  GraphqlOperations.Query.RoleGroupRolesTable_Roles,
  GraphqlOperations.Query.SubRoleGroupsList_RoleGroup,
  GraphqlOperations.Query.GetPeople,
  GraphqlOperations.Query.OrgDetail_RoleGroups,
];

export default RoleGroupModal;
