import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleSheet } from "aphrodite";
import NotificationRow from "@src/deprecatedDesignSystem/components/NotificationRow";
import { NotificationFragment } from "@src/fragments.generated";
import { UIEvent, useEffect, useRef, useState } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import NoNotificationsImage from "./NoNotificationsImage";
import Text from "@ui/text";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";

type Props = {
  notifications: NotificationFragment[];
  loading: boolean;
  hasMore: boolean;
  fetchMoreNotifications: () => void;
  onNotificationRead: (id: string) => void;
};
export const NotificationList: React.FC<Props> = ({
  notifications,
  loading,
  hasMore,
  fetchMoreNotifications,
  onNotificationRead,
}) => {
  const [fetchedMore, setFetchedMore] = useState(false);
  const listRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = (event: UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const scrolledPastThreshold =
      target.scrollTop > (target.scrollHeight - target.clientHeight) * 0.7;

    if (scrolledPastThreshold && !fetchedMore) {
      fetchMoreNotifications();
      setFetchedMore(true);
    }
  };

  useEffect(() => {
    if (fetchedMore) {
      setFetchedMore(false);
    }
  }, [fetchedMore, notifications]);

  return (
    <AutoLayout styleDeclaration={styles.container}>
      {loading && (
        <AutoLayout
          width="100%"
          alignSelf="stretch"
          alignmentHorizontal="center"
          alignmentVertical="center"
        >
          <Spinner size={64} color={deprecatedTones.blue9} />
        </AutoLayout>
      )}
      {notifications.length === 0 ? (
        <AutoLayout
          flex={1}
          alignSelf="stretch"
          alignmentVertical="center"
          alignmentHorizontal="center"
          direction="vertical"
        >
          <NoNotificationsImage size={200} />
          <Text type="P1">You have no notifications</Text>
        </AutoLayout>
      ) : (
        <AutoLayout flex={1} direction="vertical" alignSelf="stretch">
          <AutoLayout styleDeclaration={styles.dropdownHeader}>
            <Text type="P1" fontWeight="SemiBold">
              Notifications
            </Text>
          </AutoLayout>
          <AutoLayout
            ref={listRef}
            onScroll={handleScroll}
            flex={1}
            direction="vertical"
            alignSelf="stretch"
            style={{ overflowY: "scroll" }}
          >
            {notifications.map((n) => (
              <NotificationRow
                key={n.id}
                listRef={listRef}
                notification={n}
                inViewport={onNotificationRead}
              />
            ))}
            {hasMore && (
              <AutoLayout
                flex={1}
                alignSelf="stretch"
                alignmentHorizontal="center"
                style={{ paddingTop: 12, paddingBottom: 12 }}
              >
                <Spinner size={24} color={deprecatedTones.blue9} />
              </AutoLayout>
            )}
          </AutoLayout>
        </AutoLayout>
      )}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    overflowY: "hidden",
    width: 400,
    height: 400,
    borderRadius: 12,
    color: deprecatedColors.onBackground,
    backgroundColor: deprecatedColors.background,
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.18)",
  },
  notificationsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 400,
    fontSize: 13,
    fontWeight: 400,
    color: deprecatedTones.gray7,
    flexDirection: "column",
    padding: "40px 20px",
  },
  dropdownHeader: {
    width: "100%",
    padding: 12,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
});

export default NotificationList;
